"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnrichAIWidgetsContainer = void 0;
var react_1 = __importStar(require("react"));
var graphql_1 = require("../__generated__/graphql");
var ui_components_1 = require("@groupby/ui-components");
var react_redux_1 = require("react-redux");
var catalog_1 = require("./client/catalog");
var hooks_1 = require("./client/catalog/hooks");
var hooks_2 = require("./client/hooks");
var proposal_1 = require("./client/proposal");
var components_1 = require("./components");
var enrich_ai_settings_widget_1 = require("./enrich-ai-settings-widget");
var enrich_ai_widgets_utils_1 = require("./enrich-ai-widgets.utils");
var enrich_ai_styles_1 = require("./enrich-ai.styles");
var global_re_enrich_alert_1 = require("./global-re-enrich-alert");
var hooks_3 = require("./hooks");
var upload_file_options_1 = require("./models/upload-file-options");
var preview_products_loader_1 = require("./preview-products-loader");
var preview_products_widget_1 = require("./preview-products-widget");
var strategy_preview_1 = require("./strategy-preview");
var upload_data_dialog_1 = require("./upload-data-dialog");
var feature_flags_1 = require("../feature-flags");
var hooks_4 = require("../hooks");
var icons_1 = require("../icons");
var localization_1 = require("../localization");
var store_1 = require("../store");
var EnrichAIWidgetsContainer = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var featureFlagsService = (0, hooks_4.useFeatureFlagsService)().featureFlagsService;
    var isMeasuresEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures);
    var clearStore = (0, hooks_2.useClearStore)().clearStore;
    var refetchProductAttributes = (0, hooks_2.useRefetchQueries)().refetchProductAttributes;
    var _b = (0, hooks_3.useCatalogSettings)(), selectedProjectId = _b.selectedProjectId, selectedCatalogId = _b.selectedCatalogId, onSelectCatalog = _b.onSelectCatalog, onSelectProjectId = _b.onSelectProjectId;
    var selectedTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    // need to prevent blinking screen between creating ticket sequence for catalog and getting catalog state
    var _c = __read((0, react_1.useState)(false), 2), isInitialCatalogStatusLoading = _c[0], setIsInitialCatalogStatusLoading = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), isUploadDataDialogOpen = _d[0], setIsUploadDataDialogOpen = _d[1];
    var _e = __read((0, react_1.useState)(false), 2), isFileUploading = _e[0], setIsFileUploading = _e[1];
    var _f = __read((0, react_1.useState)(null), 2), fileUploadError = _f[0], setFileUploadError = _f[1];
    var _g = (0, catalog_1.useCatalogProgress)(), stopPolling = _g.stopPolling, getCatalogById = _g.getCatalogById, catalog = _g.catalog, catalogDownloadUrl = _g.catalogDownloadUrl, catalogProgress = _g.catalogProgress, catalogProgressState = _g.catalogProgressState, isCatalogInProgress = _g.isCatalogInProgress, catalogProgressError = _g.error;
    var createNewCatalog = (0, hooks_1.useCreateNewCatalog)().createNewCatalog;
    // TODO: fix types
    var replaceCatalog = (0, hooks_1.useReplaceCatalog)({ catalog: catalog }).replaceCatalog;
    var updateCatalog = (0, hooks_1.useUpdateCatalog)({ catalog: catalog }).updateCatalog;
    var _h = (0, proposal_1.useBulkAssertions)({
        catalogId: selectedCatalogId,
        onCompleted: function () {
            setIsUploadDataDialogOpen(false);
            void refetchProductAttributes(selectedTreeNodeId);
        },
    }), addBulkAssertions = _h.addBulkAssertions, isEnrichedDataUpdating = _h.isLoading, updateEnrichedDataError = _h.error;
    var onSelectStrategyNodePath = (0, react_1.useCallback)(function (path) { return dispatch((0, store_1.enrichAISetSelectedTreeNodePath)(path)); }, [dispatch]);
    var loadCatalog = (0, react_1.useCallback)(function (catalogId) {
        if (catalogId === void 0) { catalogId = selectedCatalogId; }
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsInitialCatalogStatusLoading(true);
                        return [4 /*yield*/, getCatalogById(catalogId)];
                    case 1:
                        _a.sent();
                        setIsInitialCatalogStatusLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    }, [getCatalogById, selectedCatalogId]);
    (0, react_1.useEffect)(function () {
        if (selectedCatalogId) {
            void loadCatalog();
        }
    }, [selectedCatalogId, loadCatalog]);
    var error = (_a = (fileUploadError || catalogProgressError)) === null || _a === void 0 ? void 0 : _a.message;
    var isUploading = isFileUploading || isInitialCatalogStatusLoading;
    var catalogState = (0, react_1.useMemo)(function () {
        if (isUploading) {
            return catalog_1.CatalogProgressState.Uploading;
        }
        if (error) {
            return catalog_1.CatalogProgressState.Error;
        }
        return catalogProgressState;
    }, [isUploading, error, catalogProgressState]);
    (0, react_1.useEffect)(function () {
        if (catalogState === catalog_1.CatalogProgressState.Completed || catalogProgressError) {
            stopPolling();
        }
    }, [catalogState, stopPolling, catalogProgressError]);
    var handleSelectCatalog = function (catalog) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, clearStore()];
                case 1:
                    _a.sent();
                    onSelectCatalog(catalog);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCatalogUpdate = function (fileToUpload, mode) { return __awaiter(void 0, void 0, void 0, function () {
        var newCatalogId, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    stopPolling();
                    setFileUploadError(null);
                    setIsInitialCatalogStatusLoading(false);
                    setIsFileUploading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 9, , 10]);
                    if (!!selectedCatalogId) return [3 /*break*/, 3];
                    onSelectStrategyNodePath(null);
                    return [4 /*yield*/, createNewCatalog(fileToUpload)];
                case 2:
                    newCatalogId = _a.sent();
                    onSelectCatalog(newCatalogId);
                    return [3 /*break*/, 8];
                case 3:
                    if (!(mode === upload_file_options_1.UpdateDataModes.Replace)) return [3 /*break*/, 5];
                    return [4 /*yield*/, replaceCatalog(fileToUpload)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    if (!(mode === upload_file_options_1.UpdateDataModes.Merge)) return [3 /*break*/, 7];
                    return [4 /*yield*/, updateCatalog(fileToUpload)];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7:
                    dispatch((0, store_1.enrichAIRemoveChanges)());
                    void loadCatalog();
                    _a.label = 8;
                case 8:
                    setIsUploadDataDialogOpen(false);
                    setIsFileUploading(false);
                    return [3 /*break*/, 10];
                case 9:
                    e_1 = _a.sent();
                    setIsFileUploading(false);
                    setFileUploadError(e_1);
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }); };
    var handleEnrichedDataUpdate = function (data, columnsOrder, mode) {
        setFileUploadError(null);
        if (columnsOrder.length < 2) {
            setFileUploadError(t({ key: 'CSV_PARSE_REQUIRED_COLUMNS_ERROR' }));
        }
        var records = (0, enrich_ai_widgets_utils_1.parseFileData)({
            data: data,
            columnsOrder: columnsOrder,
            mode: mode,
            isMeasuresEnabled: isMeasuresEnabled,
        });
        if (records.length) {
            dispatch((0, store_1.enrichAIRemoveChanges)());
            void addBulkAssertions(records, graphql_1.ProposalAction.Augment);
        }
    };
    var catalogProgressValue = (0, react_1.useMemo)(function () { return (isUploading ? 0 : catalogProgress); }, [catalogProgress, isUploading]);
    var isCatalogLoadingNotStarted = catalogState === catalog_1.CatalogProgressState.NotStarted;
    var isLoading = ![catalog_1.CatalogProgressState.NotStarted, catalog_1.CatalogProgressState.Completed, catalog_1.CatalogProgressState.Error].includes(catalogState);
    var isCompleted = catalogState === catalog_1.CatalogProgressState.Completed;
    var showCatalogLoadingError = catalogProgressError && !isFileUploading;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ui_components_1.PageTitle, { className: 'title', title: t({ key: 'ENRICH_AI_TITLE' }), buttons: (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ui_components_1.Button, { rounded: true, className: "title-action", onClick: function () { return setIsUploadDataDialogOpen(true); }, variant: "outlined" }, t({ key: 'UPLOAD_FILE_BUTTON_TITLE' })),
                isCompleted && (react_1.default.createElement(ui_components_1.Button, { rounded: true, className: "title-action", href: catalogDownloadUrl, disabled: !catalogDownloadUrl, variant: "outlined", endIcon: react_1.default.createElement(icons_1.DownloadIcon, { width: 16, height: 16, color: ui_components_1.baseColors.background200 }) }, t({ key: 'DOWNLOAD_BUTTON_TITLE' }))))) }),
        react_1.default.createElement(enrich_ai_settings_widget_1.EnrichAISettingsWidget, { selectedProjectId: selectedProjectId, selectedCatalogId: selectedCatalogId, onSelectCatalog: handleSelectCatalog, onSelectProjectId: onSelectProjectId }),
        react_1.default.createElement(enrich_ai_styles_1.StyledStrategyPreviewWidget, null,
            react_1.default.createElement(strategy_preview_1.StrategyPreviewContainer, { isLoading: isLoading })),
        isUploadDataDialogOpen && (react_1.default.createElement(upload_data_dialog_1.UploadDataDialog, { isDisabled: !selectedCatalogId, isFileUploading: isFileUploading || isEnrichedDataUpdating, fileUploadError: (fileUploadError === null || fileUploadError === void 0 ? void 0 : fileUploadError.message) || (updateEnrichedDataError === null || updateEnrichedDataError === void 0 ? void 0 : updateEnrichedDataError.message), onClose: function () { return setIsUploadDataDialogOpen(false); }, onCatalogUpdate: handleCatalogUpdate, onEnrichedDataUpdate: handleEnrichedDataUpdate })),
        react_1.default.createElement(enrich_ai_styles_1.StyledPreviewProductsWidgetContainer, null,
            isCompleted && react_1.default.createElement(global_re_enrich_alert_1.GlobalReEnrichAlert, { onReEnrich: loadCatalog }),
            react_1.default.createElement(preview_products_widget_1.PreviewProductsWidget, { showNoDataFallback: isCatalogLoadingNotStarted, catalogLoadingError: showCatalogLoadingError && catalogProgressError.message, showPreviewLoader: !showCatalogLoadingError && (isLoading || !!error), showWidgetContent: isCompleted, onReEnrich: loadCatalog, previewLoaderComponent: (react_1.default.createElement(components_1.PreviewSkeleton, { className: "preview-products-skeleton" }, isCatalogInProgress && !showCatalogLoadingError && !isInitialCatalogStatusLoading ? (react_1.default.createElement(preview_products_loader_1.PreviewProductsLoader, { progress: catalogProgressValue, error: error, progressState: catalogState })) : null)) }))));
};
exports.EnrichAIWidgetsContainer = EnrichAIWidgetsContainer;
exports.default = exports.EnrichAIWidgetsContainer;
