"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCustomizeViewContainer = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var product_customize_view_constants_1 = require("./product-customize-view.constants");
var product_customize_view_styles_1 = require("./product-customize-view.styles");
var use_get_attributes_as_columns_1 = require("../client/use-get-attributes-as-columns");
var table_1 = require("../../../../../../common/table");
var attributes_1 = require("../../../../../client/attributes");
var generic_1 = require("../../../../../generic");
var feature_flags_1 = require("../../../../../../feature-flags");
var hooks_1 = require("../../../../../../hooks");
var localization_1 = require("../../../../../../localization");
var store_1 = require("../../../../../../store");
var ProductCustomizeViewContainer = function (_a) {
    var excludedColumns = _a.excludedColumns, columnsOrder = _a.columnsOrder, selectAllMode = _a.selectAllMode, columnsConfig = _a.columnsConfig, onChangeColumns = _a.onChangeColumns, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var baseFilterOptions = (0, react_1.useMemo)(function () {
        var options = [product_customize_view_constants_1.CustomizeViewFilterOptions.Base, product_customize_view_constants_1.CustomizeViewFilterOptions.Enriched];
        if (featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures)) {
            options.push(product_customize_view_constants_1.CustomizeViewFilterOptions.Measures);
        }
        return options;
    }, [featureFlagsService]);
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _b = __read((0, react_1.useState)(null), 2), filterAnchorEl = _b[0], setFilterAnchorEl = _b[1];
    var _c = __read((0, react_1.useState)(baseFilterOptions), 2), filterOptions = _c[0], setFilterOptions = _c[1];
    var existingIds = (0, react_1.useMemo)(function () { return columnsConfig.map(function (_a) {
        var id = _a.id;
        return id;
    }); }, [columnsConfig]);
    var _d = (0, use_get_attributes_as_columns_1.useGetAttributesAsColumns)({
        catalogId: catalogId,
        filterOptions: filterOptions,
        productTreeNodeId: productTreeNodeId,
        productAttributeFilter: {
            some: {
                label: {
                    notIn: __spreadArray(__spreadArray([], __read(existingIds), false), [attributes_1.PRODUCT_DESCRIPTION_LABEL], false),
                },
            },
        },
    }), _e = _d.pagination, isLoadingMore = _e.isLoadingMore, hasNextPage = _e.hasNextPage, onNextPage = _e.onNextPage, isLoading = _d.isLoading, error = _d.error, attributes = _d.attributes;
    var handleApply = function (selected) {
        if (selected === void 0) { selected = baseFilterOptions; }
        setFilterOptions(selected);
        setFilterAnchorEl(null);
    };
    var columns = (0, react_1.useMemo)(function () { return columnsConfig.concat(attributes.map(function (_a) {
        var label = _a.label, isEditable = _a.isEditable, isMeasure = _a.isMeasure, isAIEnriched = _a.isAIEnriched;
        return ({
            id: label,
            label: label,
            isEditable: isEditable,
            isMeasure: isMeasure,
            isAIEnriched: isAIEnriched,
        });
    })); }, [attributes, columnsConfig]);
    var multiSelectMenuOptions = (0, react_1.useMemo)(function () { return baseFilterOptions.map(function (value) { return ({
        value: value,
        label: t({ key: product_customize_view_constants_1.filterOptionToMessageKey[value] }),
        icon: value === product_customize_view_constants_1.CustomizeViewFilterOptions.Enriched ? react_1.default.createElement(product_customize_view_styles_1.StyledAISparkleIcon, { fill: ui_components_1.baseColors.blue300 }) : undefined,
    }); }); }, [t, baseFilterOptions]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(table_1.TableCustomizeView, { isOpen: true, columns: columns, isLoadingMore: isLoadingMore, hasNextPage: hasNextPage, onLoadMore: onNextPage, error: error === null || error === void 0 ? void 0 : error.message, isLoading: isLoading, excludedColumns: excludedColumns, columnsOrder: columnsOrder, selectAllMode: selectAllMode, onChangeColumns: onChangeColumns, onClose: onClose, onFilterOpen: setFilterAnchorEl }),
        filterAnchorEl && (react_1.default.createElement(generic_1.MultiSelectMenu, { options: multiSelectMenuOptions, anchorEl: filterAnchorEl, onClose: function () { return setFilterAnchorEl(null); }, selected: filterOptions, onApply: handleApply }))));
};
exports.ProductCustomizeViewContainer = ProductCustomizeViewContainer;
exports.default = exports.ProductCustomizeViewContainer;
