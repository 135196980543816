"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectOrganizationSideEffects = void 0;
var react_redux_1 = require("react-redux");
var creators_1 = require("../store/actions/creators");
var state_1 = require("../store/state");
// eslint-disable-next-line import/prefer-default-export
var useSelectOrganizationSideEffects = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var dispatchSideEffects = function () {
        dispatch((0, creators_1.productsSetFilterActive)(state_1.ProductFilterActiveTypes.All));
        dispatch((0, creators_1.productsSetFilterOperator)(state_1.ProductFilterOperatorType.Or));
        dispatch((0, creators_1.refinementsSetFilter)(state_1.AvailableRefinementType.All));
        dispatch((0, creators_1.productsSetIncludedCategoriesIds)([]));
        dispatch((0, creators_1.productsSetExcludedCategoriesIds)([]));
        dispatch((0, creators_1.productsSetIncludedAttributesIds)([]));
        dispatch((0, creators_1.productsSetExcludedAttributesIds)([]));
        dispatch((0, creators_1.productsClearIncludedTaxonomyClassIds)());
        dispatch((0, creators_1.productsClearExcludedTaxonomyClassIds)());
        dispatch((0, creators_1.setAdvancedViewerFilter)([]));
        dispatch((0, creators_1.facetNavigationSetOpenAttributes)({}));
        dispatch((0, creators_1.facetNavigationSetOpenCategories)({}));
        dispatch((0, creators_1.facetNavigationSetOpenTaxonomyClasses)(false));
        dispatch((0, creators_1.facetNavigationSetSearchTerm)(''));
    };
    return { performOrganizationSelectionSideEffects: dispatchSideEffects };
};
exports.useSelectOrganizationSideEffects = useSelectOrganizationSideEffects;
