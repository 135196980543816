"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetApolloClient = void 0;
var react_1 = require("react");
var apollo_client_utils_1 = require("./apollo-client.utils");
var context_1 = require("../context");
var hooks_1 = require("../hooks");
var utils_1 = require("../utils");
var useGetApolloClient = function () {
    var url = (0, utils_1.useGetEnrichAiAPIUrl)();
    var token = (0, hooks_1.useToken)();
    var _a = (0, react_1.useContext)(context_1.EnrichContext), config = _a.config, logError = _a.logError;
    var client = (0, react_1.useRef)((0, apollo_client_utils_1.createApolloClient)(url, { token: token, isNonPublicEnv: config.isNonPublicEnv, logError: logError }));
    return client.current;
};
exports.useGetApolloClient = useGetApolloClient;
exports.default = exports.useGetApolloClient;
