"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalize = void 0;
// eslint-disable-next-line import/prefer-default-export
function normalize(models) {
    return {
        byId: models.reduce(function (byId, model) {
            var _a;
            return Object.assign(byId, (_a = {}, _a[model.id] = model, _a));
        }, {}),
        allIds: models.map(function (_a) {
            var id = _a.id;
            return id;
        }),
    };
}
exports.normalize = normalize;
