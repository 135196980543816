"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAttributeValueTitle = void 0;
var getAttributeValueTitle = function (_a) {
    var _b;
    var subAttribute = _a.subAttribute, getAttributeName = _a.getAttributeName, getAttributeValueName = _a.getAttributeValueName;
    var title = (_b = subAttribute.valueName) !== null && _b !== void 0 ? _b : (subAttribute.valueId
        ? getAttributeValueName(subAttribute.valueId) || null
        : getAttributeName(subAttribute.attributeId) || null);
    return title;
};
exports.getAttributeValueTitle = getAttributeValueTitle;
