"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTableSearch = void 0;
var react_1 = require("react");
var predicate_language_1 = require("@groupby/predicate-language");
var debounce_1 = require("debounce");
var constants_1 = require("../../constants");
var selectors_1 = require("../../store/selectors");
var useTableSearch = function (searchableFields, setLastSelectedRowId) {
    var selectedOrganizationsIds = (0, selectors_1.useSelectedOrganizationsIdsSelector)();
    var _a = __read((0, react_1.useState)(''), 2), searchQuery = _a[0], setSearchQuery = _a[1];
    var _b = __read((0, react_1.useState)(new predicate_language_1.PredicateBuilder()), 2), searchFilter = _b[0], setSearchFilter = _b[1];
    var handleSearch = (0, react_1.useCallback)((0, debounce_1.debounce)(function (query) {
        var updatedFilter = new predicate_language_1.PredicateBuilder();
        var pattern = new RegExp(query, 'gi');
        searchableFields.forEach(function (field) { return updatedFilter.or((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", ""], ["", ""])), field), '=~', pattern)); });
        setSearchFilter(updatedFilter);
    }, constants_1.SEARCH_QUERY_UPDATE_DELAY), []);
    var handleSearchProduct = function (search) {
        setLastSelectedRowId(null);
        setSearchQuery(search);
    };
    (0, react_1.useEffect)(function () {
        if (searchQuery) {
            handleSearch(searchQuery);
        }
        else {
            // skip debouncing
            setSearchFilter(new predicate_language_1.PredicateBuilder());
        }
    }, [searchQuery, handleSearch]);
    (0, react_1.useEffect)(function () {
        setSearchQuery('');
    }, [selectedOrganizationsIds]);
    return {
        handleSearch: handleSearchProduct,
        searchFilter: searchFilter,
        searchQuery: searchQuery,
    };
};
exports.useTableSearch = useTableSearch;
exports.default = exports.useTableSearch;
var templateObject_1;
