"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEntitySelectHook = void 0;
var react_1 = require("react");
function useEntitySelectHook() {
    var _a = __read((0, react_1.useState)(false), 2), selectAll = _a[0], setSelectAll = _a[1];
    var _b = __read((0, react_1.useState)(new Set()), 2), ids = _b[0], setIds = _b[1];
    // Migrate to some general solution. Maybe use an updateUniqueItemListBasedOnValue here
    var handleToggleSelection = function (id) {
        var newIds = new Set(ids);
        if (selectAll) {
            if (newIds.has(id)) {
                newIds.delete(id);
            }
            else {
                newIds.add(id);
            }
        }
        else if (newIds.has(id)) {
            newIds.delete(id);
        }
        else {
            newIds.add(id);
        }
        setIds(newIds);
    };
    var handleSelectAll = function () {
        if (selectAll && !ids.size) {
            setSelectAll(false);
        }
        else {
            setSelectAll(true);
        }
        setIds(new Set());
    };
    var handleResetSelection = function () {
        setSelectAll(false);
        setIds(new Set());
    };
    var checkItemSelected = function (item) { return (selectAll ? !ids.has(item) : ids.has(item)); };
    return {
        selectAll: selectAll,
        ids: Array.from(ids),
        handleToggleSelection: handleToggleSelection,
        setSelectAll: setSelectAll,
        setIds: function (newIds) { return setIds(new Set(newIds)); },
        handleResetSelection: handleResetSelection,
        handleSelectAll: handleSelectAll,
        checkItemSelected: checkItemSelected,
    };
}
exports.useEntitySelectHook = useEntitySelectHook;
