"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterOption = exports.FilterField = exports.FilterOperator = void 0;
var FilterOperator;
(function (FilterOperator) {
    FilterOperator["Is"] = "is";
    FilterOperator["IsNot"] = "isNot";
    FilterOperator["Contains"] = "contains";
    FilterOperator["DoesNotContain"] = "notContain";
    FilterOperator["StartsWith"] = "startsWith";
    FilterOperator["EndsWith"] = "endsWith";
    FilterOperator["Before"] = "before";
    FilterOperator["After"] = "after";
    FilterOperator["Between"] = "between";
})(FilterOperator = exports.FilterOperator || (exports.FilterOperator = {}));
var FilterField;
(function (FilterField) {
    FilterField["Field"] = "field";
    FilterField["TaxonomyClass"] = "classId";
    FilterField["Category"] = "categoryId";
    FilterField["DateCreated"] = "createdAt";
    FilterField["DateUpdated"] = "updatedAt";
    FilterField["DateLastCommented"] = "lastCommentAt";
    FilterField["Attribute"] = "attribute";
    FilterField["Value"] = "value";
})(FilterField = exports.FilterField || (exports.FilterField = {}));
var FilterOption;
(function (FilterOption) {
    FilterOption["Field"] = "field";
    FilterOption["FieldSpecifier"] = "fieldSpecifier";
    FilterOption["Operator"] = "operator";
    FilterOption["Value"] = "value";
    FilterOption["Connector"] = "connector";
})(FilterOption = exports.FilterOption || (exports.FilterOption = {}));
