"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlagsService = void 0;
var react_1 = require("react");
var context_1 = require("../context");
var useFeatureFlagsService = function () {
    var featureFlagsService = (0, react_1.useContext)(context_1.EnrichContext).featureFlagsService;
    return { featureFlagsService: featureFlagsService };
};
exports.useFeatureFlagsService = useFeatureFlagsService;
exports.default = exports.useFeatureFlagsService;
