"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TICKETS_FOR_REPLACE_CATALOG = exports.TICKETS_FOR_RE_ENRICH_CATALOG = exports.TICKETS_FOR_THE_NEW_CATALOG = exports.CATALOG_IMPORT_FROM_URL_TICKET_DESCRIPTION = exports.ticketTypeToContentMap = exports.TicketType = void 0;
var TicketType;
(function (TicketType) {
    TicketType[TicketType["CatalogImport"] = 0] = "CatalogImport";
    TicketType[TicketType["CreateProductTree"] = 1] = "CreateProductTree";
    TicketType[TicketType["Enrich"] = 2] = "Enrich";
    TicketType[TicketType["Refine"] = 3] = "Refine";
    TicketType[TicketType["QualityAssurance"] = 4] = "QualityAssurance";
    TicketType[TicketType["CatalogExport"] = 5] = "CatalogExport";
})(TicketType = exports.TicketType || (exports.TicketType = {}));
// TODO: generate tickets descriptions on BE side
// no localization is needed as this text is only a prompt for the AI
exports.ticketTypeToContentMap = (_a = {},
    _a[TicketType.CatalogImport] = {
        title: 'CatalogImport Ticket',
        description: 'Please import products into this catalog using its source.',
    },
    _a[TicketType.CreateProductTree] = {
        title: 'Product Tree Ticket',
        description: 'Please create a new product tree for this catalog.',
    },
    _a[TicketType.Enrich] = {
        title: 'Enrich Ticket',
        description: 'Please enrich all of the products in this catalog.',
    },
    _a[TicketType.Refine] = {
        title: 'Refine Ticket',
        description: 'Please Refine an existing catalog\'s attached "product tree". Do not attempt tickets about "enrichment".',
    },
    _a[TicketType.QualityAssurance] = {
        title: 'QualityAssurance Ticket',
        description: 'Please conduct quality assurance for this catalog.',
    },
    _a[TicketType.CatalogExport] = {
        title: 'CatalogExport Ticket',
        description: 'Please export the catalog to a file and upload to a URL.',
    },
    _a);
exports.CATALOG_IMPORT_FROM_URL_TICKET_DESCRIPTION = 'Please import products from the URL ';
exports.TICKETS_FOR_THE_NEW_CATALOG = [
    TicketType.CatalogImport,
    TicketType.Enrich,
    TicketType.QualityAssurance,
    TicketType.CatalogExport,
];
exports.TICKETS_FOR_RE_ENRICH_CATALOG = [
    TicketType.Enrich,
    TicketType.QualityAssurance,
    TicketType.CatalogExport,
];
exports.TICKETS_FOR_REPLACE_CATALOG = [
    TicketType.CatalogImport,
    TicketType.Enrich,
    TicketType.QualityAssurance,
    TicketType.CatalogExport,
];
