"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailsAttribute = void 0;
var react_1 = __importStar(require("react"));
var product_details_attribute_values_component_1 = require("./product-details-attribute-values.component");
var product_details_attribute_styles_1 = require("./product-details-attribute.styles");
var useChangeAttributeValues_1 = require("./useChangeAttributeValues");
var ProductDetailsAttribute = function (_a) {
    var label = _a.label, disabled = _a.disabled, values = _a.values, productId = _a.productId, refetchProductPresentations = _a.refetchProductPresentations;
    var currentSelectedValues = (0, react_1.useMemo)(function () { return values
        .filter(function (value) { return value.selected; })
        .map(function (value) { return value.value; }); }, [values]);
    var _b = (0, useChangeAttributeValues_1.useChangeAttributeValues)({
        label: label,
        currentSelectedValues: currentSelectedValues,
        productId: productId,
        refetch: refetchProductPresentations,
    }), selectedValues = _b.selectedValues, handleSelectionChange = _b.handleSelectionChange, handleSelectionSave = _b.handleSelectionSave, handleSelectionCancel = _b.handleSelectionCancel;
    return (react_1.default.createElement(product_details_attribute_styles_1.StyledAttributeWrapper, null,
        react_1.default.createElement(product_details_attribute_styles_1.StyledAttributeTitle, null, label),
        react_1.default.createElement(product_details_attribute_values_component_1.ProductDetailsAttributeValues, { disabled: disabled, values: values, selectedValues: selectedValues, isLoading: false, onChange: handleSelectionChange, onSave: handleSelectionSave, onCancel: handleSelectionCancel, onDelete: function (value) { return handleSelectionSave(selectedValues.filter(function (selectedValue) { return selectedValue !== value; })); }, onClose: function () { return handleSelectionCancel(); } })));
};
exports.ProductDetailsAttribute = ProductDetailsAttribute;
