"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetContractsFromCache = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var get_contracts_constants_1 = require("../../contract/get-contracts.constants");
var get_contracts_query_1 = require("../../contract/get-contracts.query");
var store_1 = require("../../../../store");
var useGetContractsFromCache = function () {
    var client = (0, client_1.useApolloClient)();
    var selectedProjectId = (0, store_1.useSelectedProjectId)();
    var catalogDefaultTags = (0, react_1.useMemo)(function () {
        var _a, _b, _c;
        if (!selectedProjectId) {
            return null;
        }
        var data = client.readQuery({
            query: get_contracts_query_1.GET_CONTRACTS,
            variables: {
                projectId: selectedProjectId,
                optionsToCheck: get_contracts_constants_1.contractOptionsToCheck,
            },
        });
        if (!data) {
            return null;
        }
        var allowedOptions = ((_c = (_b = (_a = data.projects.edges[0]) === null || _a === void 0 ? void 0 : _a.node.contracts.edges[0]) === null || _b === void 0 ? void 0 : _b.node) !== null && _c !== void 0 ? _c : {}).allowedOptions;
        if (!allowedOptions) {
            return null;
        }
        return Object.keys(allowedOptions).map(function (item) { return ({
            key: item,
            value: allowedOptions[item].toString(),
        }); });
    }, [client, selectedProjectId]);
    return { catalogDefaultTags: catalogDefaultTags };
};
exports.useGetContractsFromCache = useGetContractsFromCache;
