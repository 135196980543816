"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributesFacetNav = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var attributes_utils_1 = require("../attributes.utils");
var tree_navigation_1 = require("../../tree-navigation");
var formal_attributes_1 = require("../../../formal-attributes");
var store_1 = require("../../../../store");
var utils_1 = require("../../../../utils");
var AttributesFacetNav = function (_a) {
    var expandOverride = _a.expandOverride, debouncedSearchTerm = _a.debouncedSearchTerm;
    var actualAttributes = (0, store_1.useRefinementsDataSelector)().actualAttributes;
    var includedAttributesIds = (0, store_1.useProductsIncludedAttributesIds)();
    var excludedAttributesIds = (0, store_1.useProductsExcludedAttributesIds)();
    var _b = (0, formal_attributes_1.useFormalAttributes)(), getAttributeName = _b.getAttributeName, getAttributeValueName = _b.getAttributeValueName;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = __read((0, react_1.useState)(new Map()), 2), facetNavData = _c[0], setFacetNavData = _c[1];
    var changeAttributesIds = (0, react_1.useCallback)(function (selectedId, prevAttributesIds) {
        var selectedAttributesIds = [selectedId];
        return (0, ramda_1.all)(function (selectedAttributeId) { return prevAttributesIds.includes(selectedAttributeId); }, selectedAttributesIds)
            ? (0, ramda_1.without)(selectedAttributesIds, prevAttributesIds)
            : (0, ramda_1.uniq)((0, ramda_1.concat)(selectedAttributesIds, prevAttributesIds));
    }, []);
    var handleSetInclude = (0, react_1.useCallback)(function (selectedId) {
        var includedAttributeIds = changeAttributesIds(selectedId, includedAttributesIds);
        dispatch((0, store_1.productsSetIncludedAttributesIds)(includedAttributeIds));
    }, [dispatch, includedAttributesIds, changeAttributesIds]);
    var handleSetExclude = (0, react_1.useCallback)(function (selectedId) {
        var excludedAttributeIds = changeAttributesIds(selectedId, excludedAttributesIds);
        dispatch((0, store_1.productsSetExcludedAttributesIds)(excludedAttributeIds));
    }, [dispatch, excludedAttributesIds, changeAttributesIds]);
    var getActualAttributesMap = (0, react_1.useCallback)(function () {
        var attributeMap = actualAttributes.reduce(function (map, actualAttribute) {
            var _a, _b;
            var valueTitle = (0, attributes_utils_1.getAttributeValueTitle)({ subAttribute: actualAttribute, getAttributeName: getAttributeName, getAttributeValueName: getAttributeValueName });
            if (!valueTitle)
                return map;
            var valueId = [actualAttribute.attributeId, (_a = actualAttribute.valueId) !== null && _a !== void 0 ? _a : '', (_b = actualAttribute.valueName) !== null && _b !== void 0 ? _b : ''].join(store_1.ATTRIBUTE_PROPERTY_DELIMITER);
            var attributeValue = __assign(__assign({}, actualAttribute), { children: new Map(), title: valueTitle, parentId: actualAttribute.attributeId, id: valueId });
            if (!map.has(actualAttribute.attributeId)) {
                map.set(actualAttribute.attributeId, __assign(__assign({}, actualAttribute), { title: getAttributeName(actualAttribute.attributeId), children: new Map([[valueId, attributeValue]]), parentId: null, id: actualAttribute.attributeId }));
            }
            else {
                var attribute = map.get(actualAttribute.attributeId);
                attribute.children.set(valueId, attributeValue);
            }
            return map;
        }, new Map());
        // order attribute values alphabetically
        attributeMap.forEach(function (attribute) {
            if (attribute.children && attribute.children.size > 1) {
                attribute.children = new Map(__spreadArray([], __read(attribute.children.entries()), false).sort(function (a, b) { return (a[1].title > b[1].title ? 1 : -1); }));
            }
        });
        return attributeMap;
    }, [getAttributeName, actualAttributes, getAttributeValueName]);
    var parentIds = (0, react_1.useMemo)(function () { return (0, utils_1.getNodesWithChildren)(facetNavData); }, [facetNavData]);
    (0, react_1.useEffect)(function () {
        if (actualAttributes.length) {
            var attributesMap = getActualAttributesMap();
            setFacetNavData(attributesMap);
        }
    }, [actualAttributes, getActualAttributesMap]);
    return (react_1.default.createElement(tree_navigation_1.TreeNavigation, { included: includedAttributesIds, excluded: excludedAttributesIds, debouncedSearchTerm: debouncedSearchTerm, setExcluded: handleSetExclude, setIncluded: handleSetInclude, treeData: facetNavData, parentIds: parentIds, expandOverride: expandOverride }));
};
exports.AttributesFacetNav = AttributesFacetNav;
exports.default = exports.AttributesFacetNav;
