"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.enrichAISetExpandedNodes = exports.enrichAIRemoveChanges = exports.enrichAIAddChanges = exports.enrichAISetProductTree = exports.enrichAISetSelectedTreeNodePath = exports.enrichAISetSelectedProject = exports.enrichAISetSelectedCatalog = void 0;
var Actions = __importStar(require("../types/enrich-ai"));
var utils_1 = require("../utils");
var enrichAISetSelectedCatalog = function (selectedCatalog) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_SELECTED_CATALOG, { selectedCatalog: selectedCatalog }); };
exports.enrichAISetSelectedCatalog = enrichAISetSelectedCatalog;
var enrichAISetSelectedProject = function (selectedProjectId) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_SELECTED_PROJECT, { selectedProjectId: selectedProjectId }); };
exports.enrichAISetSelectedProject = enrichAISetSelectedProject;
var enrichAISetSelectedTreeNodePath = function (selectedTreeNodePath) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH, { selectedTreeNodePath: selectedTreeNodePath }); };
exports.enrichAISetSelectedTreeNodePath = enrichAISetSelectedTreeNodePath;
var enrichAISetProductTree = function (productTree) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_PRODUCT_TREE, { productTree: productTree }); };
exports.enrichAISetProductTree = enrichAISetProductTree;
var enrichAIAddChanges = function (changes) { return (0, utils_1.createAction)(Actions.ENRICH_AI_ADD_CHANGES, { changes: changes }); };
exports.enrichAIAddChanges = enrichAIAddChanges;
var enrichAIRemoveChanges = function (strategyNodePath) { return (0, utils_1.createAction)(Actions.ENRICH_AI_REMOVE_CHANGES, { strategyNodePath: strategyNodePath }); };
exports.enrichAIRemoveChanges = enrichAIRemoveChanges;
var enrichAISetExpandedNodes = function (expandedNodes) { return (0, utils_1.createAction)(Actions.ENRICH_AI_SET_EXPANDED_NODES, { expandedNodes: expandedNodes }); };
exports.enrichAISetExpandedNodes = enrichAISetExpandedNodes;
