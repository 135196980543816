"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchProducts = void 0;
var react_1 = require("react");
var predicate_language_1 = require("@groupby/predicate-language");
var react_redux_1 = require("react-redux");
var hooks_1 = require("../../../../../hooks");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var useFetchProducts = function (countOffset, limitProducts, activeFilter) {
    var total = (0, store_1.useProductsTotal)();
    var addError = (0, hooks_1.useNotification)().addError;
    var dispatch = (0, react_redux_1.useDispatch)();
    var isExclusionModeOn = (0, store_1.useIsExclusionModeOn)();
    var selectedProductIds = (0, store_1.useSelectedProductIds)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var token = (0, hooks_1.useToken)();
    var filter = new predicate_language_1.PredicateBuilder(activeFilter);
    filter.and((0, predicate_language_1.where)((0, predicate_language_1.field)('id'), isExclusionModeOn ? '!@<' : '@<', selectedProductIds.map(function (id) { return (0, predicate_language_1.uuid)(id); })));
    var client = (0, hooks_1.useEnrichClient)();
    var _a = __read((0, react_1.useState)(true), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read((0, react_1.useState)([]), 2), products = _b[0], setProducts = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), hasMore = _c[0], setHasMore = _c[1];
    var onCheckProduct = function (id) {
        setProducts(products.map(function (el) { return ((el.id === id) ? __assign(__assign({}, el), { isChecked: !el.isChecked }) : el); }));
        dispatch((0, store_1.addSelectedProductId)(id));
    };
    (0, react_1.useEffect)(function () {
        if (token) {
            setLoading(true);
            client.products.getMany({
                filter: filter,
                page: { limit: limitProducts, offset: countOffset },
            }).then(function (data) {
                setProducts(function (prevProducts) { return __spreadArray(__spreadArray([], __read(prevProducts), false), __read(data.map(function (elem) { return (__assign(__assign({}, elem), { isChecked: true })); })), false); });
                setHasMore(countOffset + limitProducts < total);
                setLoading(false);
            }).catch(function () { return addError(t({ key: 'PRODUCT_EDITOR_TABLE_LOADING_ERROR' })); });
        }
    }, [countOffset, token]);
    return {
        loading: loading,
        products: products,
        hasMore: hasMore,
        onCheckProduct: onCheckProduct,
    };
};
exports.useFetchProducts = useFetchProducts;
exports.default = exports.useFetchProducts;
