"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsPanel = exports.ACTIVE_FILTER_TYPES = void 0;
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var partials_1 = require("./partials");
var settings_panel_styles_1 = require("./settings-panel.styles");
var feature_flags_1 = require("../../../../feature-flags");
var hooks_1 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var state_1 = require("../../../../store/state");
exports.ACTIVE_FILTER_TYPES = [
    state_1.ProductFilterActiveTypes.All,
    state_1.ProductFilterActiveTypes.Active,
    state_1.ProductFilterActiveTypes.Inactive,
];
var REFINEMENTS_FILTER_TYPES = [state_1.AvailableRefinementType.All, state_1.AvailableRefinementType.Matches];
var PRODUCT_FILTER_OPERATOR = [state_1.ProductFilterOperatorType.And, state_1.ProductFilterOperatorType.Or];
var SettingsPanel = function (_a) {
    var activeFilter = _a.activeFilter, availableRefinementsFilter = _a.availableRefinementsFilter, onSearchTermChange = _a.onSearchTermChange, onChangeFilter = _a.onChangeFilter, onChangeFilterOperator = _a.onChangeFilterOperator, onRefinementsFilter = _a.onRefinementsFilter, onSetOrganization = _a.onSetOrganization, organizations = _a.organizations, resetSettingSelections = _a.resetSettingSelections, resultsFilter = _a.resultsFilter, search = _a.debouncedSearchTerm, selectedOrganization = _a.selectedOrganization;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var isProductCountsEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.ProductCounts);
    var _b = __read((0, react_1.useState)(search !== null && search !== void 0 ? search : ''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var handleUpdateSearchTerm = function (value) {
        setSearchTerm(value);
        onSearchTermChange(value);
    };
    var handleSearchTermChange = function (event) { return handleUpdateSearchTerm(event.target.value); };
    var handleSearchTermClear = function () { return handleUpdateSearchTerm(''); };
    (0, react_1.useEffect)(function () {
        setSearchTerm(search !== null && search !== void 0 ? search : '');
    }, [search]);
    return (react_1.default.createElement(settings_panel_styles_1.StyledSettingsWrapper, null,
        react_1.default.createElement(settings_panel_styles_1.StyledSettingsHeader, null,
            react_1.default.createElement(settings_panel_styles_1.StyledSettingsTitleText, null, t({ key: 'SETTINGS' })),
            react_1.default.createElement(settings_panel_styles_1.StyledResetButton, { title: t({ key: 'RESET' }), size: "small", onClick: resetSettingSelections })),
        react_1.default.createElement(settings_panel_styles_1.StyledButtonsWrapper, null,
            react_1.default.createElement(partials_1.OrganizationSelector, { organizations: organizations, selectedOrganization: selectedOrganization, selectOrganization: onSetOrganization })),
        react_1.default.createElement(settings_panel_styles_1.StyledButtonsWrapper, null,
            react_1.default.createElement(settings_panel_styles_1.StyledButtonsHeader, null, t({ key: 'PRODUCTS' })),
            react_1.default.createElement(settings_panel_styles_1.StyledToggleButtons, { options: exports.ACTIVE_FILTER_TYPES.map(function (activeFilterType) { return ({
                    title: t({ key: activeFilterType }),
                    value: activeFilterType,
                }); }), value: activeFilter, onChange: function (e) { return onChangeFilter(e.currentTarget.value); }, size: "small", exclusive: true })),
        isProductCountsEnabled ? react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(settings_panel_styles_1.StyledButtonsWrapper, null,
                react_1.default.createElement(settings_panel_styles_1.StyledButtonsHeader, null, t({ key: 'VIEW' })),
                react_1.default.createElement(settings_panel_styles_1.StyledToggleButtons, { options: REFINEMENTS_FILTER_TYPES.map(function (refinementsFilterType) { return ({
                        title: t({ key: refinementsFilterType }),
                        value: refinementsFilterType,
                    }); }), value: availableRefinementsFilter, onChange: function (e) { return onRefinementsFilter(e.currentTarget.value); }, size: "small", radioGroupMode: true }))) : null,
        react_1.default.createElement(settings_panel_styles_1.StyledButtonsWrapper, null,
            react_1.default.createElement(settings_panel_styles_1.StyledButtonsHeader, null, t({ key: 'RESULTS' })),
            react_1.default.createElement(settings_panel_styles_1.StyledToggleButtons, { options: PRODUCT_FILTER_OPERATOR.map(function (activeResultType) { return ({
                    title: t({ key: activeResultType }),
                    value: activeResultType,
                }); }), value: resultsFilter, onChange: function (e) { return onChangeFilterOperator(e.currentTarget.value); }, size: "small", className: "and-or" })),
        react_1.default.createElement(material_1.Divider, { variant: "middle" }),
        react_1.default.createElement(partials_1.SearchInput, { handleSearchTermChange: handleSearchTermChange, searchTerm: searchTerm, handleSearchTermClear: handleSearchTermClear })));
};
exports.SettingsPanel = SettingsPanel;
