"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumnFilter = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var hooks_1 = require("../../hooks");
var useColumnFilter = function (key) {
    var ls = (0, hooks_1.useLocalStorage)();
    var _a = __read((0, react_1.useState)(function () { var _a; return ((_a = ls.getItem(key)) !== null && _a !== void 0 ? _a : {}); }), 2), columnFilter = _a[0], setColumnFilter = _a[1];
    var _b = __read((0, react_1.useState)(null), 2), filterOptions = _b[0], setFilterOptions = _b[1];
    var updateColumnFilter = (0, react_1.useCallback)(function (value) {
        var _a;
        if (filterOptions) {
            var updatedFilter = __assign(__assign({}, columnFilter), (_a = {}, _a[filterOptions.id] = value, _a));
            setColumnFilter(updatedFilter);
            ls.setItem(key, updatedFilter);
        }
    }, [columnFilter, key, ls, filterOptions]);
    var getColumnFilterValue = (0, react_1.useCallback)(function (columnId) {
        if (columnId) {
            return columnFilter[columnId];
        }
        if (!filterOptions) {
            return undefined;
        }
        return columnFilter[filterOptions.id];
    }, [columnFilter, filterOptions]);
    var openColumnFilter = (0, react_1.useCallback)(function (id, anchorEl) { return setFilterOptions({ id: id, anchorEl: anchorEl }); }, []);
    var closeColumnFilter = (0, react_1.useCallback)(function () { return setFilterOptions(null); }, []);
    var isColumnFilterActive = (0, react_1.useCallback)(function (id) { return !(0, ramda_1.isNil)(columnFilter[id]); }, [columnFilter]);
    return {
        anchorEl: filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.anchorEl,
        openColumnFilter: openColumnFilter,
        updateColumnFilter: updateColumnFilter,
        getColumnFilterValue: getColumnFilterValue,
        closeColumnFilter: closeColumnFilter,
        isColumnFilterActive: isColumnFilterActive,
    };
};
exports.useColumnFilter = useColumnFilter;
exports.default = exports.useColumnFilter;
