"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributeValuesMap = exports.attributeValuesMapSelector = exports.useAttributesSelector = exports.attributesSelector = exports.useFormalAttributeValuesMapSelector = exports.useFormalAttributeValuessMap = exports.formalAttributeValuesMapSelector = exports.useFormalAttributesMapSelector = exports.useFormalAttributesMap = exports.formalAttributesMapSelector = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
exports.formalAttributesMapSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var formalAttributesMap = _a.attributes.formalAttributesMap;
    return formalAttributesMap;
});
exports.useFormalAttributesMap = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var formalAttributesMap = _a.attributes.formalAttributesMap;
    return formalAttributesMap;
});
var useFormalAttributesMapSelector = function () { return (0, react_redux_1.useSelector)(exports.useFormalAttributesMap); };
exports.useFormalAttributesMapSelector = useFormalAttributesMapSelector;
exports.formalAttributeValuesMapSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var formalAttributeValuesMap = _a.attributes.formalAttributeValuesMap;
    return formalAttributeValuesMap;
});
exports.useFormalAttributeValuessMap = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var formalAttributeValuesMap = _a.attributes.formalAttributeValuesMap;
    return formalAttributeValuesMap;
});
var useFormalAttributeValuesMapSelector = function () { return (0, react_redux_1.useSelector)(exports.useFormalAttributeValuessMap); };
exports.useFormalAttributeValuesMapSelector = useFormalAttributeValuesMapSelector;
exports.attributesSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var attributes = _a.attributes.attributes;
    return attributes;
});
var useAttributesSelector = function () { return (0, react_redux_1.useSelector)(exports.attributesSelector); };
exports.useAttributesSelector = useAttributesSelector;
var attributeValuesMapSelector = function (_state, isMultiFacetNavEnabled) { return (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var _b = _a.attributes, attributeValuesMap = _b.attributeValuesMap, oldAttributeValuesMap = _b.oldAttributeValuesMap;
    return (isMultiFacetNavEnabled ? attributeValuesMap : oldAttributeValuesMap);
}); };
exports.attributeValuesMapSelector = attributeValuesMapSelector;
var useAttributeValuesMap = function (isMultiFacetNavEnabled) { return (0, react_redux_1.useSelector)(function (state) { return (0, exports.attributeValuesMapSelector)(state, isMultiFacetNavEnabled); }); };
exports.useAttributeValuesMap = useAttributeValuesMap;
