"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetStrategyValueOptionList = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var use_get_strategy_values_hook_1 = require("./use-get-strategy-values.hook");
var client_utils_1 = require("../../client.utils");
var useGetStrategyValueOptionList = function (_a) {
    var props = __rest(_a, []);
    var _b = (0, use_get_strategy_values_hook_1.useGetStrategyValues)(__assign({}, props)), data = _b.data, previousData = _b.previousData, isLoading = _b.isLoading, refetch = _b.refetch, restData = __rest(_b, ["data", "previousData", "isLoading", "refetch"]);
    var onRefetch = (0, react_1.useCallback)(function (searchString) { return refetch({
        filter: {
            some: {
                value: {
                    contains: (0, client_utils_1.normalizeSearchString)(searchString),
                },
            },
        },
    }); }, [refetch]);
    return __assign(__assign({}, restData), { isLoading: isLoading, data: isLoading && (!data || (0, ramda_1.isEmpty)(data)) ? previousData : data, refetch: onRefetch });
};
exports.useGetStrategyValueOptionList = useGetStrategyValueOptionList;
