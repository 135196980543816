"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormObserver = void 0;
var react_1 = require("react");
var formik_1 = require("formik");
var FormObserver = function (_a) {
    var onChange = _a.onChange;
    var values = (0, formik_1.useFormikContext)().values;
    (0, react_1.useEffect)(function () {
        onChange(values);
    }, [values, onChange]);
    return null;
};
exports.FormObserver = FormObserver;
exports.default = exports.FormObserver;
