"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductsTableConfig = void 0;
var react_1 = __importStar(require("react"));
var constants_1 = require("./constants");
var use_get_attributes_as_columns_1 = require("../client/use-get-attributes-as-columns");
var AttributeTitle_1 = require("../partials/AttributeTitle");
var product_customize_view_constants_1 = require("../products-customize-view/product-customize-view.constants");
var table_1 = require("../../../../../../common/table");
var store_1 = require("../../../../../../store");
var attributes_1 = require("../../../../../client/attributes");
var useProductsTableConfig = function (_a) {
    var _b = _a.productsTableColumnsConfig, productsTableColumnsConfig = _b === void 0 ? [] : _b, onCellClick = _a.onCellClick;
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var productTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _c = (0, table_1.useCustomizeTableColumns)({
        defaultExcludedColumns: productsTableColumnsConfig.map(function (_a) {
            var id = _a.id, _b = _a.isMeasure, isMeasure = _b === void 0 ? false : _b;
            return ({ label: id, isMeasure: isMeasure });
        }),
        defaultColumnsOrder: productsTableColumnsConfig.map(function (_a) {
            var id = _a.id;
            return id;
        }),
    }), excludedColumns = _c.excludedColumns, excludedLabelColumns = _c.excludedLabelColumns, excludedMeasureColumns = _c.excludedMeasureColumns, columnsOrder = _c.columnsOrder, selectAllMode = _c.selectAllMode, checkIsColumnSelected = _c.checkIsColumnSelected, actions = _c.actions;
    var productAttributeFilter = (0, react_1.useMemo)(function () {
        if (!excludedLabelColumns.length) {
            return null;
        }
        var attributesFilter = (!selectAllMode ? { in: excludedLabelColumns } : { notIn: excludedLabelColumns });
        return { some: { label: attributesFilter } };
    }, [excludedLabelColumns, selectAllMode]);
    var productMeasureFilter = (0, react_1.useMemo)(function () {
        if (!excludedMeasureColumns.length) {
            return null;
        }
        var attributesFilter = (!selectAllMode ? { in: excludedMeasureColumns } : { notIn: excludedMeasureColumns });
        return { some: { dimension: attributesFilter } };
    }, [excludedMeasureColumns, selectAllMode]);
    var _d = (0, use_get_attributes_as_columns_1.useGetAttributesAsColumns)({
        catalogId: catalogId,
        productTreeNodeId: productTreeNodeId,
        productAttributeFilter: productAttributeFilter,
        productMeasureFilter: productMeasureFilter,
        filterOptions: product_customize_view_constants_1.defaultFilterOptions,
    }), isLoading = _d.isLoading, error = _d.error, attributes = _d.attributes;
    var categoryLabel = (0, store_1.useProductTreeTagValue)(attributes_1.NODE_CATEGORY_LABEL_TAG_KEY) || attributes_1.NODE_CATEGORY_LABEL_DEFAULT;
    var columns = (0, react_1.useMemo)(function () {
        var customColumns = constants_1.CUSTOM_PRODUCT_FIELDS.map(function (label) { return ({ label: label }); });
        return attributes.concat(customColumns).reduce(function (acc, attributeColumn) {
            if (checkIsColumnSelected(attributeColumn)) {
                var label_1 = attributeColumn.label, isEditable = attributeColumn.isEditable, isMeasure = attributeColumn.isMeasure, isAIEnriched_1 = attributeColumn.isAIEnriched;
                var columnConfig = productsTableColumnsConfig.find(function (columnConfig) { return columnConfig.id === label_1; });
                var isAttributeEditable = (isEditable && label_1 !== categoryLabel) || (columnConfig === null || columnConfig === void 0 ? void 0 : columnConfig.isEditable);
                var column = columnConfig !== null && columnConfig !== void 0 ? columnConfig : {
                    id: label_1,
                    label: label_1,
                    renderTitle: function () { return react_1.default.createElement(AttributeTitle_1.AttributeTitle, { label: label_1, isAIEnriched: isAIEnriched_1 }); },
                    type: table_1.ColumnType.Tags,
                    path: label_1,
                    width: '10rem',
                    isEditable: isAttributeEditable && !isMeasure,
                    isMeasure: isMeasure,
                    isAIEnriched: isAIEnriched_1,
                    onCellClick: onCellClick,
                };
                return __spreadArray(__spreadArray([], __read(acc), false), [column], false);
            }
            return acc;
        }, []);
    }, [attributes, checkIsColumnSelected, productsTableColumnsConfig, onCellClick]);
    return {
        tableColumns: {
            columns: columns,
            isLoading: isLoading,
            error: error === null || error === void 0 ? void 0 : error.message,
            actions: {
                onChangeColumns: actions.onChangeColumns,
            },
            selection: {
                excludedColumns: excludedColumns,
                excludedLabelColumns: excludedLabelColumns,
                excludedMeasureColumns: excludedMeasureColumns,
                selectAllMode: selectAllMode,
            },
            order: {
                columnsOrder: columnsOrder,
            },
        },
    };
};
exports.useProductsTableConfig = useProductsTableConfig;
exports.default = exports.useProductsTableConfig;
