"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefineModal = exports.useRefineModalColumns = void 0;
var react_1 = require("react");
var hooks_1 = require("../../hooks");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var State = __importStar(require("../../store/state"));
var useRefineModalColumns = function (additionalColumns) {
    var t = (0, localization_1.useLocalization)().formatMessage;
    var baseColumns = (0, react_1.useMemo)(function () { return [
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_SKU_ID' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.SKU_ID,
            description: t({ key: 'DEFAULT' }),
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_IMAGE' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.IMAGE,
            description: t({ key: 'DEFAULT' }),
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_NAME' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.NAME,
            description: t({ key: 'DEFAULT' }),
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_ID' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_ID,
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_DESCRIPTION' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.DESCRIPTION,
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_GROUP' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_GROUP,
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_CREATED_DATE' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.CREATED_DATE,
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_LAST_MODIFIED' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.LAST_MODIFIED,
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_TAXONOMY_CLASS' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.CLASS,
        },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_URL' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.URL,
        },
        // descoped for MVP
        // {
        //   label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_PRICE' }),
        //   id: State.PRODUCT_EDITOR_TABLE_COLUMNS.PRICE,
        // },
        {
            label: t({ key: 'PRODUCT_EDITOR_TABLE_COLUMNS_ACTIVE_STATUS' }),
            id: State.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS,
        },
    ]; }, [t]);
    var columns = __spreadArray(__spreadArray([], __read(baseColumns), false), __read(additionalColumns), false);
    var defaultColumns = (0, react_1.useMemo)(function () { return columns
        .filter(function (_a) {
        var description = _a.description;
        return description === t({ key: 'DEFAULT' });
    })
        .map(function (_a) {
        var id = _a.id;
        return id;
    }); }, [columns]);
    return { columns: columns, defaultColumns: defaultColumns };
};
exports.useRefineModalColumns = useRefineModalColumns;
var useRefineModal = function (_a) {
    var lsKey = _a.lsKey, _b = _a.additionalColumns, additionalColumns = _b === void 0 ? [] : _b, _c = _a.additionalDefaultColumnNames, additionalDefaultColumnNames = _c === void 0 ? [] : _c;
    var ls = (0, hooks_1.useLocalStorage)();
    var _d = (0, exports.useRefineModalColumns)(additionalColumns), columns = _d.columns, defaultColumns = _d.defaultColumns;
    var organizations = (0, store_1.useSelectedOrganizationsIdsSelector)();
    var _e = __read((0, react_1.useState)(__spreadArray([
        State.PRODUCT_EDITOR_TABLE_COLUMNS.SKU_ID,
        State.PRODUCT_EDITOR_TABLE_COLUMNS.IMAGE,
        State.PRODUCT_EDITOR_TABLE_COLUMNS.NAME
    ], __read(additionalDefaultColumnNames), false)), 2), activeColumns = _e[0], setActiveColumns = _e[1];
    var getCacheableColumns = function (activeColumns) {
        var staticTableColumns = Object.values(State.PRODUCT_EDITOR_TABLE_COLUMNS);
        return activeColumns.filter(function (column) { return staticTableColumns.includes(column); });
    };
    (0, react_1.useEffect)(function () {
        // remove dynamic columns on organization change
        var organizationAgnosticColumns = getCacheableColumns(activeColumns);
        setActiveColumns(organizationAgnosticColumns);
    }, [organizations]);
    var handleSetActiveColumns = (0, react_1.useCallback)(function (activeCols) {
        setActiveColumns(activeCols);
        // omitting dynamic columns (e.g. attributes) from cache
        var cacheableColumns = getCacheableColumns(activeCols);
        ls.setItem(lsKey, { 'data': cacheableColumns });
    }, [lsKey]);
    (0, react_1.useEffect)(function () {
        var _a;
        var receivedActiveColumns = (_a = ls.getItem(lsKey)) === null || _a === void 0 ? void 0 : _a.data;
        if (Array.isArray(receivedActiveColumns)) {
            setActiveColumns(receivedActiveColumns);
        }
    }, [lsKey]);
    return {
        activeColumns: activeColumns,
        columns: columns,
        defaultColumns: defaultColumns,
        handleSetActiveColumns: handleSetActiveColumns,
    };
};
exports.useRefineModal = useRefineModal;
