"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClassifyModalBreadCrumbs = void 0;
var react_1 = __importStar(require("react"));
var icons_material_1 = require("@mui/icons-material");
var breadcrumbs_styles_1 = require("./breadcrumbs.styles");
var localization_1 = require("../../../../../localization");
var utils_1 = require("../../../../../utils");
var ClassifyModalBreadCrumbs = function (_a) {
    var node = _a.node, taxonomyNodes = _a.taxonomyNodes, onClearNode = _a.onClearNode, selectedNodeId = _a.selectedNodeId;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var currentNodePath = (0, react_1.useMemo)(function () {
        if (node) {
            return (0, utils_1.getNodePath)(taxonomyNodes, node);
        }
        return [];
    }, [node, taxonomyNodes]);
    var title = node === null || node === void 0 ? void 0 : node.title;
    var isSelectedNode = (node === null || node === void 0 ? void 0 : node.id) !== selectedNodeId;
    var showClearButton = title && selectedNodeId && !isSelectedNode;
    return (react_1.default.createElement(breadcrumbs_styles_1.StyledBreadcrumbsWrapper, null,
        react_1.default.createElement(breadcrumbs_styles_1.StyledWripperTitle, null,
            react_1.default.createElement(breadcrumbs_styles_1.StyledTitle, { variant: "caption" }, t({ key: 'CLASSIFY_MODAL_SELECTED_CLASS' })),
            showClearButton && react_1.default.createElement(breadcrumbs_styles_1.StyledButton, { onClick: onClearNode }, t({ key: 'CLEAR' }))),
        react_1.default.createElement(breadcrumbs_styles_1.StyledClassName, { variant: "body1" }, title || t({ key: 'CLASSIFY_MODAL_TITLE_NOT_SELECTED' })),
        react_1.default.createElement(breadcrumbs_styles_1.StyledBreadcrumbs, { separator: react_1.default.createElement(icons_material_1.NavigateNext, null), "aria-label": "breadcrumb" }, currentNodePath === null || currentNodePath === void 0 ? void 0 : currentNodePath.map(function (item) { return (react_1.default.createElement(breadcrumbs_styles_1.StyledBreadcrumbsItem, { variant: 'caption', key: item.id }, item.title)); }))));
};
exports.ClassifyModalBreadCrumbs = ClassifyModalBreadCrumbs;
exports.default = exports.ClassifyModalBreadCrumbs;
