"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetCatalogTagsFromCache = void 0;
var client_1 = require("@apollo/client");
var get_catalog_query_1 = require("../get-catalog.query");
var store_1 = require("../../../../store");
var useGetCatalogTagsFromCache = function () {
    var client = (0, client_1.useApolloClient)();
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var getTags = function () {
        var _a, _b, _c;
        if (!selectedCatalogId) {
            return [];
        }
        var data = client.readQuery({ query: get_catalog_query_1.GET_CATALOG_BY_ID, variables: { ids: [selectedCatalogId] } });
        if (!data) {
            return [];
        }
        return (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.catalogs) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b[0].node.tags.edges) === null || _c === void 0 ? void 0 : _c.map(function (_a) {
            var node = _a.node;
            return node;
        });
    };
    return { getTags: getTags };
};
exports.useGetCatalogTagsFromCache = useGetCatalogTagsFromCache;
