"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInput = void 0;
var react_1 = __importStar(require("react"));
var debounce_1 = require("debounce");
var filters_styles_1 = require("../../filters.styles");
var localization_1 = require("../../../../../../localization");
var TextInput = function (_a) {
    var onOptionChange = _a.onOptionChange, value = _a.value, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _c = __read((0, react_1.useState)(value), 2), inputValue = _c[0], setInputValue = _c[1];
    var handleChange = (0, react_1.useMemo)(function () { return (0, debounce_1.debounce)(onOptionChange, 300); }, [onOptionChange]);
    (0, react_1.useEffect)(function () {
        setInputValue(value);
    }, [value]);
    var handleInputChange = function (_a) {
        var value = _a.target.value;
        setInputValue(value);
        handleChange(value);
    };
    return (react_1.default.createElement(filters_styles_1.StyledInput, { variant: 'outlined', value: inputValue, placeholder: t({ key: 'VALUE' }), onChange: handleInputChange, disabled: disabled }));
};
exports.TextInput = TextInput;
exports.default = exports.TextInput;
