"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEnvironmentConfig = void 0;
var react_1 = require("react");
var paths_1 = require("../paths");
var context_1 = require("../context");
var useEnvironmentConfig = function () {
    var _a = (0, react_1.useContext)(context_1.EnrichContext).config, isNonPublicEnv = _a.isNonPublicEnv, isLowerEnv = _a.isLowerEnv;
    return {
        isNonPublicEnv: isNonPublicEnv,
        isLowerEnv: isLowerEnv,
        defaultEnrichApiPrefix: paths_1.ENRICH_PRODUCTION_API_PREFIX,
        enrichApiPrefixProduction: paths_1.ENRICH_PRODUCTION_API_PREFIX,
        defaultEnrichAIApiPrefix: paths_1.ENRICH_AI_API_PREFIX,
        enrichAIApiPrefixProduction: paths_1.ENRICH_AI_PRODUCTION_API_PREFIX,
    };
};
exports.useEnvironmentConfig = useEnvironmentConfig;
exports.default = exports.useEnvironmentConfig;
