"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductTreeNodes = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var get_all_tree_nodes_query_1 = require("./get-all-tree-nodes.query");
var get_tree_nodes_utils_1 = require("./get-tree-nodes.utils");
var client_constants_1 = require("../../client.constants");
var useProductTreeNodes = function (_a) {
    var productTreeId = _a.productTreeId, filter = _a.filter, options = _a.options, labelsFilter = _a.labelsFilter, _b = _a.skipLabels, skipLabels = _b === void 0 ? false : _b;
    var _c = (0, client_1.useQuery)(get_all_tree_nodes_query_1.GET_ALL_PRODUCT_TREE_NODES, {
        variables: {
            productTreeId: productTreeId,
            filter: filter,
            labelsFilter: labelsFilter,
            skipLabels: skipLabels,
            first: client_constants_1.PAGE_SIZE_LARGE,
            sort: [{ distance: graphql_1.SortOrder.Asc }],
        },
        notifyOnNetworkStatusChange: true,
        skip: options === null || options === void 0 ? void 0 : options.skip,
    }), data = _c.data, queryResponse = __rest(_c, ["data"]);
    var formattedData = (0, react_1.useMemo)(function () { var _a; return (0, get_tree_nodes_utils_1.formatTreeNodesData)(((_a = data === null || data === void 0 ? void 0 : data.productTreeNodes) !== null && _a !== void 0 ? _a : [])); }, [data === null || data === void 0 ? void 0 : data.productTreeNodes]);
    return __assign({ data: formattedData }, queryResponse);
};
exports.useProductTreeNodes = useProductTreeNodes;
exports.default = exports.useProductTreeNodes;
