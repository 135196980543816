"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClassesRefreshSelector = exports.classesRefreshSelector = exports.useClassesIsLoadingSelector = exports.classesIsLoadingSelector = exports.useClassesSelector = exports.classesSelector = exports.taxonomyClassesSelector = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
exports.taxonomyClassesSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var taxonomyClasses = _a.taxonomyClasses;
    return taxonomyClasses;
});
exports.classesSelector = (0, reselect_1.createSelector)(exports.taxonomyClassesSelector, function (_a) {
    var classes = _a.classes;
    return classes;
});
var useClassesSelector = function () { return (0, react_redux_1.useSelector)(exports.classesSelector); };
exports.useClassesSelector = useClassesSelector;
exports.classesIsLoadingSelector = (0, reselect_1.createSelector)(exports.taxonomyClassesSelector, function (_a) {
    var isLoading = _a.isLoading;
    return isLoading;
});
var useClassesIsLoadingSelector = function () { return (0, react_redux_1.useSelector)(exports.classesIsLoadingSelector); };
exports.useClassesIsLoadingSelector = useClassesIsLoadingSelector;
exports.classesRefreshSelector = (0, reselect_1.createSelector)(exports.taxonomyClassesSelector, function (_a) {
    var isRefresh = _a.isRefresh;
    return isRefresh;
});
var useClassesRefreshSelector = function () { return (0, react_redux_1.useSelector)(exports.classesRefreshSelector); };
exports.useClassesRefreshSelector = useClassesRefreshSelector;
