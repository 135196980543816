"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT = void 0;
var types_1 = require("../actions/types");
exports.DEFAULT = {
    searchTerm: '',
    openAttributes: {},
    openCategories: {},
    openTaxonomyClasses: false,
};
exports.default = (function (state, action) {
    if (state === void 0) { state = exports.DEFAULT; }
    switch (action.type) {
        case types_1.FACET_NAVIGATION_SET_SEARCH_TERM:
            return facetNavigationSetSearchTerm(state, action.payload);
        case types_1.FACET_NAVIGATION_SET_OPEN_CATEGORIES:
            return facetNavigationSetOpenCategories(state, action.payload);
        case types_1.FACET_NAVIGATION_SET_OPEN_ATTRIBUTES:
            return facetNavigationSetOpenAttributes(state, action.payload);
        case types_1.FACET_NAVIGATION_SET_OPEN_TAXONOMY_CLASSES:
            return facetNavigationSetOpenTaxonomyClasses(state, action.payload);
        default:
            return state;
    }
});
var facetNavigationSetSearchTerm = function (state, searchTerm) { return (__assign(__assign({}, state), { searchTerm: searchTerm })); };
var facetNavigationSetOpenCategories = function (state, openCategories) { return (__assign(__assign({}, state), { openCategories: openCategories })); };
var facetNavigationSetOpenAttributes = function (state, openAttributes) { return (__assign(__assign({}, state), { openAttributes: openAttributes })); };
var facetNavigationSetOpenTaxonomyClasses = function (state, openTaxonomyClasses) { return (__assign(__assign({}, state), { openTaxonomyClasses: openTaxonomyClasses })); };
