"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT = void 0;
var types_1 = require("../actions/types");
exports.DEFAULT = {
    selectedCatalog: undefined,
    selectedProjectId: undefined,
    selectedTreeNodePath: undefined,
    productTree: undefined,
    changes: undefined,
    expandedNodes: [],
};
exports.default = (function (state, action) {
    if (state === void 0) { state = exports.DEFAULT; }
    switch (action.type) {
        case types_1.ENRICH_AI_SET_SELECTED_CATALOG:
            return enrichAISetSelectedCatalogReducer(state, action.payload.selectedCatalog);
        case types_1.ENRICH_AI_SET_SELECTED_PROJECT:
            return enrichAISetSelectedProjectReducer(state, action.payload.selectedProjectId);
        case types_1.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH:
            return enrichAISetSelectedTreeNodePathReducer(state, action.payload.selectedTreeNodePath);
        case types_1.ENRICH_AI_SET_PRODUCT_TREE:
            return enrichAISetProductTreeIdReducer(state, action.payload.productTree);
        case types_1.ENRICH_AI_ADD_CHANGES:
            return enrichAIAddChangesReducer(state, action.payload.changes);
        case types_1.ENRICH_AI_REMOVE_CHANGES:
            return enrichAIRemoveChangesReducer(state, action.payload.strategyNodePath);
        case types_1.ENRICH_AI_SET_EXPANDED_NODES:
            return enrichAISetExpandedNodesReducer(state, action.payload.expandedNodes);
        default:
            return state;
    }
});
var enrichAISetSelectedCatalogReducer = function (state, selectedCatalog) { return (__assign(__assign({}, state), { selectedCatalog: selectedCatalog })); };
var enrichAISetSelectedProjectReducer = function (state, selectedProjectId) { return (__assign(__assign({}, state), { selectedProjectId: selectedProjectId })); };
var enrichAISetSelectedTreeNodePathReducer = function (state, selectedTreeNodePath) { return (__assign(__assign({}, state), { selectedTreeNodePath: selectedTreeNodePath })); };
var enrichAISetProductTreeIdReducer = function (state, productTree) { return (__assign(__assign({}, state), { productTree: productTree })); };
var enrichAIAddChangesReducer = function (state, newChanges) {
    var _a;
    var _b;
    var changes = state.changes, selectedCatalog = state.selectedCatalog;
    if (!selectedCatalog) {
        return state;
    }
    var catalogId = selectedCatalog.id;
    var catalogChanges = __spreadArray(__spreadArray([], __read(((_b = changes === null || changes === void 0 ? void 0 : changes[catalogId]) !== null && _b !== void 0 ? _b : [])), false), __read(newChanges), false);
    return __assign(__assign({}, state), { changes: __assign(__assign({}, changes), (_a = {}, _a[catalogId] = catalogChanges, _a)) });
};
var enrichAIRemoveChangesReducer = function (state, strategyNodePath) {
    var _a, _b;
    var _c;
    var changes = state.changes, selectedCatalog = state.selectedCatalog;
    if (!selectedCatalog) {
        return state;
    }
    var catalogId = selectedCatalog.id;
    if (strategyNodePath) {
        var catalogChanges = (_c = changes === null || changes === void 0 ? void 0 : changes[catalogId]) !== null && _c !== void 0 ? _c : [];
        return __assign(__assign({}, state), { changes: __assign(__assign({}, changes), (_a = {}, _a[catalogId] = catalogChanges.filter(function (_a) {
                var path = _a.path;
                return !path.startsWith(strategyNodePath);
            }), _a)) });
    }
    return __assign(__assign({}, state), { changes: __assign(__assign({}, changes), (_b = {}, _b[catalogId] = undefined, _b)) });
};
var enrichAISetExpandedNodesReducer = function (state, expandedNodes) { return (__assign(__assign({}, state), { expandedNodes: expandedNodes })); };
