"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectedOrganizationsSelector = exports.useSelectedOrganizationsIdsSelector = exports.useOrganizationsSelector = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
var organizationsSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var organizations = _a.organizations;
    return organizations.data;
});
var useOrganizationsSelector = function () { return (0, react_redux_1.useSelector)(organizationsSelector); };
exports.useOrganizationsSelector = useOrganizationsSelector;
var selectedOrganizationsIdsSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var organizations = _a.organizations;
    return organizations.selectedOrganizationsIds;
});
var useSelectedOrganizationsIdsSelector = function () { return (0, react_redux_1.useSelector)(selectedOrganizationsIdsSelector); };
exports.useSelectedOrganizationsIdsSelector = useSelectedOrganizationsIdsSelector;
var selectedOrganizationsSelector = (0, reselect_1.createSelector)(organizationsSelector, selectedOrganizationsIdsSelector, function (organizationsData, selectedOrganizationsIds) { return organizationsData.filter(function (organization) { return selectedOrganizationsIds.includes(organization.id); }); });
var useSelectedOrganizationsSelector = function () { return (0, react_redux_1.useSelector)(selectedOrganizationsSelector); };
exports.useSelectedOrganizationsSelector = useSelectedOrganizationsSelector;
