"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useViewerTableAdvancedFilter = exports.fieldSpecifierMap = void 0;
var react_1 = require("react");
var predicate_language_1 = require("@groupby/predicate-language");
var debounce_1 = require("debounce");
var use_filter_utils_1 = require("./use-filter.utils");
var filter_utils_1 = require("../product-viewer/filter/filter.utils");
var filter_1 = require("../product-viewer/filter");
var store_1 = require("../../store");
var State = __importStar(require("../../store/state"));
var OPERATOR_TO_PREDICATE = (_a = {},
    _a[filter_1.FilterOperator.Is] = '==',
    _a[filter_1.FilterOperator.IsNot] = '!=',
    _a[filter_1.FilterOperator.Before] = '<',
    _a[filter_1.FilterOperator.After] = '>',
    _a[filter_1.FilterOperator.Contains] = '=~',
    _a[filter_1.FilterOperator.DoesNotContain] = '!~',
    _a[filter_1.FilterOperator.StartsWith] = '=~',
    _a[filter_1.FilterOperator.EndsWith] = '=~',
    _a);
exports.fieldSpecifierMap = (_b = {},
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_ID] = 'id',
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.SKU_ID] = 'proprietaryId',
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.NAME] = 'title',
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.DESCRIPTION] = 'description',
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_GROUP] = 'variantGroupId',
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.URL] = 'url',
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.CLASS] = 'classId',
    _b[State.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS] = 'active',
    _b);
var useViewerTableAdvancedFilter = function () {
    var _a = __read((0, react_1.useState)(new predicate_language_1.PredicateBuilder()), 2), filter = _a[0], setFilter = _a[1];
    var advancedFilterData = (0, store_1.useAdvancedViewerFilterData)();
    var formatValue = function (field, value, operator) {
        if ((0, filter_utils_1.isUuidField)(field))
            return (0, predicate_language_1.uuid)(value);
        if (operator === filter_1.FilterOperator.Contains || operator === filter_1.FilterOperator.DoesNotContain)
            return new RegExp(value, 'i');
        if (operator === filter_1.FilterOperator.StartsWith)
            return new RegExp("^".concat(value, ".*"), 'i');
        if (operator === filter_1.FilterOperator.EndsWith)
            return new RegExp(".*".concat(value, "$"), 'i');
        if ((0, filter_utils_1.isDateFilter)(field))
            return value[0];
        return value;
    };
    var createBasicPredicate = function (_a) {
        var field = _a.field, operator = _a.operator, value = _a.value;
        var formattedValue = formatValue(field, value, operator);
        return (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["", ""], ["", ""])), field), OPERATOR_TO_PREDICATE[operator], formattedValue);
    };
    var createDateRangePredicate = function (_a) {
        var field = _a.field, value = _a.value;
        var _b = __read(value, 2), startDate = _b[0], endDate = _b[1];
        return ((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["", ""], ["", ""])), field), '>', startDate).and((0, predicate_language_1.f)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["", ""], ["", ""])), field), '<', endDate));
    };
    var createAttributePredicate = function (_a) {
        var attribute = _a.attribute, value = _a.value, operator = _a.operator;
        var _b = JSON.parse(value), id = _b.id, name = _b.name;
        var predicate = (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["attributes"], ["attributes"]))), '@>', (id || name) ? (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["id"], ["id"]))), '==', (0, predicate_language_1.uuid)(attribute))
            .and((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["values"], ["values"]))), '@>', (0, predicate_language_1.where)(id ? (0, predicate_language_1.f)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["id"], ["id"]))) : (0, predicate_language_1.f)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["name"], ["name"]))), '==', id ? (0, predicate_language_1.uuid)(id) : name))) : (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["id"], ["id"]))), '==', (0, predicate_language_1.uuid)(attribute)));
        if (operator === filter_1.FilterOperator.IsNot) {
            return (0, predicate_language_1.not)(predicate);
        }
        return predicate;
    };
    var createValuePredicate = function (_a) {
        var value = _a.value, operator = _a.operator;
        var idsArray = JSON.parse(value);
        var predicate;
        if (idsArray.length === 1) {
            predicate = (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["attributes"], ["attributes"]))), '@>', (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["values"], ["values"]))), '@>', ((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["id"], ["id"]))), '==', (0, predicate_language_1.uuid)(idsArray[0])))));
        }
        else {
            predicate = idsArray.reduce(function (filter, id) {
                filter.or((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["attributes"], ["attributes"]))), '@>', (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["values"], ["values"]))), '@>', ((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["id"], ["id"]))), '==', (0, predicate_language_1.uuid)(id))))));
                return filter;
            }, new predicate_language_1.PredicateBuilder());
        }
        if (operator === filter_1.FilterOperator.IsNot) {
            return (0, predicate_language_1.not)(predicate);
        }
        return predicate;
    };
    var generatePredicate = function (filters) {
        var predicate = filters.reduce(function (filter, _a) {
            var field = _a.field, operator = _a.operator, value = _a.value, _b = _a.fieldSpecifier, fieldSpecifier = _b === void 0 ? null : _b, connector = _a.connector;
            var logicalOperator = (0, use_filter_utils_1.getLogicalOperator)(connector);
            if (field === filter_1.FilterField.TaxonomyClass || field === filter_1.FilterField.Category) {
                filter[logicalOperator](createBasicPredicate({ field: field, operator: operator, value: value }));
            }
            if ((0, filter_utils_1.isDateFilter)(field)) {
                if (operator === filter_1.FilterOperator.Between) {
                    filter[logicalOperator](createDateRangePredicate({ field: field, value: value }));
                }
                else {
                    filter[logicalOperator](createBasicPredicate({ field: field, operator: operator, value: value }));
                }
            }
            if (field === filter_1.FilterField.Field) {
                filter[logicalOperator](createBasicPredicate({ field: exports.fieldSpecifierMap[fieldSpecifier], operator: operator, value: value }));
            }
            if (field === filter_1.FilterField.Attribute) {
                filter[logicalOperator](createAttributePredicate({ attribute: fieldSpecifier, value: value, operator: operator }));
            }
            if (field === filter_1.FilterField.Value) {
                filter[logicalOperator](createValuePredicate({ value: value, operator: operator }));
            }
            return filter;
        }, new predicate_language_1.PredicateBuilder());
        setFilter(predicate);
    };
    var debouncedGeneratePredicate = (0, debounce_1.debounce)(generatePredicate, 300);
    (0, react_1.useEffect)(function () {
        if (advancedFilterData.every(function (_a) {
            var field = _a.field, value = _a.value, operator = _a.operator;
            return !!field && !!value && !!operator;
        })) {
            debouncedGeneratePredicate(advancedFilterData);
        }
    }, [advancedFilterData]);
    return { advancedFilter: filter };
};
exports.useViewerTableAdvancedFilter = useViewerTableAdvancedFilter;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
