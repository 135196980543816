"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileImage = void 0;
var react_1 = __importStar(require("react"));
var product_tile_image_styles_1 = require("./product-tile-image.styles");
var no_image_icon_1 = require("../../../../icons/svg/no-image-icon");
var localization_1 = require("../../../../localization");
var ProductTileImage = function (_a) {
    var image = _a.image, _b = _a.includeText, includeText = _b === void 0 ? true : _b;
    var _c = __read((0, react_1.useState)(!image), 2), imageError = _c[0], setImageError = _c[1];
    var t = (0, localization_1.useLocalization)().formatMessage;
    return (react_1.default.createElement(product_tile_image_styles_1.StyledImageWrapper, null,
        !imageError
            && react_1.default.createElement("img", { alt: "product tile", loading: "lazy", src: image, onError: function () { return setImageError(true); } }),
        imageError
            && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(no_image_icon_1.NoImageIcon, null),
                includeText && react_1.default.createElement("span", null, t({ key: 'PRODUCT_TILE_NO_IMAGE_AVAILABLE' }))))));
};
exports.ProductTileImage = ProductTileImage;
