"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectedProducts = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var store_1 = require("../../store");
var useSelectedProducts = function (_a) {
    var lastSelectedRowId = _a.lastSelectedRowId, setLastSelectedRowId = _a.setLastSelectedRowId, _b = _a.products, products = _b === void 0 ? [] : _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var isExclusionModeOn = (0, store_1.useIsExclusionModeOn)();
    var selectedProductIds = (0, store_1.useSelectedProductIds)();
    var total = (0, store_1.useProductsTotal)();
    var selectedProductsForPage = (0, react_1.useMemo)(function () {
        var _a = __read((0, ramda_1.partition)(function (_a) {
            var id = _a.id;
            return selectedProductIds.includes(id);
        }, products), 2), selectedProducts = _a[0], excludedProducts = _a[1];
        if (isExclusionModeOn)
            return excludedProducts;
        return selectedProducts;
    }, [isExclusionModeOn, products, selectedProductIds]);
    var selectedProductsCount = (0, react_1.useMemo)(function () { return (isExclusionModeOn ? total - selectedProductIds.length : selectedProductIds.length); }, [isExclusionModeOn, selectedProductIds.length, total]);
    var shouldProductsBeSelected = (0, react_1.useMemo)(function () { return (selectedProductsForPage.length > 0 && isExclusionModeOn) || (selectedProductsForPage.length === 0 && !isExclusionModeOn); }, [isExclusionModeOn, selectedProductsForPage.length]);
    var handleAllProductsSelect = (0, react_1.useCallback)(function () {
        setLastSelectedRowId(null);
        var productIds = products.map(function (_a) {
            var id = _a.id;
            return id;
        });
        if (shouldProductsBeSelected) {
            dispatch((0, store_1.setSelectedProducts)(productIds));
        }
        else {
            dispatch((0, store_1.clearSelectedProducts)(productIds));
        }
    }, [products, shouldProductsBeSelected]);
    var handleProductSelect = (0, react_1.useCallback)(function (event, _a) {
        var id = _a.id;
        if (event.nativeEvent.shiftKey) {
            var lastSelectedIndex = products.findIndex(function (it) { return it.id === lastSelectedRowId; });
            var selectedIndex = products.findIndex(function (it) { return it.id === id; });
            if (lastSelectedIndex !== -1) {
                var start_1 = lastSelectedIndex > selectedIndex ? selectedIndex : lastSelectedIndex;
                var end_1 = lastSelectedIndex > selectedIndex ? lastSelectedIndex : selectedIndex;
                var range = products.filter(function (_, ind) { return ind >= start_1 && ind <= end_1; }).map(function (it) { return it.id; });
                dispatch((0, store_1.addSelectedProductIds)(range));
            }
            else {
                setLastSelectedRowId(id);
                dispatch((0, store_1.addSelectedProductId)(id));
            }
        }
        else {
            setLastSelectedRowId(id);
            dispatch((0, store_1.addSelectedProductId)(id));
        }
    }, [lastSelectedRowId, products]);
    return {
        handleAllProductsSelect: handleAllProductsSelect,
        handleProductSelect: handleProductSelect,
        selectedProductsForPage: selectedProductsForPage,
        selectedProductsCount: selectedProductsCount,
    };
};
exports.useSelectedProducts = useSelectedProducts;
