"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAllCategoriesSelector = exports.allCategoriesSelector = exports.useCategoryGroupsSelector = exports.categoryGroupsSelector = exports.useTopLevelCategoriesSelector = exports.topLevelCategoriesSelector = exports.useTopLevelCategoryIdsSelector = exports.topLevelCategoryIdsSelector = exports.useCategoriesMapSelector = exports.useCategoriesMap = exports.categoriesMapSelector = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
exports.categoriesMapSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var categories = _a.categories;
    return categories.categoriesMap;
});
exports.useCategoriesMap = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var categories = _a.categories;
    return categories.categoriesMap;
});
var useCategoriesMapSelector = function () { return (0, react_redux_1.useSelector)(exports.useCategoriesMap); };
exports.useCategoriesMapSelector = useCategoriesMapSelector;
exports.topLevelCategoryIdsSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var categories = _a.categories;
    return categories.topLevelCategoryIds;
});
var useTopLevelCategoryIdsSelector = function () { return (0, react_redux_1.useSelector)(exports.topLevelCategoryIdsSelector); };
exports.useTopLevelCategoryIdsSelector = useTopLevelCategoryIdsSelector;
exports.topLevelCategoriesSelector = (0, reselect_1.createSelector)(exports.topLevelCategoryIdsSelector, exports.categoriesMapSelector, function (topLevelCategoryIds, categoriesMap) { return topLevelCategoryIds.map(function (topLevelCategoryId) { return categoriesMap[topLevelCategoryId]; }); });
var useTopLevelCategoriesSelector = function () { return (0, react_redux_1.useSelector)(exports.topLevelCategoriesSelector); };
exports.useTopLevelCategoriesSelector = useTopLevelCategoriesSelector;
exports.categoryGroupsSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var categories = _a.categories;
    return categories.categoryGroups;
});
var useCategoryGroupsSelector = function () { return (0, react_redux_1.useSelector)(exports.categoryGroupsSelector); };
exports.useCategoryGroupsSelector = useCategoryGroupsSelector;
exports.allCategoriesSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var categories = _a.categories;
    return categories.allCategories;
});
var useAllCategoriesSelector = function () { return (0, react_redux_1.useSelector)(exports.allCategoriesSelector); };
exports.useAllCategoriesSelector = useAllCategoriesSelector;
