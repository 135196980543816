"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNSPECIFIED_UNIT = exports.notVisibleLabels = void 0;
var attributes_1 = require("../../../client/attributes");
exports.notVisibleLabels = [
    attributes_1.PRODUCT_PAGE_URL_LABEL,
    attributes_1.PRODUCT_IMAGE_LABEL,
    attributes_1.PRODUCT_SKU_LABEL,
];
exports.UNSPECIFIED_UNIT = 'UNSPECIFIED';
