"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageSize = void 0;
var react_1 = require("react");
var client_constants_1 = require("../../client.constants");
function usePageSize(_a) {
    var _b = _a.defaultPageSize, defaultPageSize = _b === void 0 ? client_constants_1.PAGE_SIZE_DEFAULT : _b;
    var _c = __read((0, react_1.useState)(defaultPageSize), 2), pageSize = _c[0], setPageSize = _c[1];
    return {
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
    };
}
exports.usePageSize = usePageSize;
