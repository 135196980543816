"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COPY_PRODUCT_TREE_TITLE = exports.itemTypeToMessageKey = exports.ItemType = void 0;
var ItemType;
(function (ItemType) {
    ItemType["Attribute"] = "ATTRIBUTE";
    ItemType["Value"] = "VALUE";
})(ItemType = exports.ItemType || (exports.ItemType = {}));
exports.itemTypeToMessageKey = [
    { label: 'ATTRIBUTE', value: ItemType.Attribute },
    { label: 'VALUE', value: ItemType.Value },
];
exports.COPY_PRODUCT_TREE_TITLE = 'Global Ontology Copy';
