"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.areDuplicatesExist = exports.updateUniqueItemListBasedOnValueAndProp = exports.updateUniqueItemListBasedOnValue = void 0;
var updateUniqueItemListBasedOnValue = function (item, value) { return function (list) {
    var newList = new Set(list);
    if (value) {
        newList.add(item);
    }
    else {
        newList.delete(item);
    }
    return Array.from(newList);
}; };
exports.updateUniqueItemListBasedOnValue = updateUniqueItemListBasedOnValue;
var updateUniqueItemListBasedOnValueAndProp = function (item, value, propName) { return function (list) {
    var key = item[propName];
    var selectedItem = list.findIndex(function (listItem) { return listItem[propName] === key; });
    if (selectedItem >= 0 && !value) {
        return list.filter(function (listItem) { return listItem[propName] !== key; });
    }
    if (selectedItem < 0 && value) {
        return __spreadArray(__spreadArray([], __read(list), false), [item], false);
    }
    return list;
}; };
exports.updateUniqueItemListBasedOnValueAndProp = updateUniqueItemListBasedOnValueAndProp;
var areDuplicatesExist = function (_a) {
    var list = _a.list, propName = _a.propName, _b = _a.useLowerCase, useLowerCase = _b === void 0 ? true : _b;
    var uniqueValues = new Set(list.map(function (item) { return (useLowerCase ? String(item[propName]).toLowerCase() : String(item[propName])); }));
    return uniqueValues.size < list.length;
};
exports.areDuplicatesExist = areDuplicatesExist;
