"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.STANDARD_PRODUCT_FIELDS = exports.CUSTOM_PRODUCT_FIELDS = exports.ORIGINAL_PRODUCT_FIELDS = exports.CustomProductFields = exports.OriginalProductFields = void 0;
var OriginalProductFields;
(function (OriginalProductFields) {
    OriginalProductFields["Name"] = "name";
    OriginalProductFields["Description"] = "description";
    OriginalProductFields["Category"] = "category_names";
})(OriginalProductFields = exports.OriginalProductFields || (exports.OriginalProductFields = {}));
var CustomProductFields;
(function (CustomProductFields) {
    CustomProductFields["LastAttributeUpdatedAt"] = "lastAttributeUpdatedAt";
})(CustomProductFields = exports.CustomProductFields || (exports.CustomProductFields = {}));
exports.ORIGINAL_PRODUCT_FIELDS = Object.values(OriginalProductFields);
exports.CUSTOM_PRODUCT_FIELDS = Object.values(CustomProductFields);
exports.STANDARD_PRODUCT_FIELDS = __spreadArray(__spreadArray([], __read(exports.ORIGINAL_PRODUCT_FIELDS), false), __read(exports.CUSTOM_PRODUCT_FIELDS), false);
