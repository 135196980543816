"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnFilter = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var ramda_1 = require("ramda");
var column_filter_styles_1 = require("./column-filter.styles");
var generic_1 = require("../../../../generic");
var localization_1 = require("../../../../localization");
var ColumnFilter = function (_a) {
    var anchorEl = _a.anchorEl, isLoading = _a.isLoading, filterOptions = _a.filterOptions, selected = _a.selected, onClose = _a.onClose, onApply = _a.onApply;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(''), 2), searchText = _b[0], setSearchText = _b[1];
    var sortedFilterOptions = (0, react_1.useMemo)(function () { return filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.sort(function (a, b) { return (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1); }); }, [filterOptions]);
    var selectedValue = (0, react_1.useMemo)(function () { return selected !== null && selected !== void 0 ? selected : sortedFilterOptions === null || sortedFilterOptions === void 0 ? void 0 : sortedFilterOptions.map(function (_a) {
        var id = _a.id;
        return id;
    }); }, [sortedFilterOptions, selected]);
    var _c = __read((0, react_1.useState)(selectedValue), 2), selectedIds = _c[0], setSelectedIds = _c[1];
    (0, react_1.useEffect)(function () {
        if (anchorEl) {
            setSelectedIds(selectedValue);
        }
    }, [anchorEl, selectedValue]);
    var isSelected = (0, react_1.useCallback)(function (id) { return selectedIds.includes(id); }, [selectedIds]);
    var handleSelect = (0, react_1.useCallback)(function (id) {
        var updatedSelectedIds = new Set(selectedIds);
        if (updatedSelectedIds.has(id)) {
            updatedSelectedIds.delete(id);
        }
        else {
            updatedSelectedIds.add(id);
        }
        setSelectedIds(Array.from(updatedSelectedIds));
    }, [selectedIds]);
    var handleApply = function () {
        onApply((filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.length) === selectedIds.length ? undefined : selectedIds);
        onClose();
    };
    var filteredOptions = (0, react_1.useMemo)(function () { return sortedFilterOptions === null || sortedFilterOptions === void 0 ? void 0 : sortedFilterOptions.filter(function (_a) {
        var label = _a.label;
        return label.toLowerCase().includes(searchText.toLowerCase());
    }); }, [searchText, sortedFilterOptions]);
    var options = (0, react_1.useMemo)(function () {
        if (isLoading) {
            return [];
        }
        return filteredOptions.map(function (_a) {
            var id = _a.id, label = _a.label;
            return (react_1.default.createElement(material_1.FormControlLabel, { key: id, control: react_1.default.createElement(ui_components_1.Checkbox, { checked: isSelected(id), onChange: function () { return handleSelect(id); } }), label: label }));
        });
    }, [filteredOptions, handleSelect, isLoading, isSelected]);
    var isAllSelected = (0, react_1.useMemo)(function () { return new Set(__spreadArray(__spreadArray([], __read(selectedIds), false), __read(filteredOptions.map(function (_a) {
        var id = _a.id;
        return id;
    })), false)).size === selectedIds.length; }, [filteredOptions, selectedIds]);
    var handleSelectAll = function () {
        var uniqueIds = new Set(selectedIds);
        var actionName = isAllSelected ? 'delete' : 'add';
        filteredOptions.forEach(function (_a) {
            var id = _a.id;
            uniqueIds[actionName](id);
        });
        setSelectedIds(Array.from(uniqueIds));
    };
    var areActionsDisabled = isLoading || (0, ramda_1.isEmpty)(filteredOptions !== null && filteredOptions !== void 0 ? filteredOptions : []);
    return (react_1.default.createElement(material_1.Popover, { open: !!anchorEl, onClose: onClose, anchorEl: anchorEl, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        } },
        react_1.default.createElement(column_filter_styles_1.StyledContainer, null,
            react_1.default.createElement(column_filter_styles_1.StyledSearchInput, { onChange: function (e) { return setSearchText(e.target.value); }, value: searchText, placeholder: t({ key: 'SEARCH' }), startAdornment: react_1.default.createElement(material_1.InputAdornment, { position: "start" },
                    react_1.default.createElement(ui_components_1.Icon, { variant: "search", color: ui_components_1.baseColors.primaryGreen400, className: "search-icon" })) }),
            react_1.default.createElement("div", { className: 'content' },
                isLoading && react_1.default.createElement(generic_1.SimpleLoading, null),
                options),
            react_1.default.createElement(material_1.Divider, null),
            react_1.default.createElement("div", { className: "footer" },
                react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", title: isAllSelected ? t({ key: 'CLEAR_ALL' }) : t({ key: 'SELECT_ALL' }), disabled: areActionsDisabled, onClick: handleSelectAll }),
                react_1.default.createElement(ui_components_1.Button, { rounded: true, color: "primary", title: t({ key: 'APPLY' }), variant: "contained", disabled: areActionsDisabled, onClick: handleApply })))));
};
exports.ColumnFilter = ColumnFilter;
exports.default = exports.ColumnFilter;
