"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsUserRoleLoadingSelector = exports.isUserRoleLoading = exports.useUserRoleSelector = exports.userRoleSelector = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
exports.userRoleSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var user = _a.user;
    return user.role;
});
var useUserRoleSelector = function () { return (0, react_redux_1.useSelector)(exports.userRoleSelector); };
exports.useUserRoleSelector = useUserRoleSelector;
exports.isUserRoleLoading = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var user = _a.user;
    return user.isRoleLoading;
});
var useIsUserRoleLoadingSelector = function () { return (0, react_redux_1.useSelector)(exports.isUserRoleLoading); };
exports.useIsUserRoleLoadingSelector = useIsUserRoleLoadingSelector;
