"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENRICH_AI_SET_EXPANDED_NODES = exports.ENRICH_AI_REMOVE_CHANGES = exports.ENRICH_AI_ADD_CHANGES = exports.ENRICH_AI_SET_PRODUCT_TREE = exports.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH = exports.ENRICH_AI_SET_SELECTED_PROJECT = exports.ENRICH_AI_SET_SELECTED_CATALOG = void 0;
var const_1 = require("../../const");
exports.ENRICH_AI_SET_SELECTED_CATALOG = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SELECTED_CATALOG");
exports.ENRICH_AI_SET_SELECTED_PROJECT = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SELECTED_PROJECT");
exports.ENRICH_AI_SET_SELECTED_TREE_NODE_PATH = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_SELECTED_TREE_NODE_PATH");
exports.ENRICH_AI_SET_PRODUCT_TREE = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_PRODUCT_TREE");
exports.ENRICH_AI_ADD_CHANGES = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_ADD_CHANGES");
exports.ENRICH_AI_REMOVE_CHANGES = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_REMOVE_CHANGES");
exports.ENRICH_AI_SET_EXPANDED_NODES = "".concat(const_1.PREFIX_ENRICH, "ENRICH_AI_SET_EXPANDED_NODES");
