"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResetAllFilters = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var store_1 = require("../../store");
var State = __importStar(require("../../store/state"));
var useResetAllFilters = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var resetAllFilters = (0, react_1.useCallback)(function () {
        dispatch((0, store_1.refinementsSetFilter)(State.AvailableRefinementType.All));
        dispatch((0, store_1.productsSetIncludedCategoriesIds)([]));
        dispatch((0, store_1.productsSetExcludedCategoriesIds)([]));
        dispatch((0, store_1.productsClearIncludedTaxonomyClassIds)());
        dispatch((0, store_1.productsClearExcludedTaxonomyClassIds)());
        dispatch((0, store_1.productsSetIncludedAttributesIds)([]));
        dispatch((0, store_1.productsSetExcludedAttributesIds)([]));
        dispatch((0, store_1.productsSetFilterActive)(State.ProductFilterActiveTypes.All));
        dispatch((0, store_1.productsSetFilterOperator)(State.ProductFilterOperatorType.Or));
        dispatch((0, store_1.facetNavigationSetOpenAttributes)({}));
        dispatch((0, store_1.facetNavigationSetOpenCategories)({}));
        dispatch((0, store_1.facetNavigationSetOpenTaxonomyClasses)(false));
        dispatch((0, store_1.facetNavigationSetSearchTerm)(''));
    }, [dispatch]);
    return { resetAllFilters: resetAllFilters };
};
exports.useResetAllFilters = useResetAllFilters;
exports.default = exports.useResetAllFilters;
