"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEnrichClient = void 0;
var react_1 = require("react");
var enrich_client_1 = require("@groupby/enrich-client");
var hooks_1 = require("./");
var utils_1 = require("../utils");
var useEnrichClient = function () {
    var token = (0, hooks_1.useToken)();
    var enrichHost = (0, utils_1.useGetEnrichAPIUrl)();
    var client = (0, react_1.useMemo)(function () { return new enrich_client_1.HttpClient({ host: enrichHost, token: token }); }, [enrichHost, token]);
    return client;
};
exports.useEnrichClient = useEnrichClient;
exports.default = exports.useEnrichClient;
