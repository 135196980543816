"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsEntityChanged = void 0;
var react_1 = require("react");
var use_history_changes_hook_1 = require("./use-history-changes.hook");
var store_1 = require("../../store");
var useIsEntityChanged = function () {
    var selectedStrategyNodeChanges = (0, use_history_changes_hook_1.useHistoryChanges)().selectedStrategyNodeChanges;
    var uniqueEntityPaths = (0, react_1.useMemo)(function () {
        var uniquePaths = new Set();
        selectedStrategyNodeChanges.forEach(function (_a) {
            var id = _a.id;
            var _b = __read(id.split(store_1.LABEL_VALUE_PAIR_SEPARATOR), 2), label = _b[0], value = _b[1];
            uniquePaths.add(label);
            if (value) {
                uniquePaths.add(id);
            }
        });
        return uniquePaths;
    }, [selectedStrategyNodeChanges]);
    var checkIsAttributeChanged = (0, react_1.useCallback)(function (label) { return uniqueEntityPaths.has(label); }, [uniqueEntityPaths]);
    var checkIsValueChanged = (0, react_1.useCallback)(function (label, value) { return uniqueEntityPaths.has("".concat(label).concat(store_1.LABEL_VALUE_PAIR_SEPARATOR).concat(value)); }, [uniqueEntityPaths]);
    return {
        checkIsAttributeChanged: checkIsAttributeChanged,
        checkIsValueChanged: checkIsValueChanged,
    };
};
exports.useIsEntityChanged = useIsEntityChanged;
