"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createThunkAction = exports.createErrorAction = exports.createAction = void 0;
function createAction(type, payload, meta) {
    return { type: type, payload: payload, meta: meta };
}
exports.createAction = createAction;
function createErrorAction(type, payload, meta) {
    return __assign(__assign({}, createAction(type, payload, meta)), { error: true });
}
exports.createErrorAction = createErrorAction;
function createThunkAction(type, payload, meta) {
    return createAction(type, payload, __assign({ thunk: true }, meta));
}
exports.createThunkAction = createThunkAction;
