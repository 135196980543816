"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortingListMenuItems = void 0;
var models_1 = require("../../../models");
exports.sortingListMenuItems = [
    { label: 'SORT_BY_TEXT_ASC', value: models_1.SortingItem.SortByTextAsc },
    { label: 'SORT_BY_TEXT_DESC', value: models_1.SortingItem.SortByTextDesc },
    { label: 'SORT_BY_PRODUCT_COUNTS_ASC', value: models_1.SortingItem.SortByProductCountsAsc },
    { label: 'SORT_BY_PRODUCT_COUNTS_DESC', value: models_1.SortingItem.SortByProductCountsDesc },
];
