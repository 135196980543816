"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnType = void 0;
var ColumnType;
(function (ColumnType) {
    ColumnType["Text"] = "text";
    ColumnType["Tags"] = "tags";
    ColumnType["Date"] = "date";
    ColumnType["Image"] = "image";
    ColumnType["Link"] = "link";
})(ColumnType = exports.ColumnType || (exports.ColumnType = {}));
