"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PARSE_ERROR_TYPE_TO_MESSAGE_KEY = exports.UPDATE_DATA_MODE_TO_MESSAGE_KEY = void 0;
var hooks_1 = require("../hooks");
var upload_file_options_1 = require("../models/upload-file-options");
exports.UPDATE_DATA_MODE_TO_MESSAGE_KEY = (_a = {},
    _a[upload_file_options_1.UpdateDataModes.Replace] = 'REPLACE',
    _a[upload_file_options_1.UpdateDataModes.Merge] = 'MERGE',
    _a);
exports.PARSE_ERROR_TYPE_TO_MESSAGE_KEY = (_b = {},
    _b[hooks_1.ErrorTypes.Delimiter] = 'CSV_PARSE_ERROR_DELIMITER',
    _b[hooks_1.ErrorTypes.Quotes] = 'CSV_PARSE_ERROR_QUOTES',
    _b[hooks_1.ErrorTypes.FieldMismatch] = 'CSV_PARSE_ERROR_FIELD_MISMATCH',
    _b[hooks_1.ErrorTypes.DuplicatedKeyField] = 'CSV_PARSE_ERROR_DUPLICATED_KEY',
    _b[hooks_1.ErrorTypes.EmptyKeyField] = 'CSV_PARSE_ERROR_EMPTY_KEY',
    _b[hooks_1.ErrorTypes.EmptyField] = 'CSV_PARSE_ERROR_EMPTY',
    _b[hooks_1.ErrorTypes.ParseArray] = 'CSV_PARSE_ERROR_PARSE_ARRAY',
    _b[hooks_1.ErrorTypes.Parse] = 'CSV_PARSE_ERROR_PARSE',
    _b);
