"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateDataModes = exports.UpdateCatalogTypes = void 0;
var UpdateCatalogTypes;
(function (UpdateCatalogTypes) {
    UpdateCatalogTypes["Catalog"] = "catalog";
    UpdateCatalogTypes["Data"] = "data";
})(UpdateCatalogTypes = exports.UpdateCatalogTypes || (exports.UpdateCatalogTypes = {}));
var UpdateDataModes;
(function (UpdateDataModes) {
    UpdateDataModes["Replace"] = "replace";
    UpdateDataModes["Merge"] = "merge";
})(UpdateDataModes = exports.UpdateDataModes || (exports.UpdateDataModes = {}));
