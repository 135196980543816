"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeValue = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var attributes_styles_1 = require("./attributes.styles");
var AttributeValue = function (_a) {
    // commented out for EN-3066
    // const valueListIcon = values.some(({ active, name }) => !active && name) && <WarningIcon />;
    var values = _a.values;
    var value = (0, react_1.useMemo)(function () {
        var isEmptyValue = !values.some(function (_a) {
            var name = _a.name;
            return name;
        });
        if (isEmptyValue) {
            return react_1.default.createElement("span", { className: "attribute-value-box" }, "\u2014");
        }
        var valueContent = values.map(function (_a, index) {
            var name = _a.name;
            return (
            // commented out for EN-3066
            // const valueContent = values.map(({ name, active }, index) => (
            react_1.default.createElement(react_1.default.Fragment, { key: name },
                react_1.default.createElement("span", { className: 'attribute-text' }, name),
                index !== values.length - 1 && react_1.default.createElement("span", null, ",\u00A0")));
        });
        return (react_1.default.createElement(ui_components_1.ToolTip, { arrow: true, title: (react_1.default.createElement(attributes_styles_1.StyledTooltipValue, null, valueContent)) },
            react_1.default.createElement("div", { className: "attribute-value-box" }, valueContent)));
    }, [values]);
    return (react_1.default.createElement(attributes_styles_1.StyledAttributeContainerCell, null, value));
};
exports.AttributeValue = AttributeValue;
exports.default = exports.AttributeValue;
