"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductList = void 0;
var react_1 = __importStar(require("react"));
var classify_modal_styles_1 = require("./classify-modal.styles");
var use_fetch_products_hook_1 = require("./hooks/use-fetch-products.hook");
var localization_1 = require("../../../../localization");
var product_card_1 = require("../../../components/product-card");
var selectors_1 = require("../../../../store/selectors");
var LIMIT_PRODUCTS = 100;
var ProductList = function (_a) {
    var expanded = _a.expanded, setExpanded = _a.setExpanded, productsCount = _a.productsCount, className = _a.className, activeFilter = _a.activeFilter;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(0), 2), countOffset = _b[0], setCountOffset = _b[1];
    var isExclusionModeOn = (0, selectors_1.useIsExclusionModeOn)();
    var _c = (0, use_fetch_products_hook_1.useFetchProducts)(countOffset, LIMIT_PRODUCTS, activeFilter), products = _c.products, hasMore = _c.hasMore, loading = _c.loading, onCheckProduct = _c.onCheckProduct;
    var observer = (0, react_1.useRef)();
    var lastProductElementRef = (0, react_1.useCallback)(function (node) {
        if (loading)
            return;
        if (observer.current)
            observer.current.disconnect();
        if (isExclusionModeOn) {
            observer.current = new IntersectionObserver(function (entries) {
                if (entries[0].isIntersecting) {
                    if (hasMore) {
                        setCountOffset(countOffset + LIMIT_PRODUCTS);
                    }
                }
            });
            if (node)
                observer.current.observe(node);
        }
    }, [loading, hasMore]);
    return (react_1.default.createElement(classify_modal_styles_1.StyledAccordion, { className: className, expanded: expanded, onChange: function (_, isExpanded) { return setExpanded(isExpanded); } },
        react_1.default.createElement(classify_modal_styles_1.StylesAccordionSummary, { expandIcon: react_1.default.createElement(classify_modal_styles_1.StyledArrowForwardIosSharp, null) },
            react_1.default.createElement("div", null, t({ key: 'CLASSIFY_MODAL_PRODUCTS', values: { productsCount: productsCount } }))),
        react_1.default.createElement(classify_modal_styles_1.StyleAccordionDetails, null,
            products.map(function (el) { return (react_1.default.createElement("div", { ref: lastProductElementRef, key: el.id },
                react_1.default.createElement(product_card_1.ProductCard, __assign({}, el, { onCheckProduct: onCheckProduct })))); }),
            loading && react_1.default.createElement(classify_modal_styles_1.StyledLoading, { iconOrientation: "right", isLoading: true, progressSize: 25, showEllipsis: false }))));
};
exports.ProductList = ProductList;
exports.default = exports.ProductList;
