"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAdvancedViewerFilterData = exports.productsAdvancedViewerFilterData = exports.useProductsTotal = exports.productsTotal = exports.useProductsFilterOperator = exports.productsFilterOperator = exports.useProductsFilterActive = exports.productsFilterActive = exports.useSelectedProductIds = exports.selectedProductIds = exports.useIsExclusionModeOn = exports.isExclusionModeOn = exports.useProductsExcludedAttributesIds = exports.productsExcludedAttributesIds = exports.useProductsIncludedAttributesIds = exports.productsIncludedAttributesIds = exports.useProductsExcludedTaxonomyClassIds = exports.productsExcludedTaxonomyClassIds = exports.useProductsIncludedTaxonomyClassIds = exports.productsIncludedTaxonomyClassIds = exports.useProductsExcludedCategoriesIds = exports.productsExcludedCategoriesIds = exports.useProductsIncludedCategoriesIds = exports.productsIncludedCategoriesIds = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
exports.productsIncludedCategoriesIds = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.includedCategoriesIds;
});
var useProductsIncludedCategoriesIds = function () { return (0, react_redux_1.useSelector)(exports.productsIncludedCategoriesIds); };
exports.useProductsIncludedCategoriesIds = useProductsIncludedCategoriesIds;
exports.productsExcludedCategoriesIds = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.excludedCategoriesIds;
});
var useProductsExcludedCategoriesIds = function () { return (0, react_redux_1.useSelector)(exports.productsExcludedCategoriesIds); };
exports.useProductsExcludedCategoriesIds = useProductsExcludedCategoriesIds;
exports.productsIncludedTaxonomyClassIds = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.includedTaxonomyClassIds;
});
var useProductsIncludedTaxonomyClassIds = function () { return (0, react_redux_1.useSelector)(exports.productsIncludedTaxonomyClassIds); };
exports.useProductsIncludedTaxonomyClassIds = useProductsIncludedTaxonomyClassIds;
exports.productsExcludedTaxonomyClassIds = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.excludedTaxonomyClassIds;
});
var useProductsExcludedTaxonomyClassIds = function () { return (0, react_redux_1.useSelector)(exports.productsExcludedTaxonomyClassIds); };
exports.useProductsExcludedTaxonomyClassIds = useProductsExcludedTaxonomyClassIds;
exports.productsIncludedAttributesIds = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.includedAttributesIds;
});
var useProductsIncludedAttributesIds = function () { return (0, react_redux_1.useSelector)(exports.productsIncludedAttributesIds); };
exports.useProductsIncludedAttributesIds = useProductsIncludedAttributesIds;
exports.productsExcludedAttributesIds = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.excludedAttributesIds;
});
var useProductsExcludedAttributesIds = function () { return (0, react_redux_1.useSelector)(exports.productsExcludedAttributesIds); };
exports.useProductsExcludedAttributesIds = useProductsExcludedAttributesIds;
exports.isExclusionModeOn = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.isExclusionModeOn;
});
var useIsExclusionModeOn = function () { return (0, react_redux_1.useSelector)(exports.isExclusionModeOn); };
exports.useIsExclusionModeOn = useIsExclusionModeOn;
exports.selectedProductIds = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.selectedProductIds;
});
var useSelectedProductIds = function () { return (0, react_redux_1.useSelector)(exports.selectedProductIds); };
exports.useSelectedProductIds = useSelectedProductIds;
exports.productsFilterActive = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.productFilterActive;
});
var useProductsFilterActive = function () { return (0, react_redux_1.useSelector)(exports.productsFilterActive); };
exports.useProductsFilterActive = useProductsFilterActive;
exports.productsFilterOperator = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.productFilterResults;
});
var useProductsFilterOperator = function () { return (0, react_redux_1.useSelector)(exports.productsFilterOperator); };
exports.useProductsFilterOperator = useProductsFilterOperator;
exports.productsTotal = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.total;
});
var useProductsTotal = function () { return (0, react_redux_1.useSelector)(exports.productsTotal); };
exports.useProductsTotal = useProductsTotal;
exports.productsAdvancedViewerFilterData = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var products = _a.products;
    return products.advancedViewerFilterData;
});
var useAdvancedViewerFilterData = function () { return (0, react_redux_1.useSelector)(exports.productsAdvancedViewerFilterData); };
exports.useAdvancedViewerFilterData = useAdvancedViewerFilterData;
