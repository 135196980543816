"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributesFilter = exports.default = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var attributes_expansion_item_component_1 = require("./attributes-expansion-item.component");
var generic_1 = require("../../../generic");
var localization_1 = require("../../../localization");
var components_1 = require("..");
var sidebar_section_1 = require("../side-bar/sidebar-section");
var AttributesFilter = function (_a) {
    var expansionAttributes = _a.expansionAttributes, isAttributesLoading = _a.isAttributesLoading, openAttributes = _a.openAttributes, onHeaderClick = _a.onHeaderClick, onCollapseAll = _a.onCollapseAll, onExpandAll = _a.onExpandAll, className = _a.className;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var isNoData = !expansionAttributes.length && !isAttributesLoading;
    var isOpenAttributesEmpty = (0, react_1.useMemo)(function () { return (0, ramda_1.all)(function (value) { return !value; }, (0, ramda_1.values)(openAttributes)); }, [openAttributes]);
    var isCollapseAllMode = isNoData || isOpenAttributesEmpty;
    return (react_1.default.createElement(components_1.SidebarSection, { className: className, title: react_1.default.createElement(react_1.default.Fragment, null,
            t({ key: 'ATTRIBUTES' }),
            react_1.default.createElement(sidebar_section_1.StyledCollapseAll, { onClick: isCollapseAllMode ? onExpandAll : onCollapseAll }, t({ key: isCollapseAllMode ? 'EXPAND_ALL' : 'COLLAPSE_ALL' }))) },
        react_1.default.createElement(generic_1.Loading, { iconOrientation: "right", isLoading: isAttributesLoading, progressSize: 25, showEllipsis: false }),
        !isAttributesLoading && expansionAttributes.map(function (_a) {
            var attributeId = _a.attributeId, subAttributes = _a.subAttributes, title = _a.title;
            return (react_1.default.createElement(attributes_expansion_item_component_1.AttributesExpansionItem, { key: attributeId, id: attributeId, title: title, items: subAttributes, isOpen: openAttributes[attributeId], onHeaderClick: onHeaderClick }));
        })));
};
var MemoizedAttributesFilter = react_1.default.memo(AttributesFilter);
exports.default = MemoizedAttributesFilter;
exports.AttributesFilter = MemoizedAttributesFilter;
