"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChipsContainer = void 0;
var react_1 = __importStar(require("react"));
var predicate_language_1 = require("@groupby/predicate-language");
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var chips_component_1 = require("./chips.component");
var hooks_1 = require("../../hooks");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var State = __importStar(require("../../store/state"));
var ChipsContainer = function (_a) {
    var className = _a.className, onClear = _a.onClear;
    var dispatch = (0, react_redux_1.useDispatch)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var includedCategoriesIds = (0, store_1.useProductsIncludedCategoriesIds)();
    var excludedCategoriesIds = (0, store_1.useProductsExcludedCategoriesIds)();
    var includedAttributesIds = (0, store_1.useProductsIncludedAttributesIds)();
    var excludedAttributesIds = (0, store_1.useProductsExcludedAttributesIds)();
    var categoriesMap = (0, store_1.useCategoriesMapSelector)();
    var formalAttributesMap = (0, store_1.useFormalAttributesMapSelector)();
    var formalAttributeValuesMap = (0, store_1.useFormalAttributeValuesMapSelector)();
    var includedTaxonomyClassIds = (0, store_1.useProductsIncludedTaxonomyClassIds)();
    var excludedTaxonomyClassIds = (0, store_1.useProductsExcludedTaxonomyClassIds)();
    var activeFilter = (0, store_1.useProductsFilterActive)();
    var taxonomyClasses = (0, store_1.useClassesSelector)();
    var client = (0, hooks_1.useEnrichClient)();
    var mapCategoryToItem = (0, react_1.useCallback)(function (category, itemType) {
        var onDelete = itemType === 'included'
            ? function () { return dispatch((0, store_1.productsSetIncludedCategoriesIds)((0, ramda_1.without)([category.id], includedCategoriesIds))); }
            : function () { return dispatch((0, store_1.productsSetExcludedCategoriesIds)((0, ramda_1.without)([category.id], excludedCategoriesIds))); };
        return {
            id: category.id,
            label: category.title,
            type: itemType,
            onDelete: onDelete,
        };
    }, [dispatch, excludedCategoriesIds, includedCategoriesIds]);
    var mapTaxonomyClass = (0, react_1.useCallback)(function (taxonomyClass, itemType) {
        var onDelete = itemType === 'included'
            ? function () { return dispatch((0, store_1.productsSetIncludedTaxonomyClassIds)(taxonomyClass.id)); }
            : function () { return dispatch((0, store_1.productsSetExcludedTaxonomyClassIds)(taxonomyClass.id)); };
        return {
            id: taxonomyClass.id,
            label: taxonomyClass.title,
            type: itemType,
            onDelete: onDelete,
        };
    }, [dispatch]);
    (0, react_1.useEffect)(function () {
        var uniqueAttributeIds = (0, ramda_1.uniq)(includedAttributesIds.concat(excludedAttributesIds)
            .map(function (joinedAttributeId) { return joinedAttributeId.split(store_1.ATTRIBUTE_PROPERTY_DELIMITER, 2)[0]; }));
        var missingAttributeIds = (0, ramda_1.without)(__spreadArray([], __read(formalAttributesMap.keys()), false), uniqueAttributeIds);
        if (missingAttributeIds.length) {
            var maxPageSize = 100;
            var attributeIdsToFetch = missingAttributeIds.slice(0, maxPageSize);
            client.formalAttributeNames.getMany({
                page: { limit: attributeIdsToFetch.length },
                filter: new predicate_language_1.PredicateBuilder((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["id"], ["id"]))), '@<', attributeIdsToFetch.map(function (id) { return (0, predicate_language_1.uuid)(id); }))),
            }).then(function (data) {
                dispatch((0, store_1.attributesSetFormalAttributes)(data));
            }, function (err) {
                console.error(err);
            });
        }
        var uniqueAttributeValueIds = (0, ramda_1.uniq)(includedAttributesIds.concat(excludedAttributesIds)
            .map(function (joinedAttributeId) { return joinedAttributeId.split(store_1.ATTRIBUTE_PROPERTY_DELIMITER, 3)[1]; }))
            .filter(function (a) { return !!a; });
        var missingAttributeValueIds = (0, ramda_1.without)(__spreadArray([], __read(formalAttributeValuesMap.keys()), false), uniqueAttributeValueIds);
        if (missingAttributeValueIds.length) {
            var maxPageSize = 100;
            var attributeValueIdsToFetch = missingAttributeValueIds.slice(0, maxPageSize);
            client.formalAttributeValues.getMany({
                page: { limit: attributeValueIdsToFetch.length },
                filter: new predicate_language_1.PredicateBuilder((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["id"], ["id"]))), '@<', attributeValueIdsToFetch.map(function (id) { return (0, predicate_language_1.uuid)(id); }))),
            }).then(function (data) {
                dispatch((0, store_1.attributesSetFormalAttributeValues)(data));
            }, function (err) {
                console.error(err);
            });
        }
    }, [dispatch, client, includedAttributesIds, excludedAttributesIds, formalAttributesMap, formalAttributeValuesMap]);
    var mapAttributeIdToItem = (0, react_1.useCallback)(function (joinedAttributeId, itemType) {
        var _a, _b, _c, _d;
        var _e = __read(joinedAttributeId.split(store_1.ATTRIBUTE_PROPERTY_DELIMITER), 3), attributeId = _e[0], valueId = _e[1], valueName = _e[2];
        var onDelete = itemType === 'included'
            ? function () { return dispatch((0, store_1.productsSetIncludedAttributesIds)((0, ramda_1.without)([joinedAttributeId], includedAttributesIds))); }
            : function () { return dispatch((0, store_1.productsSetExcludedAttributesIds)((0, ramda_1.without)([joinedAttributeId], excludedAttributesIds))); };
        return {
            id: joinedAttributeId,
            label: [(_b = (_a = formalAttributesMap.get(attributeId)) === null || _a === void 0 ? void 0 : _a.displayName) !== null && _b !== void 0 ? _b : attributeId, (_d = (_c = formalAttributeValuesMap.get(valueId)) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : (valueName || valueId)].filter(function (a) { return !!a; }).join(': '),
            type: itemType,
            onDelete: onDelete,
        };
    }, [dispatch, includedAttributesIds, excludedAttributesIds, formalAttributesMap, formalAttributeValuesMap]);
    var activeFilterItem = (0, react_1.useMemo)(function () { return ({
        id: 'activeFilterItem',
        label: t({ key: activeFilter }),
        type: chips_component_1.ITEM_TYPE.INCLUDED,
        onDelete: function () { return dispatch((0, store_1.productsSetFilterActive)(State.ProductFilterActiveTypes.All)); },
    }); }, [activeFilter, dispatch]);
    var items = (0, react_1.useMemo)(function () {
        var chips = [activeFilterItem];
        includedCategoriesIds.forEach(function (id) { return categoriesMap[id] && chips.push(mapCategoryToItem(categoriesMap[id], chips_component_1.ITEM_TYPE.INCLUDED)); });
        excludedCategoriesIds.forEach(function (id) { return categoriesMap[id] && chips.push(mapCategoryToItem(categoriesMap[id], chips_component_1.ITEM_TYPE.EXCLUDED)); });
        includedTaxonomyClassIds.forEach(function (id) { return taxonomyClasses.byId[id] && chips.push(mapTaxonomyClass(taxonomyClasses.byId[id], chips_component_1.ITEM_TYPE.INCLUDED)); });
        excludedTaxonomyClassIds.forEach(function (id) { return taxonomyClasses.byId[id] && chips.push(mapTaxonomyClass(taxonomyClasses.byId[id], chips_component_1.ITEM_TYPE.EXCLUDED)); });
        includedAttributesIds.forEach(function (id) { return chips.push(mapAttributeIdToItem(id, chips_component_1.ITEM_TYPE.INCLUDED)); });
        excludedAttributesIds.forEach(function (id) { return chips.push(mapAttributeIdToItem(id, chips_component_1.ITEM_TYPE.EXCLUDED)); });
        return chips;
    }, [
        activeFilterItem,
        includedCategoriesIds,
        excludedCategoriesIds,
        includedTaxonomyClassIds,
        excludedTaxonomyClassIds,
        includedAttributesIds,
        excludedAttributesIds,
        mapCategoryToItem,
        mapAttributeIdToItem,
        categoriesMap,
        mapTaxonomyClass,
        taxonomyClasses.byId,
    ]);
    var handleClear = (0, react_1.useCallback)(function () {
        if (onClear) {
            onClear();
        }
        dispatch((0, store_1.productsSetIncludedCategoriesIds)([]));
        dispatch((0, store_1.productsSetExcludedCategoriesIds)([]));
        dispatch((0, store_1.productsClearIncludedTaxonomyClassIds)());
        dispatch((0, store_1.productsClearExcludedTaxonomyClassIds)());
        dispatch((0, store_1.productsSetIncludedAttributesIds)([]));
        dispatch((0, store_1.productsSetExcludedAttributesIds)([]));
        dispatch((0, store_1.productsSetFilterActive)(State.ProductFilterActiveTypes.All));
    }, [dispatch, onClear]);
    return react_1.default.createElement(chips_component_1.Chips, { className: className, items: items, onClear: handleClear });
};
exports.ChipsContainer = ChipsContainer;
exports.default = exports.ChipsContainer;
var templateObject_1, templateObject_2;
