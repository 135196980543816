"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewProductsWidgetContent = void 0;
var react_1 = __importStar(require("react"));
var preview_products_widget_content_styles_1 = require("./preview-products-widget-content.styles");
var products_preview_1 = require("./products-preview");
var strategy_attributes_1 = require("../strategy-attributes");
var store_1 = require("../../../store");
var PreviewProductsWidgetContent = function (_a) {
    var onReEnrich = _a.onReEnrich;
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _b = __read((0, react_1.useState)(true), 2), isAttributeSectionVisible = _b[0], setIsAttributeSectionVisible = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isListViewMode = _c[0], setIsListViewMode = _c[1];
    var toggleAttributeSectionVisibility = function () { return setIsAttributeSectionVisible(function (oldValue) { return !oldValue; }); };
    var toggleViewMode = function () { return setIsListViewMode(function (oldValue) { return !oldValue; }); };
    var _d = __read((0, react_1.useState)(null), 2), selectedAttributeNode = _d[0], setSelectedAttributeNode = _d[1];
    (0, react_1.useEffect)(function () {
        setSelectedAttributeNode(null);
    }, [selectedStrategyNodeId, setSelectedAttributeNode]);
    return (react_1.default.createElement(preview_products_widget_content_styles_1.StyledProductsContainer, null,
        react_1.default.createElement(preview_products_widget_content_styles_1.StyledTabsContainer, null,
            isAttributeSectionVisible && (react_1.default.createElement(strategy_attributes_1.StrategyAttributesContainer, { onReEnrich: onReEnrich, selectedAttributeNode: selectedAttributeNode, onSelectAttributeNode: setSelectedAttributeNode })),
            react_1.default.createElement(products_preview_1.ProductsPreviewContainer, { selectedAttributeNode: selectedAttributeNode, isAttributesSectionVisible: isAttributeSectionVisible, isListViewMode: isListViewMode, toggleAttributeSectionVisibility: toggleAttributeSectionVisibility, toggleViewMode: toggleViewMode }))));
};
exports.PreviewProductsWidgetContent = PreviewProductsWidgetContent;
