"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaginationValues = void 0;
var getPaginationValues = function (_a) {
    var itemsCount = _a.itemsCount, pageNumber = _a.pageNumber, pageSize = _a.pageSize;
    var pagesCount = Math.ceil(itemsCount / pageSize);
    var startRange = (pageNumber - 1) * pageSize + 1;
    var endRange = (pageNumber === pagesCount ? itemsCount : startRange + pageSize - 1);
    return {
        pagesCount: pagesCount,
        startRange: startRange,
        endRange: endRange,
    };
};
exports.getPaginationValues = getPaginationValues;
