"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetStrategyAttributes = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var attributes_utils_1 = require("./attributes.utils");
var use_attributes_base_filter_hook_1 = require("./use-attributes-base-filter.hook");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var hooks_1 = require("../../hooks");
var get_strategy_attributes_query_1 = require("../get-strategy-attributes.query");
var models_1 = require("../../../models");
// merge the new part with an already loaded
var updateQuery = function (previousResult, options) {
    var _a, _b;
    var _c = ((_a = previousResult.productTreeNode.labelValidation) !== null && _a !== void 0 ? _a : {}).edges, prevAttributes = _c === void 0 ? [] : _c;
    var productTreeNode = ((_b = options === null || options === void 0 ? void 0 : options.fetchMoreResult) !== null && _b !== void 0 ? _b : {}).productTreeNode;
    var _d = productTreeNode.labelValidation, _e = _d.edges, edges = _e === void 0 ? [] : _e, productStrategyLabelsData = __rest(_d, ["edges"]);
    return {
        productTreeNode: __assign(__assign(__assign({}, previousResult.productTreeNode), productTreeNode), { labelValidation: __assign(__assign({}, productStrategyLabelsData), { edges: __spreadArray(__spreadArray([], __read(prevAttributes), false), __read(edges), false) }) }),
    };
};
var fetchMoreOptions = { updateQuery: updateQuery };
var useGetStrategyAttributes = function (_a) {
    var _b, _c;
    var productTreeNodeId = _a.productTreeNodeId, _d = _a.sortingItem, sortingItem = _d === void 0 ? models_1.SortingItem.SortByTextAsc : _d, _e = _a.pageSize, pageSize = _e === void 0 ? client_constants_1.PAGE_SIZE_LARGE : _e, options = _a.options, filter = _a.filter;
    var sort = attributes_utils_1.sortingItemToStrategyAttributesSortMap[sortingItem];
    var baseFilter = (0, use_attributes_base_filter_hook_1.useAttributesBaseFilter)();
    var _f = (0, client_1.useQuery)(get_strategy_attributes_query_1.GET_STRATEGY_ATTRIBUTES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            sort: sort,
            productTreeNodeId: productTreeNodeId !== null && productTreeNodeId !== void 0 ? productTreeNodeId : '',
            filter: filter || baseFilter,
            first: pageSize,
            before: null,
            after: null,
            last: null,
        },
        skip: !productTreeNodeId || (options === null || options === void 0 ? void 0 : options.skip),
    }), data = _f.data, previousData = _f.previousData, error = _f.error, networkStatus = _f.networkStatus, variables = _f.variables, fetchMore = _f.fetchMore, refetch = _f.refetch;
    var _g = (_b = data === null || data === void 0 ? void 0 : data.productTreeNode.labelValidation) !== null && _b !== void 0 ? _b : {}, pageInfo = _g.pageInfo, edges = _g.edges;
    var hasNextPage = (pageInfo !== null && pageInfo !== void 0 ? pageInfo : {}).hasNextPage;
    var pagination = (0, hooks_1.usePagination)({
        networkStatus: networkStatus,
        pageInfo: pageInfo,
        pageSize: pageSize,
        fetchMore: fetchMore,
        fetchMoreOptions: fetchMoreOptions,
        lastVariables: variables,
    });
    var onRefetch = (0, react_1.useCallback)(function (newVariables) {
        pagination.setCurrentPage(1);
        return refetch(__assign(__assign({}, newVariables), { first: pageSize, before: null, after: null, last: null }));
    }, [pageSize, pagination, refetch]);
    var onSearch = (0, react_1.useCallback)(function (searchString) { return onRefetch({
        filter: {
            some: {
                label: {
                    contains: (0, client_utils_1.normalizeSearchString)(searchString),
                },
            },
        },
    }); }, [onRefetch]);
    return {
        data: edges,
        previousData: (_c = previousData === null || previousData === void 0 ? void 0 : previousData.productTreeNode.labelValidation) === null || _c === void 0 ? void 0 : _c.edges,
        productTreeNodeTitle: data === null || data === void 0 ? void 0 : data.productTreeNode.title,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        pagination: pagination,
        error: error,
        hasNextPage: hasNextPage,
        refetch: onRefetch,
        onSearch: onSearch,
    };
};
exports.useGetStrategyAttributes = useGetStrategyAttributes;
