"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNotification = void 0;
var react_1 = require("react");
var context_1 = require("../context");
var useNotification = function () {
    var _a = (0, react_1.useContext)(context_1.EnrichContext).notification, addError = _a.addError, addSuccess = _a.addSuccess;
    return { addError: addError, addSuccess: addSuccess };
};
exports.useNotification = useNotification;
exports.default = exports.useNotification;
