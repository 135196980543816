"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStrategyNodeChanges = exports.addPathRecursive = void 0;
var react_1 = require("react");
var use_history_changes_hook_1 = require("./use-history-changes.hook");
var store_1 = require("../../store");
var addPathRecursive = function (path, uniquePaths) {
    var pathParts = path.split(store_1.STRATEGY_NODE_PATH_SEPARATOR);
    // only the root node is left
    if (pathParts.length === 1) {
        return;
    }
    if (uniquePaths.has(path)) {
        return;
    }
    uniquePaths.add(path);
    pathParts.pop();
    var newPath = pathParts.join(store_1.STRATEGY_NODE_PATH_SEPARATOR);
    (0, exports.addPathRecursive)(newPath, uniquePaths);
};
exports.addPathRecursive = addPathRecursive;
var useStrategyNodeChanges = function () {
    var catalogChanges = (0, use_history_changes_hook_1.useHistoryChanges)().catalogChanges;
    var uniqueStrategyNodePaths = (0, react_1.useMemo)(function () {
        var uniquePaths = new Set();
        catalogChanges === null || catalogChanges === void 0 ? void 0 : catalogChanges.forEach(function (_a) {
            var path = _a.path;
            return (0, exports.addPathRecursive)(path, uniquePaths);
        });
        return uniquePaths;
    }, [catalogChanges]);
    var checkIsNodeChanged = (0, react_1.useCallback)(function (path) { return uniqueStrategyNodePaths.has(path); }, [uniqueStrategyNodePaths]);
    return {
        checkIsNodeChanged: checkIsNodeChanged,
    };
};
exports.useStrategyNodeChanges = useStrategyNodeChanges;
