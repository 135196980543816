"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Attribute = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var attribute_value_component_1 = require("./attribute-value.component");
var attributes_styles_1 = require("./attributes.styles");
// commented out for EN-3066
// import { WarningIcon } from '@/enrich/icons';
var hooks_1 = require("../../../hooks");
var Attribute = function (_a) {
    var attribute = _a.attribute, displayValueOnly = _a.displayValueOnly;
    var name = attribute.name, _b = attribute.values, values = _b === void 0 ? [] : _b;
    // commented out for EN-3066
    // const { name, active: isAttributeActive, values = [] } = attribute;
    var attributeNameRef = (0, react_1.useRef)(null);
    var isAttributeOverflowing = (0, hooks_1.useIsTextOverflowing)(attributeNameRef);
    // commented out for EN-3066
    // const titleIcon = !isAttributeActive && <WarningIcon />;
    var attributeText = react_1.default.createElement("span", { ref: attributeNameRef, className: 'attribute-text' }, name);
    // commented out for EN-3066
    // eslint-disable-next-line max-len
    // const attributeText = <span ref={attributeNameRef} className={cn('attribute-text', { 'attribute-text--crossed-out': !isAttributeActive })}>{name}</span>;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !displayValueOnly && (react_1.default.createElement(attributes_styles_1.StyledAttributeContainerCell, null, isAttributeOverflowing
            ? react_1.default.createElement(ui_components_1.ToolTip, { arrow: true, title: name }, attributeText)
            : attributeText)),
        react_1.default.createElement(attribute_value_component_1.AttributeValue, { values: values })));
};
exports.Attribute = Attribute;
exports.default = exports.Attribute;
