"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHistoryChanges = void 0;
var react_1 = require("react");
var store_1 = require("../../store");
var useHistoryChanges = function () {
    var catalogChanges = (0, store_1.useCatalogChanges)();
    var selectedStrategyNodePath = (0, store_1.useSelectedTreeNodePath)();
    var selectedStrategyNodeChanges = (0, react_1.useMemo)(function () { var _a; return (_a = catalogChanges === null || catalogChanges === void 0 ? void 0 : catalogChanges.filter(function (_a) {
        var path = _a.path;
        return path.startsWith(selectedStrategyNodePath);
    })) !== null && _a !== void 0 ? _a : []; }, [catalogChanges, selectedStrategyNodePath]);
    return {
        selectedStrategyNodeChanges: selectedStrategyNodeChanges,
        catalogChanges: catalogChanges,
    };
};
exports.useHistoryChanges = useHistoryChanges;
