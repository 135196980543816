"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.facetNavigationSetOpenTaxonomyClasses = exports.facetNavigationSetOpenAttributes = exports.facetNavigationSetOpenCategories = exports.facetNavigationSetSearchTerm = void 0;
var facet_navigation_panel_1 = require("../types/facet-navigation-panel");
var utils_1 = require("../utils");
var facetNavigationSetSearchTerm = function (searchTerm) { return (0, utils_1.createAction)(facet_navigation_panel_1.FACET_NAVIGATION_SET_SEARCH_TERM, searchTerm); };
exports.facetNavigationSetSearchTerm = facetNavigationSetSearchTerm;
var facetNavigationSetOpenCategories = function (openCategories) { return (0, utils_1.createAction)(facet_navigation_panel_1.FACET_NAVIGATION_SET_OPEN_CATEGORIES, openCategories); };
exports.facetNavigationSetOpenCategories = facetNavigationSetOpenCategories;
var facetNavigationSetOpenAttributes = function (openAttributes) { return (0, utils_1.createAction)(facet_navigation_panel_1.FACET_NAVIGATION_SET_OPEN_ATTRIBUTES, openAttributes); };
exports.facetNavigationSetOpenAttributes = facetNavigationSetOpenAttributes;
var facetNavigationSetOpenTaxonomyClasses = function (openTaxonomyClasses) { return (0, utils_1.createAction)(facet_navigation_panel_1.FACET_NAVIGATION_SET_OPEN_TAXONOMY_CLASSES, openTaxonomyClasses); };
exports.facetNavigationSetOpenTaxonomyClasses = facetNavigationSetOpenTaxonomyClasses;
