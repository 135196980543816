"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserRoutePermissions = exports.ENRICH = exports.EnrichSection = exports.EnrichRouter = exports.ENRICH_DRAWER_SECTION = exports.getEnrichDrawerRoutes = void 0;
var routes_1 = require("./routes");
Object.defineProperty(exports, "getEnrichDrawerRoutes", { enumerable: true, get: function () { return routes_1.getEnrichDrawerRoutes; } });
Object.defineProperty(exports, "ENRICH_DRAWER_SECTION", { enumerable: true, get: function () { return routes_1.ENRICH_DRAWER_SECTION; } });
var router_1 = require("./router");
Object.defineProperty(exports, "EnrichRouter", { enumerable: true, get: function () { return router_1.EnrichRouter; } });
var router_constants_1 = require("./router.constants");
Object.defineProperty(exports, "EnrichSection", { enumerable: true, get: function () { return router_constants_1.EnrichSection; } });
Object.defineProperty(exports, "ENRICH", { enumerable: true, get: function () { return router_constants_1.ENRICH; } });
var use_user_routes_permissions_1 = require("./use-user-routes-permissions");
Object.defineProperty(exports, "useUserRoutePermissions", { enumerable: true, get: function () { return use_user_routes_permissions_1.useUserRoutePermissions; } });
