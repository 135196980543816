"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT = void 0;
var Actions = __importStar(require("../actions/types"));
exports.DEFAULT = {
    formalAttributesMap: new Map(),
    formalAttributeValuesMap: new Map(),
    attributes: [],
    attributeValuesMap: {},
    oldAttributeValuesMap: new Map(),
};
var attributesSetFormalAttributes = function (state, formalAttributes) {
    if (formalAttributes.length) {
        // create a copy so that useEffect hooks see this as a new map.
        var formalAttributesMap_1 = new Map(state.formalAttributesMap);
        formalAttributes.forEach(function (formalAttribute) { return formalAttributesMap_1.set(formalAttribute.id, formalAttribute); });
        return __assign(__assign({}, state), { formalAttributesMap: formalAttributesMap_1 });
    }
    return state;
};
var attributesSetFormalAttributeValues = function (state, formalAttributeValues) {
    if (formalAttributeValues.length) {
        // create a copy so that useEffect hooks see this as a new map.
        var formalAttributeValuesMap_1 = new Map(state.formalAttributeValuesMap);
        formalAttributeValues.forEach(function (formalAttributeValue) { return formalAttributeValuesMap_1
            .set(formalAttributeValue.id, formalAttributeValue); });
        return __assign(__assign({}, state), { formalAttributeValuesMap: formalAttributeValuesMap_1 });
    }
    return state;
};
var attributesSetAttributes = function (state, _a) {
    var attributes = _a.attributes, isMultiFacetNavEnabled = _a.isMultiFacetNavEnabled;
    return (__assign(__assign(__assign({}, state), { attributes: __spreadArray([], __read(attributes), false) }), (isMultiFacetNavEnabled && {
        attributeValuesMap: __spreadArray([], __read(attributes), false).reduce(function (attributesMap, actualAttribute) {
            var _a;
            attributesMap[actualAttribute.attributeId] = ((_a = attributesMap[actualAttribute.attributeId]) !== null && _a !== void 0 ? _a : []).concat(actualAttribute);
            return attributesMap;
        }, {}),
    })));
};
var attributesSetValuesMap = function (state, attributeValuesMap) { return (__assign(__assign({}, state), { oldAttributeValuesMap: new Map(attributeValuesMap) })); };
exports.default = (function (state, action) {
    if (state === void 0) { state = exports.DEFAULT; }
    switch (action.type) {
        case Actions.ATTRIBUTES_SET_FORMAL_ATTRIBUTES:
            return attributesSetFormalAttributes(state, action.payload);
        case Actions.ATTRIBUTES_SET_FORMAL_ATTRIBUTE_VALUES:
            return attributesSetFormalAttributeValues(state, action.payload);
        case Actions.ATTRIBUTES_SET_ATTRIBUTES:
            return attributesSetAttributes(state, action.payload);
        case Actions.ATTRIBUTES_SET_VALUES_MAP:
            return attributesSetValuesMap(state, action.payload);
        default:
            return state;
    }
});
