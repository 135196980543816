"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsListView = void 0;
var react_1 = __importStar(require("react"));
var hooks_1 = require("./hooks");
var use_products_table_columns_config_hook_1 = require("./hooks/use-products-table-columns-config.hook");
var products_customize_view_1 = require("./products-customize-view");
var products_tags_menu_container_1 = require("./products-tags-menu/products-tags-menu.container");
var table_1 = require("../../../../../common/table");
var attributes_1 = require("../../../../client/attributes");
var product_1 = require("../../../../client/product");
var feature_flags_1 = require("../../../../../feature-flags");
var hooks_2 = require("../../../../../hooks");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var ProductsListView = function (_a) {
    var selected = _a.selected, isSelectable = _a.isSelectable, isCustomizeViewOpen = _a.isCustomizeViewOpen, search = _a.search, selectedAttributeNode = _a.selectedAttributeNode, onCompleted = _a.onCompleted, onSelect = _a.onSelect, onActiveProductIdChange = _a.onActiveProductIdChange, onCustomizeViewClose = _a.onCustomizeViewClose, setProducts = _a.setProducts;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var featureFlagsService = (0, hooks_2.useFeatureFlagsService)().featureFlagsService;
    var isMeasureEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures);
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)(null), 2), cellInfo = _b[0], setCellInfo = _b[1];
    var productsTableColumnsConfig = (0, use_products_table_columns_config_hook_1.useProductsTableColumnsConfig)();
    var _c = (0, hooks_1.useProductsTableConfig)({
        productsTableColumnsConfig: productsTableColumnsConfig,
        onCellClick: setCellInfo,
    }).tableColumns, columns = _c.columns, _d = _c.selection, excludedColumns = _d.excludedColumns, excludedLabelColumns = _d.excludedLabelColumns, excludedMeasureColumns = _d.excludedMeasureColumns, selectAllMode = _d.selectAllMode, columnsOrder = _c.order.columnsOrder, onChangeColumns = _c.actions.onChangeColumns;
    var productLabelFilter = (0, react_1.useMemo)(function () {
        if (!excludedLabelColumns.length) {
            return undefined;
        }
        return { label: (!selectAllMode ? { in: excludedLabelColumns } : { notIn: excludedLabelColumns }) };
    }, [excludedLabelColumns, selectAllMode]);
    var productMeasureFilter = (0, react_1.useMemo)(function () {
        if (!excludedMeasureColumns.length) {
            return undefined;
        }
        return { dimension: (!selectAllMode ? { in: excludedMeasureColumns } : { notIn: excludedMeasureColumns }) };
    }, [excludedMeasureColumns, selectAllMode]);
    var _e = (0, product_1.useGetProducts)({
        catalogId: selectedCatalogId,
        nodeId: selectedStrategyNodeId,
        attributeNode: selectedAttributeNode,
        search: search,
        productLabelFilter: productLabelFilter,
        productMeasureFilter: productMeasureFilter,
        skipMeasures: !isMeasureEnabled,
        onCompleted: onCompleted,
    }), products = _e.products, _f = _e.pagination, pageNumber = _f.pageNumber, pageSize = _f.pageSize, totalCount = _f.totalCount, onPageChange = _f.onPageChange, onPageSizeChange = _f.onPageSizeChange, isLoadingMore = _f.isLoadingMore, isLoading = _e.isLoading, error = _e.error;
    // TODO: remove this when we have a better way to handle this, probably lifting the state up
    (0, react_1.useEffect)(function () {
        if (products === null || products === void 0 ? void 0 : products.length)
            setProducts(products);
    }, [products, setProducts]);
    var parsedProducts = (0, react_1.useMemo)(function () { return products.map(function (_a) {
        var labels = _a.labels, measures = _a.measures, product = __rest(_a, ["labels", "measures"]);
        var productAttributes = labels.edges.reduce(function (acc, _a) {
            var _b;
            var _c;
            var node = _a.node;
            var label = node.label, value = node.value, assertedIncorrect = node.assertedIncorrect;
            var formattedValue = value === attributes_1.SpecialAttributeValue.NotApplicable ? t({ key: 'NOT_APPLICABLE_SHORT' }) : value;
            if (assertedIncorrect) {
                return acc;
            }
            return __assign(__assign({}, acc), (_b = {}, _b[label] = __spreadArray(__spreadArray([], __read((_c = acc[label]) !== null && _c !== void 0 ? _c : []), false), [formattedValue], false), _b));
        }, {});
        var productMeasures = measures === null || measures === void 0 ? void 0 : measures.edges.reduce(function (acc, _a) {
            var _b;
            var _c;
            var node = _a.node;
            var dimension = node.dimension, value = node.value;
            return __assign(__assign({}, acc), (_b = {}, _b[dimension] = __spreadArray(__spreadArray([], __read((_c = acc[dimension]) !== null && _c !== void 0 ? _c : []), false), [value], false), _b));
        }, {});
        return __assign(__assign(__assign({}, productAttributes), productMeasures), product);
    }); }, [products, t]);
    var handleSelect = function (newSelected) {
        var selectedIds = newSelected.map(function (_a) {
            var id = _a.id;
            return id;
        });
        onSelect(selectedIds);
    };
    var selectedItems = (0, react_1.useMemo)(function () { return parsedProducts.filter(function (_a) {
        var id = _a.id;
        return selected.includes(id);
    }); }, [parsedProducts, selected]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(table_1.Table, { isSelectable: isSelectable, columns: columns, data: parsedProducts, selected: selectedItems, setSelected: handleSelect, pageNumber: pageNumber, rowsPerPage: pageSize, total: totalCount, isLoading: isLoading || isLoadingMore, error: error === null || error === void 0 ? void 0 : error.message, setPerPage: onPageSizeChange, setPage: onPageChange, onRowClick: function (row) { return onActiveProductIdChange(row.id); } }),
        isCustomizeViewOpen && (react_1.default.createElement(products_customize_view_1.ProductCustomizeViewContainer, { excludedColumns: excludedColumns, columnsOrder: columnsOrder, selectAllMode: selectAllMode, columnsConfig: productsTableColumnsConfig, onChangeColumns: onChangeColumns, onClose: onCustomizeViewClose })),
        cellInfo && cellInfo.value && (react_1.default.createElement(products_tags_menu_container_1.ProductsTagsMenuContainer, { cellInfo: cellInfo, onClose: function () { return setCellInfo(null); } }))));
};
exports.ProductsListView = ProductsListView;
exports.default = exports.ProductsListView;
