"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldRowBeHighlighted = void 0;
var date_fns_1 = require("date-fns");
var shouldRowBeHighlighted = function (_a, previousValue) {
    var id = _a.id, lastCommentAt = _a.lastCommentAt;
    if (id !== previousValue.id) {
        return false;
    }
    return (0, date_fns_1.isAfter)(new Date(lastCommentAt), new Date(previousValue.lastCommentAt));
};
exports.shouldRowBeHighlighted = shouldRowBeHighlighted;
