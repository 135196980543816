"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCollapsible = void 0;
var react_1 = require("react");
var models_1 = require("../../../../models");
var utils_1 = require("../../../../utils");
var useCollapsible = function () {
    var _a = __read((0, react_1.useState)([]), 2), excludedItems = _a[0], setExcludedItems = _a[1];
    var _b = __read((0, react_1.useState)(models_1.CollapseState.Collapse), 2), collapseState = _b[0], setCollapseState = _b[1];
    var _c = __read((0, react_1.useTransition)(), 2), _ = _c[0], startTransition = _c[1];
    var isAtLeastOneExpanded = !(collapseState === models_1.CollapseState.Collapse && !excludedItems.length);
    var toggleExpandAll = (0, react_1.useCallback)(function () {
        startTransition(function () {
            setCollapseState(isAtLeastOneExpanded ? models_1.CollapseState.Collapse : models_1.CollapseState.Expand);
        });
        setExcludedItems([]);
    }, [isAtLeastOneExpanded]);
    var toggleExpand = (0, react_1.useCallback)(function (id, expanded) {
        var value = (collapseState === models_1.CollapseState.Expand && !expanded) || (collapseState === models_1.CollapseState.Collapse && expanded);
        setExcludedItems((0, utils_1.updateUniqueItemListBasedOnValue)(id, value));
    }, [collapseState]);
    var checkIsExpanded = (0, react_1.useCallback)(function (item) {
        var isExcluded = excludedItems.includes(item);
        return collapseState === models_1.CollapseState.Collapse ? isExcluded : !isExcluded;
    }, [collapseState, excludedItems]);
    return {
        isAtLeastOneExpanded: isAtLeastOneExpanded,
        toggleExpandAll: toggleExpandAll,
        toggleExpand: toggleExpand,
        checkIsExpanded: checkIsExpanded,
    };
};
exports.useCollapsible = useCollapsible;
