"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortByNumberField = exports.sortByTextFieldCaseInsensitive = exports.SortOrder = void 0;
var ramda_1 = require("ramda");
var SortOrder;
(function (SortOrder) {
    SortOrder[SortOrder["Ascend"] = 0] = "Ascend";
    SortOrder[SortOrder["Descend"] = 1] = "Descend";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
var sortOrderToComparatorFunctionMap = (_a = {},
    _a[SortOrder.Ascend] = ramda_1.ascend,
    _a[SortOrder.Descend] = ramda_1.descend,
    _a);
var sortBy = function (list, _a) {
    var propName = _a.propName, _b = _a.sortOrder, sortOrder = _b === void 0 ? SortOrder.Ascend : _b, parser = _a.parser;
    var comparator = sortOrderToComparatorFunctionMap[sortOrder];
    return (0, ramda_1.sort)(comparator((0, ramda_1.compose)(parser, (0, ramda_1.prop)(propName))), list);
};
var sortByTextFieldCaseInsensitive = function (sortOrder) { return function (list, propName) { return sortBy(list, { propName: propName, sortOrder: sortOrder, parser: ramda_1.toLower }); }; };
exports.sortByTextFieldCaseInsensitive = sortByTextFieldCaseInsensitive;
var sortByNumberField = function (sortOrder) { return function (list, propName) { return sortBy(list, { propName: propName, sortOrder: sortOrder, parser: Number }); }; };
exports.sortByNumberField = sortByNumberField;
