"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEnrichPath = void 0;
var react_1 = require("react");
var context_1 = require("../context");
var useEnrichPath = function () {
    var enrichPath = (0, react_1.useContext)(context_1.EnrichContext).config.enrichPath;
    return enrichPath;
};
exports.useEnrichPath = useEnrichPath;
exports.default = exports.useEnrichPath;
