"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTooltip = void 0;
var react_1 = require("react");
var useTooltip = function () {
    var _a = __read((0, react_1.useState)(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var onMouseEnter = (0, react_1.useCallback)(function (event) {
        var el = event.target;
        var isOpen = el.offsetWidth < el.scrollWidth;
        setIsOpen(isOpen);
    }, []);
    return {
        isOpen: isOpen,
        setIsOpen: setIsOpen,
        onMouseEnter: onMouseEnter,
    };
};
exports.useTooltip = useTooltip;
