"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkCommentDialog = void 0;
var react_1 = __importStar(require("react"));
var predicate_language_1 = require("@groupby/predicate-language");
var ui_components_1 = require("@groupby/ui-components");
var bulk_commenting_dialog_styles_1 = require("./bulk-commenting-dialog.styles");
var partials_1 = require("../partials");
var hooks_1 = require("../../../../../hooks");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var BulkCommentDialog = function (_a) {
    var selectedCount = _a.selectedCount, activeFilter = _a.activeFilter, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var addError = (0, hooks_1.useNotification)().addError;
    var _b = __read((0, react_1.useState)(false), 2), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isSubmitting = _c[0], setIsSubmitting = _c[1];
    var handleClose = function () { return onClose(false); };
    var client = (0, hooks_1.useEnrichClient)();
    var isExclusionModeOn = (0, store_1.useIsExclusionModeOn)();
    var selectedProductIds = (0, store_1.useSelectedProductIds)();
    var handleSubmit = function (comment) { return __awaiter(void 0, void 0, void 0, function () {
        var productIds, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSubmitting(true);
                    productIds = selectedProductIds;
                    if (isExclusionModeOn) {
                        productIds = (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["id"], ["id"]))), '!@<', selectedProductIds.map(predicate_language_1.uuid));
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, client.comments.createOne({ content: comment, subjectIds: productIds })];
                case 2:
                    _a.sent();
                    onClose(true);
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    addError(t({ key: 'COMMENT_CREATE_ERROR' }));
                    throw new Error(e_1.message);
                case 4:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement(ui_components_1.Dialog, { maxWidth: "sm", title: react_1.default.createElement(partials_1.DialogTitle, { title: t({ key: 'BULK_COMMENTING_DIALOG_TITLE' }), onClose: handleClose }) },
        react_1.default.createElement(bulk_commenting_dialog_styles_1.StyledDialogContent, null,
            react_1.default.createElement(bulk_commenting_dialog_styles_1.StyledProductList, { expanded: isExpanded, setExpanded: setIsExpanded, productsCount: selectedCount, activeFilter: activeFilter }),
            react_1.default.createElement(partials_1.CommentTextBox, { isSubmitting: isSubmitting, onSubmit: handleSubmit }))));
};
exports.BulkCommentDialog = BulkCommentDialog;
exports.default = exports.BulkCommentDialog;
var templateObject_1;
