"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTablePagination = void 0;
var react_1 = require("react");
var useTablePagination = function (filter, setLastSelectedRowId) {
    var _a = __read((0, react_1.useState)(1), 2), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = __read((0, react_1.useState)(20), 2), rowsPerPage = _b[0], setRowsPerPage = _b[1];
    (0, react_1.useEffect)(function () {
        setCurrentPage(1);
    }, [filter]);
    var handlePageChange = (0, react_1.useCallback)(function (newValue) {
        setLastSelectedRowId(null);
        setCurrentPage(newValue);
    }, [setLastSelectedRowId]);
    var handleRowsPerPageChange = (0, react_1.useCallback)(function (newValue) {
        setCurrentPage(1);
        setRowsPerPage(newValue);
    }, []);
    return {
        currentPage: currentPage,
        rowsPerPage: rowsPerPage,
        handlePageChange: handlePageChange,
        handleRowsPerPageChange: handleRowsPerPageChange,
    };
};
exports.useTablePagination = useTablePagination;
exports.default = exports.useTablePagination;
