"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteAttributeDialog = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var react_redux_1 = require("react-redux");
var delete_attribute_dialog_styles_1 = require("./delete-attribute-dialog.styles");
var delete_from_other_nodes_dialog_component_1 = require("../delete-from-other-nodes-dialog.component");
var attributes_1 = require("../../../../client/attributes");
var hooks_1 = require("../../../../client/hooks");
var values_1 = require("../../../../client/values");
var context_1 = require("../../../../context");
var generic_1 = require("../../../../generic");
var hooks_2 = require("../../../../../hooks");
var localization_1 = require("../../../../../localization");
var store_1 = require("../../../../../store");
var DeleteAttributeDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose, onDeleteAttributesInSelectedNode = _a.onDeleteAttributesInSelectedNode;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var dispatch = (0, react_redux_1.useDispatch)();
    var refetchStrategyAttributes = (0, hooks_1.useRefetchQueries)().refetchStrategyAttributes;
    var _b = (0, hooks_2.useNotification)(), addError = _b.addError, addSuccess = _b.addSuccess;
    var _c = (0, context_1.useCheckedAttributes)(), _d = _c.checkedIds, checkedIds = _d === void 0 ? [] : _d, isValueCheckMode = _c.isValueCheckMode, checkedItemTypeTitle = _c.checkedItemTypeTitle, correspondingAttributeInValueCheckMode = _c.correspondingAttributeInValueCheckMode, clearCheckedIds = _c.clearCheckedIds, getCheckedEntityLabels = _c.getCheckedEntityLabels;
    var deleteAttributesFromSeveralNodes = (0, attributes_1.useDeleteAttributesFromSeveralNodes)().deleteAttributesFromSeveralNodes;
    var deleteValuesFromSeveralNodes = (0, values_1.useDeleteValuesFromSeveralNodes)().deleteValuesFromSeveralNodes;
    var _e = __read((0, react_1.useState)(false), 2), isDeleteFromOthersDialogOpen = _e[0], setIsDeleteFromOthersDialogOpen = _e[1];
    var _f = __read((0, react_1.useState)(false), 2), isDeleteFromSelectedNodeLoading = _f[0], setIsDeleteFromSelectedNodeLoading = _f[1];
    var _g = __read((0, react_1.useState)(false), 2), isDeleteFromOtherNodesLoading = _g[0], setIsDeleteFromOtherNodesLoading = _g[1];
    var selectedStrategyNodePath = (0, store_1.useSelectedTreeNodePath)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var localizationMessageValues = { count: checkedIds.length, entity: checkedItemTypeTitle };
    var checkedLabels = (0, react_1.useMemo)(function () { return getCheckedEntityLabels(); }, [getCheckedEntityLabels]);
    var handleAddItemsToHistory = function (ids, treeNodePaths, entityType) {
        var e_1, _a, e_2, _b;
        var historyItems = [];
        try {
            for (var ids_1 = __values(ids), ids_1_1 = ids_1.next(); !ids_1_1.done; ids_1_1 = ids_1.next()) {
                var id = ids_1_1.value;
                try {
                    for (var treeNodePaths_1 = (e_2 = void 0, __values(treeNodePaths)), treeNodePaths_1_1 = treeNodePaths_1.next(); !treeNodePaths_1_1.done; treeNodePaths_1_1 = treeNodePaths_1.next()) {
                        var treeNodePath = treeNodePaths_1_1.value;
                        var historyItem = {
                            id: id,
                            entityType: entityType,
                            actionType: store_1.ChangeActionType.Delete,
                            path: treeNodePath,
                        };
                        historyItems.push(historyItem);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (treeNodePaths_1_1 && !treeNodePaths_1_1.done && (_b = treeNodePaths_1.return)) _b.call(treeNodePaths_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (ids_1_1 && !ids_1_1.done && (_a = ids_1.return)) _a.call(ids_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        dispatch((0, store_1.enrichAIAddChanges)(historyItems));
    };
    var handleDeleteCheckedItems = function (treeNodePathList) { return __awaiter(void 0, void 0, void 0, function () {
        var treeNodeIdList, attributeLabel_1, historyIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    treeNodeIdList = treeNodePathList.map(function (path) { return path.split(store_1.STRATEGY_NODE_PATH_SEPARATOR).pop(); });
                    if (!isValueCheckMode) return [3 /*break*/, 2];
                    attributeLabel_1 = correspondingAttributeInValueCheckMode;
                    return [4 /*yield*/, deleteValuesFromSeveralNodes(attributeLabel_1, checkedLabels, treeNodeIdList)];
                case 1:
                    _a.sent();
                    historyIds = checkedLabels.map(function (value) { return "".concat(attributeLabel_1).concat(store_1.LABEL_VALUE_PAIR_SEPARATOR).concat(value); });
                    handleAddItemsToHistory(historyIds, treeNodePathList, store_1.ChangeEntityType.Value);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, deleteAttributesFromSeveralNodes(checkedLabels, treeNodeIdList)];
                case 3:
                    _a.sent();
                    handleAddItemsToHistory(checkedLabels, treeNodePathList, store_1.ChangeEntityType.Attribute);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteAttributesInSelectedNode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsDeleteFromSelectedNodeLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, handleDeleteCheckedItems([selectedStrategyNodePath])];
                case 2:
                    _a.sent();
                    onDeleteAttributesInSelectedNode();
                    setIsDeleteFromOthersDialogOpen(true);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    addError(t({ key: 'DELETE_ENTITY_ERROR', values: localizationMessageValues }), error_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    setIsDeleteFromSelectedNodeLoading(false);
                    onClose();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleUpdateProductPresentation = function () { return refetchStrategyAttributes(selectedStrategyNodeId); };
    var handleClose = function () {
        clearCheckedIds();
        void handleUpdateProductPresentation();
    };
    var handleDeleteFromOtherNodes = function (selectedNodePaths) { return __awaiter(void 0, void 0, void 0, function () {
        var localizationMessageValues, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsDeleteFromOtherNodesLoading(true);
                    localizationMessageValues = { count: checkedIds.length, entity: checkedItemTypeTitle };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, handleDeleteCheckedItems(selectedNodePaths)];
                case 2:
                    _a.sent();
                    clearCheckedIds();
                    addSuccess(t({ key: 'DELETE_ENTITY_SUCCESS', values: localizationMessageValues }));
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    addError(t({ key: 'DELETE_ENTITY_ERROR', values: localizationMessageValues }), error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setIsDeleteFromOtherNodesLoading(false);
                    setIsDeleteFromOthersDialogOpen(false);
                    void handleUpdateProductPresentation();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        open && (react_1.default.createElement(delete_attribute_dialog_styles_1.StyledDialog, { title: t({ key: 'CONFIRMATION_DELETE_TITLE', values: localizationMessageValues }), proceedButtonProps: {
                error: true,
                title: t({ key: 'DELETE' }),
                onClick: handleDeleteAttributesInSelectedNode,
                disabled: isDeleteFromSelectedNodeLoading,
                endIcon: isDeleteFromSelectedNodeLoading && react_1.default.createElement(generic_1.LoadingIndicator, null),
            }, cancelButtonProps: { title: t({ key: 'CANCEL' }), onClick: onClose } },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(ui_components_1.Typography, { variant: "caption" }, t({ key: 'DELETE_DIALOG_WARNING_MESSAGE' })),
                checkedLabels.map(function (label, index) { return react_1.default.createElement(generic_1.ListRowItem, { key: index, text: label }); })))),
        isDeleteFromOthersDialogOpen && (react_1.default.createElement(delete_from_other_nodes_dialog_component_1.DeleteFromOtherNodesDialog, { onClose: handleClose, onSubmit: handleDeleteFromOtherNodes, isLoading: isDeleteFromOtherNodesLoading }))));
};
exports.DeleteAttributeDialog = DeleteAttributeDialog;
exports.default = exports.DeleteAttributeDialog;
