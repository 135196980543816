"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributesBaseFilter = void 0;
var attributes_constants_1 = require("./attributes.constants");
var store_1 = require("../../../../store");
var useAttributesBaseFilter = function () {
    var CATEGORY_LABEL = (0, store_1.useProductTreeTagValue)(attributes_constants_1.NODE_CATEGORY_LABEL_TAG_KEY) || attributes_constants_1.NODE_CATEGORY_LABEL_DEFAULT;
    return {
        some: {
            label: {
                notIn: [
                    attributes_constants_1.PRODUCT_DESCRIPTION_LABEL,
                    attributes_constants_1.PRODUCT_PAGE_URL_LABEL,
                    attributes_constants_1.PRODUCT_IMAGE_LABEL,
                    CATEGORY_LABEL,
                ],
            },
        },
    };
};
exports.useAttributesBaseFilter = useAttributesBaseFilter;
