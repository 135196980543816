"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.attributeInitialValues = exports.initialAttributeItem = exports.initialValueItem = exports.AttributeFormFieldNames = void 0;
var AttributeFormFieldNames;
(function (AttributeFormFieldNames) {
    AttributeFormFieldNames["Attributes"] = "attributes";
    AttributeFormFieldNames["Values"] = "values";
    AttributeFormFieldNames["Name"] = "name";
    AttributeFormFieldNames["Description"] = "description";
    AttributeFormFieldNames["Readonly"] = "readonly";
})(AttributeFormFieldNames = exports.AttributeFormFieldNames || (exports.AttributeFormFieldNames = {}));
exports.initialValueItem = { name: '', description: '' };
exports.initialAttributeItem = { name: '', description: '', values: [] };
exports.attributeInitialValues = (_a = {},
    _a[AttributeFormFieldNames.Attributes] = [exports.initialAttributeItem],
    _a);
