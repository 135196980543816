"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAggregatedProductMeasureUnits = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var ramda_1 = require("ramda");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var hooks_1 = require("../../hooks");
var constants_1 = require("../constants");
var gql_1 = require("../gql");
// merge the new part with an already loaded
var updateQuery = function (previousResult, options) {
    var _a, _b;
    var _c = ((_a = previousResult.productTreeNode.productAggregateMeasures) !== null && _a !== void 0 ? _a : {}).edges, prevMeasures = _c === void 0 ? [] : _c;
    var productTreeNode = ((_b = options === null || options === void 0 ? void 0 : options.fetchMoreResult) !== null && _b !== void 0 ? _b : {}).productTreeNode;
    var _d = productTreeNode.productAggregateMeasures, _e = _d.edges, edges = _e === void 0 ? [] : _e, productAggregateLabels = __rest(_d, ["edges"]);
    if (!edges.length) {
        return {
            productTreeNode: __assign(__assign({}, previousResult.productTreeNode), productTreeNode),
        };
    }
    var newMeasure = edges[0].node;
    var changedMeasureIndex = prevMeasures.findIndex(function (_a) {
        var node = _a.node;
        return node.dimension === newMeasure.dimension;
    });
    var prevAttribute = prevMeasures[changedMeasureIndex];
    var changedMeasure = {
        node: __assign(__assign({}, prevAttribute.node), { units: __assign(__assign({}, newMeasure.units), { edges: __spreadArray(__spreadArray([], __read(prevAttribute.node.units.edges), false), __read(newMeasure.units.edges), false) }) }),
    };
    var newAttributes = (0, ramda_1.update)(changedMeasureIndex, changedMeasure, prevMeasures);
    return {
        productTreeNode: __assign(__assign({}, productTreeNode), { productAggregateLabels: __assign(__assign({}, productAggregateLabels), { edges: newAttributes }) }),
    };
};
var fetchMoreOptions = { updateQuery: updateQuery };
var useGetAggregatedProductMeasureUnits = function (_a) {
    var _b, _c, _d;
    var catalogId = _a.catalogId, productTreeNodeId = _a.productTreeNodeId, dimension = _a.dimension, sortingItem = _a.sortingItem, options = _a.options;
    var sort = constants_1.sortingItemToAggregatedProductMeasureUnitsSortMap[sortingItem];
    var _e = __read((0, client_1.useLazyQuery)(gql_1.GET_AGGREGATED_PRODUCT_MEASURE_UNITS, {
        notifyOnNetworkStatusChange: true,
        variables: {
            sort: sort,
            dimension: dimension,
            productTreeNodeId: productTreeNodeId !== null && productTreeNodeId !== void 0 ? productTreeNodeId : '',
            catalog: catalogId,
            first: client_constants_1.PAGE_SIZE_LARGE,
            last: null,
            before: null,
            after: null,
        },
    }), 2), getAggregatedProductMeasureUnits = _e[0], _f = _e[1], data = _f.data, error = _f.error, networkStatus = _f.networkStatus, variables = _f.variables, fetchMore = _f.fetchMore;
    // using useQuery skip: true will remove all previously uploaded data
    (0, react_1.useEffect)(function () {
        if (dimension && !(options === null || options === void 0 ? void 0 : options.skip)) {
            void getAggregatedProductMeasureUnits({ variables: { catalog: catalogId, productTreeNodeId: productTreeNodeId, dimension: dimension } });
        }
    }, [catalogId, dimension, getAggregatedProductMeasureUnits, options === null || options === void 0 ? void 0 : options.skip, productTreeNodeId]);
    var units = ((_d = (_c = (_b = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _b === void 0 ? void 0 : _b.productAggregateMeasures.edges[0]) === null || _c === void 0 ? void 0 : _c.node) !== null && _d !== void 0 ? _d : {}).units;
    var _g = units !== null && units !== void 0 ? units : {}, edges = _g.edges, pageInfo = _g.pageInfo;
    var pagination = (0, hooks_1.usePagination)({
        pageInfo: pageInfo,
        networkStatus: networkStatus,
        pageSize: client_constants_1.PAGE_SIZE_LARGE,
        fetchMore: fetchMore,
        fetchMoreOptions: fetchMoreOptions,
        lastVariables: variables,
    });
    return {
        data: edges,
        pagination: pagination,
        isLoading: (0, client_utils_1.isQueryLoadingOrRefetching)(networkStatus),
        error: error,
    };
};
exports.useGetAggregatedProductMeasureUnits = useGetAggregatedProductMeasureUnits;
