"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoriesFacetNavigation = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var localization_1 = require("../../../../localization");
var tree_navigation_1 = require("../../tree-navigation");
var store_1 = require("../../../../store");
var const_1 = require("../../../../store/const");
var utils_1 = require("../../../../utils");
var CategoriesFacetNavigation = function (_a) {
    var expandOverride = _a.expandOverride, debouncedSearchTerm = _a.debouncedSearchTerm, isSimple = _a.isSimple;
    var includedCategoriesIds = (0, store_1.useProductsIncludedCategoriesIds)();
    var excludedCategoriesIds = (0, store_1.useProductsExcludedCategoriesIds)();
    var allCategories = (0, store_1.useAllCategoriesSelector)();
    var topLevelCategories = (0, store_1.useTopLevelCategoriesSelector)();
    var categoriesMap = (0, store_1.useCategoriesMapSelector)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var changeIds = (0, react_1.useCallback)(function (selectedId, prevCategoriesIds) {
        var selectedCategoriesIds = [selectedId];
        return (0, ramda_1.all)(function (selectedCategoryId) { return prevCategoriesIds.includes(selectedCategoryId); }, selectedCategoriesIds)
            ? (0, ramda_1.without)(selectedCategoriesIds)
            : (0, ramda_1.concat)(selectedCategoriesIds);
    }, []);
    var handleSetInclude = (0, react_1.useCallback)(function (selectedId) {
        var changeIncludedCategoriesIds = changeIds(selectedId, includedCategoriesIds);
        (0, ramda_1.pipe)(changeIncludedCategoriesIds, ramda_1.uniq, store_1.productsSetIncludedCategoriesIds, dispatch)(includedCategoriesIds);
    }, [dispatch, includedCategoriesIds, changeIds]);
    var handleSetExclude = (0, react_1.useCallback)(function (selectedId) {
        var changeExcludedCategoriesIds = changeIds(selectedId, excludedCategoriesIds);
        (0, ramda_1.pipe)(changeExcludedCategoriesIds, ramda_1.uniq, store_1.productsSetExcludedCategoriesIds, dispatch)(excludedCategoriesIds);
    }, [dispatch, excludedCategoriesIds, changeIds]);
    var hierarchalTree = (0, react_1.useMemo)(function () { return (0, utils_1.getTreeData)(allCategories, function (item) { return item.title; }); }, [allCategories]);
    var parentIds = (0, react_1.useMemo)(function () { return (0, utils_1.getNodesWithChildren)(hierarchalTree); }, [hierarchalTree]);
    var getTitle = (0, react_1.useCallback)(function (_a) {
        var subCategory = _a.subCategory;
        var isCategoryUncategorized = subCategory.id === const_1.UNCATEGORIZED_ID;
        var title = isCategoryUncategorized ? t({ key: 'UNCATEGORIZED' }) : subCategory.title;
        return title;
    }, [t]);
    var getCategoryLevelChildren = (0, react_1.useCallback)(function (categories, level) {
        var childrenMap = new Map();
        __spreadArray([], __read(categories.values()), false).forEach(function (category) {
            category.subcategoryIds.forEach(function (subCategoryId) {
                var subCategory = categoriesMap[subCategoryId];
                childrenMap.set(subCategoryId, __assign(__assign({}, subCategory), { parentId: level, children: new Map(), title: getTitle({ subCategory: subCategory }) }));
            });
        });
        return childrenMap;
    }, [categoriesMap]);
    var categoryLevelsTree = (0, react_1.useMemo)(function () {
        var categoryLevelsMap = new Map();
        var categoryLevel = 1;
        var subCategories = new Map(topLevelCategories.map(function (category) { return [category.id, __assign(__assign({}, category), { parentId: "".concat(categoryLevel), children: new Map() })]; }));
        while (subCategories.size > 0) {
            var title = t({ key: 'CATEGORY_LEVEL', values: { level: categoryLevel } });
            var id = "".concat(categoryLevel);
            categoryLevelsMap.set(id, {
                title: title,
                children: subCategories,
                parentId: null,
                id: id,
            });
            categoryLevel++;
            subCategories = getCategoryLevelChildren(subCategories, "".concat(categoryLevel));
        }
        return categoryLevelsMap;
    }, [topLevelCategories, t, getCategoryLevelChildren]);
    var commonProps = {
        excluded: excludedCategoriesIds,
        included: includedCategoriesIds,
        expandOverride: expandOverride,
        setExcluded: handleSetExclude,
        setIncluded: handleSetInclude,
        debouncedSearchTerm: debouncedSearchTerm,
    };
    return isSimple ? (react_1.default.createElement(tree_navigation_1.TreeNavigation, __assign({}, commonProps, { treeData: categoryLevelsTree, parentIds: Array.from({ length: categoryLevelsTree.size }, function (_, index) { return (index + 1).toString(); }), terminalNodeInteractionOnly: true, simpleRootNode: true }))) : (react_1.default.createElement(tree_navigation_1.TreeNavigation, __assign({}, commonProps, { treeData: hierarchalTree, parentIds: parentIds })));
};
exports.CategoriesFacetNavigation = CategoriesFacetNavigation;
exports.default = exports.CategoriesFacetNavigation;
