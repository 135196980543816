"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefinementsLoadingSelector = exports.useRefinementsDataSelector = exports.useRefinementsFilterSelector = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
var refinementsFilterSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var refinements = _a.refinements;
    return refinements.refinementsFilter;
});
var useRefinementsFilterSelector = function () { return (0, react_redux_1.useSelector)(refinementsFilterSelector); };
exports.useRefinementsFilterSelector = useRefinementsFilterSelector;
var refinementsDataSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var refinements = _a.refinements;
    return refinements.data;
});
var useRefinementsDataSelector = function () { return (0, react_redux_1.useSelector)(refinementsDataSelector); };
exports.useRefinementsDataSelector = useRefinementsDataSelector;
var refinementsLoadingSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var refinements = _a.refinements;
    return refinements.isLoading;
});
var useRefinementsLoadingSelector = function () { return (0, react_redux_1.useSelector)(refinementsLoadingSelector); };
exports.useRefinementsLoadingSelector = useRefinementsLoadingSelector;
