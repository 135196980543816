"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_STATE = void 0;
var Actions = __importStar(require("../actions/types"));
exports.DEFAULT_STATE = {
    classes: {
        allIds: [],
        byId: {},
        v2TerminalIds: [],
        v2Ids: [],
        inUseTerminalNodes: [],
    },
    isRefresh: false,
    isLoading: false,
};
var taxonomyClassesSetData = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { classes: __assign(__assign({}, state.classes), payload) }));
};
var taxonomyClassesSetInUseTerminaNodes = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { classes: __assign(__assign({}, state.classes), { inUseTerminalNodes: payload }) }));
};
exports.default = (function (state, action) {
    if (state === void 0) { state = exports.DEFAULT_STATE; }
    switch (action.type) {
        case Actions.TAXONOMY_CLASSES_SET_DATA:
            return taxonomyClassesSetData(state, action);
        case Actions.TAXONOMY_CLASSES_SET_IS_LOADING:
            return __assign(__assign({}, state), { isLoading: action.payload.isLoading });
        case Actions.TAXONOMY_CLASSES_REFRESH:
            return __assign(__assign({}, state), { isRefresh: action.payload });
        case Actions.TAXONOMY_CLASSES_SET_IN_USE_TERMINAL_NODES:
            return taxonomyClassesSetInUseTerminaNodes(state, action);
        default:
            return state;
    }
});
