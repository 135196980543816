"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoadMore = void 0;
var react_1 = require("react");
var hooks_1 = require("../../hooks");
var REQUEST_MAX_TRY = 3;
var useLoadMore = function (_a) {
    var moreItemsLoading = _a.moreItemsLoading, hasNextPage = _a.hasNextPage, onLoadMore = _a.onLoadMore;
    var observer = (0, react_1.useRef)();
    var errorCounter = (0, react_1.useRef)(0);
    var addError = (0, hooks_1.useNotification)().addError;
    var handleLoadMore = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, onLoadMore()];
                case 1:
                    _a.sent();
                    errorCounter.current = 0;
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    addError(error_1.message);
                    errorCounter.current = ++errorCounter.current;
                    if (errorCounter.current <= REQUEST_MAX_TRY) {
                        void handleLoadMore();
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [addError, onLoadMore]);
    var lastElementRef = (0, react_1.useCallback)(function (node) {
        if (observer.current) {
            observer.current.disconnect();
        }
        if (moreItemsLoading || errorCounter.current >= REQUEST_MAX_TRY) {
            return;
        }
        observer.current = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting && hasNextPage) {
                void handleLoadMore();
            }
        });
        if (node && hasNextPage) {
            observer.current.observe(node);
        }
    }, [handleLoadMore, hasNextPage, moreItemsLoading]);
    return lastElementRef;
};
exports.useLoadMore = useLoadMore;
