"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortingItem = void 0;
var SortingItem;
(function (SortingItem) {
    SortingItem["SortByTextAsc"] = "sortByTextAsc";
    SortingItem["SortByTextDesc"] = "sortByTextDesc";
    SortingItem["SortByProductCountsAsc"] = "sortByProductCountsAsc";
    SortingItem["SortByProductCountsDesc"] = "sortByProductCountsDesc";
})(SortingItem = exports.SortingItem || (exports.SortingItem = {}));
