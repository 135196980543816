"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SideBarComponent = exports.ACTIVE_FILTER_TYPES = void 0;
var react_1 = __importStar(require("react"));
var debounce_1 = require("debounce");
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var settings_panel_1 = require("./settings-panel");
var side_bar_styles_1 = require("./side-bar.styles");
// this is temporary while work on EN-3049 is in progress
// when EN-3049 is complete, files in the v2 folds will replace the existing files
var v2_1 = require("../attributes/v2");
var v2_2 = require("../categories/v2");
var v2_3 = require("../taxonomy-classes/v2");
var constants_1 = require("../../../constants");
var feature_flags_1 = require("../../../feature-flags");
var hooks_1 = require("../../../hooks");
var components_1 = require("..");
var store_1 = require("../../../store");
var State = __importStar(require("../../../store/state"));
exports.ACTIVE_FILTER_TYPES = [
    State.ProductFilterActiveTypes.All,
    State.ProductFilterActiveTypes.Active,
    State.ProductFilterActiveTypes.Inactive,
];
var SideBarComponent = function (_a) {
    var classTotals = _a.classTotals, categoryTotals = _a.categoryTotals, attributeTotals = _a.attributeTotals, isProductCountsLoading = _a.isProductCountsLoading, selectedOrganization = _a.selectedOrganization, onSetOrganization = _a.onSetOrganization, activeFilter = _a.activeFilter, onChangeFilter = _a.onChangeFilter, availableRefinementsFilter = _a.availableRefinementsFilter, onRefinementsFilter = _a.onRefinementsFilter, resultsFilter = _a.resultsFilter, onChangeFilterOperator = _a.onChangeFilterOperator, isOrganizationSelected = _a.isOrganizationSelected, isCategoriesLoading = _a.isCategoriesLoading, onResetAllFilters = _a.onResetAllFilters, organizations = _a.organizations;
    var dispatch = (0, react_redux_1.useDispatch)();
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var isMultiFacetNavEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.MultiFacetNav);
    var openCategories = (0, store_1.useFacetNavigationOpenCategoriesSelector)();
    var openAttributes = (0, store_1.useFacetNavigationOpenAttributesSelector)();
    var openTaxonomyClasses = (0, store_1.useFacetNavigationOpenTaxonomyClassesSelector)();
    var debouncedSearchTerm = (0, store_1.useFacetNavigationSearchTermSelector)();
    var handleSetOpenCategories = (0, ramda_1.compose)(dispatch, store_1.facetNavigationSetOpenCategories);
    var handleSetOpenAttributes = (0, ramda_1.compose)(dispatch, store_1.facetNavigationSetOpenAttributes);
    var handleSetOpenTaxonomyClasses = (0, ramda_1.compose)(dispatch, store_1.facetNavigationSetOpenTaxonomyClasses);
    var handleSetSearchTerm = (0, ramda_1.compose)(dispatch, store_1.facetNavigationSetSearchTerm);
    var handleDebouncedSearchTerm = (0, react_1.useCallback)((0, debounce_1.debounce)(handleSetSearchTerm, constants_1.SEARCH_QUERY_UPDATE_DELAY), [dispatch]);
    return (react_1.default.createElement(side_bar_styles_1.StyledSideBar, null,
        react_1.default.createElement(settings_panel_1.SettingsPanel, { organizations: organizations, selectedOrganization: selectedOrganization, onSetOrganization: onSetOrganization, activeFilter: activeFilter, onChangeFilterOperator: onChangeFilterOperator, onSearchTermChange: handleDebouncedSearchTerm, debouncedSearchTerm: debouncedSearchTerm, availableRefinementsFilter: availableRefinementsFilter, resultsFilter: resultsFilter, onChangeFilter: onChangeFilter, onRefinementsFilter: onRefinementsFilter, resetSettingSelections: onResetAllFilters }),
        isOrganizationSelected && (isMultiFacetNavEnabled ? (react_1.default.createElement(v2_2.CategoriesSideBarSection, { isCategoriesLoading: isCategoriesLoading, debouncedSearchTerm: debouncedSearchTerm })) : (react_1.default.createElement(components_1.CategoriesContainer, { isCategoriesLoading: isCategoriesLoading, debouncedSearchTerm: debouncedSearchTerm, openCategories: openCategories, setOpenCategories: handleSetOpenCategories, isProductCountsLoading: isProductCountsLoading, categoryTotals: categoryTotals }))),
        isOrganizationSelected && (isMultiFacetNavEnabled ? (react_1.default.createElement(v2_3.TaxonomyClassesContainerv2, { debouncedSearchTerm: debouncedSearchTerm, classTotals: classTotals })) : (react_1.default.createElement(components_1.TaxonomyClassesContainer, { debouncedSearchTerm: debouncedSearchTerm, classTotals: classTotals, isProductCountsLoading: isProductCountsLoading, openTaxonomyClasses: openTaxonomyClasses, onToggleExpand: handleSetOpenTaxonomyClasses }))),
        isOrganizationSelected && (isMultiFacetNavEnabled ? (react_1.default.createElement(v2_1.AttributesFilterContainerv2, { searchTerm: debouncedSearchTerm })) : (react_1.default.createElement(components_1.AttributesFilterContainer, { searchTerm: debouncedSearchTerm, isProductCountsLoading: isProductCountsLoading, openAttributes: openAttributes, setOpenAttributes: handleSetOpenAttributes, attributeTotals: attributeTotals })))));
};
exports.SideBarComponent = SideBarComponent;
exports.default = exports.SideBarComponent;
