"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxonomyClassesContainer = void 0;
var react_1 = __importStar(require("react"));
var predicate_language_1 = require("@groupby/predicate-language");
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var taxonomy_classes_sidebar_section_component_1 = require("./taxonomy-classes-sidebar-section.component");
var constants_1 = require("../../../../constants");
var hooks_1 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var hooks_2 = require("../../../hooks");
var store_1 = require("../../../../store");
var utils_1 = require("../../../../utils");
var TaxonomyClassesContainer = function (_a) {
    var debouncedSearchTerm = _a.debouncedSearchTerm, classTotals = _a.classTotals;
    var dispatch = (0, react_redux_1.useDispatch)();
    var taxonomyClasses = (0, store_1.useClassesSelector)();
    var _b = __read((0, store_1.useSelectedOrganizationsIdsSelector)(), 1), currentOrganizationsIds = _b[0];
    var client = (0, hooks_1.useEnrichClient)();
    var addError = (0, hooks_1.useNotification)().addError;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var token = (0, hooks_1.useToken)();
    var isLoading = (0, store_1.useClassesIsLoadingSelector)();
    var refinementsData = (0, store_1.useRefinementsDataSelector)();
    var refinementsLoading = (0, store_1.useRefinementsLoadingSelector)();
    var getV2Ids = (0, hooks_2.useNodesTree)().getV2Ids;
    var getTaxonomyClasses = function () {
        var _a;
        if (currentOrganizationsIds && ((_a = refinementsData === null || refinementsData === void 0 ? void 0 : refinementsData.classIds) === null || _a === void 0 ? void 0 : _a.length)) {
            var classes = refinementsData.classIds.reduce(function (acc, id) {
                var taxonomyClass = taxonomyClasses.byId[id];
                if (!id || !taxonomyClasses.allIds.length || !(taxonomyClass === null || taxonomyClass === void 0 ? void 0 : taxonomyClass.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase())))
                    return acc;
                return __spreadArray(__spreadArray([], __read(acc), false), [__assign(__assign({}, taxonomyClass), { total: classTotals[taxonomyClass === null || taxonomyClass === void 0 ? void 0 : taxonomyClass.id] })], false);
            }, []);
            dispatch((0, store_1.taxonomyClassesSetInUseTerminalNodes)(classes));
        }
        else {
            dispatch((0, store_1.taxonomyClassesSetInUseTerminalNodes)([]));
        }
    };
    (0, react_1.useEffect)(function () {
        void getTaxonomyClasses();
    }, [debouncedSearchTerm, classTotals, refinementsData.classIds, currentOrganizationsIds, taxonomyClasses.byId, taxonomyClasses.allIds]);
    var fetchRequestTaxonomyClasses = function () { return __awaiter(void 0, void 0, void 0, function () {
        var filter, _a, resultClasses, resultTaxonomyNodes, classes, _b, v2Ids, v2TerminalIds, taxonomyClasses_1, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, 3, 4]);
                    dispatch((0, store_1.taxonomyClassesSetIsLoading)(true));
                    filter = (0, predicate_language_1.where)((0, predicate_language_1.uuid)(constants_1.TAXONOMY_CLASS_V2_ID), '@<', (0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["hierarchy"], ["hierarchy"]))));
                    return [4 /*yield*/, Promise.all([(0, utils_1.getAllData)(client.classes), (0, utils_1.getAllData)(client.taxonomyNodes, filter)])];
                case 1:
                    _a = __read.apply(void 0, [_d.sent(), 2]), resultClasses = _a[0], resultTaxonomyNodes = _a[1];
                    classes = (0, utils_1.normalize)((0, ramda_1.flatten)(resultClasses));
                    _b = __read(getV2Ids(resultTaxonomyNodes, classes.byId), 2), v2Ids = _b[0], v2TerminalIds = _b[1];
                    taxonomyClasses_1 = __assign(__assign({}, classes), { v2TerminalIds: v2TerminalIds, v2Ids: v2Ids });
                    dispatch((0, store_1.taxonomyClassesSetData)(taxonomyClasses_1));
                    return [3 /*break*/, 4];
                case 2:
                    _c = _d.sent();
                    addError(t({ key: 'TAXONOMY_CLASSES_LOADING_ERROR' }));
                    return [3 /*break*/, 4];
                case 3:
                    dispatch((0, store_1.taxonomyClassesSetIsLoading)(false));
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (token) {
            void fetchRequestTaxonomyClasses();
        }
    }, [token]);
    return (react_1.default.createElement(taxonomy_classes_sidebar_section_component_1.TaxonomyClassesSideBarSection, { isLoading: refinementsLoading || isLoading, debouncedSearchTerm: debouncedSearchTerm }));
};
exports.TaxonomyClassesContainer = TaxonomyClassesContainer;
exports.default = react_1.default.memo(exports.TaxonomyClassesContainer);
var templateObject_1;
