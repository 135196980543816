"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFacetNavigationOpenTaxonomyClassesSelector = exports.facetNavigationOpenTaxonomyClassesSelector = exports.useFacetNavigationOpenAttributesSelector = exports.facetNavigationOpenAttributesSelector = exports.useFacetNavigationOpenCategoriesSelector = exports.facetNavigationOpenCategoriesSelector = exports.useFacetNavigationSearchTermSelector = exports.facetNavigationSearchTermSelector = void 0;
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
exports.facetNavigationSearchTermSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var facetNavigationPanel = _a.facetNavigationPanel;
    return facetNavigationPanel.searchTerm;
});
var useFacetNavigationSearchTermSelector = function () { return (0, react_redux_1.useSelector)(exports.facetNavigationSearchTermSelector); };
exports.useFacetNavigationSearchTermSelector = useFacetNavigationSearchTermSelector;
exports.facetNavigationOpenCategoriesSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var facetNavigationPanel = _a.facetNavigationPanel;
    return facetNavigationPanel.openCategories;
});
var useFacetNavigationOpenCategoriesSelector = function () { return (0, react_redux_1.useSelector)(exports.facetNavigationOpenCategoriesSelector); };
exports.useFacetNavigationOpenCategoriesSelector = useFacetNavigationOpenCategoriesSelector;
exports.facetNavigationOpenAttributesSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var facetNavigationPanel = _a.facetNavigationPanel;
    return facetNavigationPanel.openAttributes;
});
var useFacetNavigationOpenAttributesSelector = function () { return (0, react_redux_1.useSelector)(exports.facetNavigationOpenAttributesSelector); };
exports.useFacetNavigationOpenAttributesSelector = useFacetNavigationOpenAttributesSelector;
exports.facetNavigationOpenTaxonomyClassesSelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var facetNavigationPanel = _a.facetNavigationPanel;
    return facetNavigationPanel.openTaxonomyClasses;
});
var useFacetNavigationOpenTaxonomyClassesSelector = function () { return (0, react_redux_1.useSelector)(exports.facetNavigationOpenTaxonomyClassesSelector); };
exports.useFacetNavigationOpenTaxonomyClassesSelector = useFacetNavigationOpenTaxonomyClassesSelector;
