"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTableColumns = void 0;
var react_1 = require("react");
var constants_1 = require("../../constants");
var localization_1 = require("../../localization");
var consolidated_list_1 = require("../components/consolidated-list");
var State = __importStar(require("../../store/state"));
var useTableColumns = function (_a) {
    var activeColumns = _a.activeColumns, _b = _a.additionalTemplate, additionalTemplate = _b === void 0 ? {} : _b;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var baseTemplate = (0, react_1.useMemo)(function () {
        var _a;
        return (_a = {},
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_ID] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_ID }), value: 'id' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.SKU_ID] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.SKU_ID }), value: 'proprietaryId' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.IMAGE] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.IMAGE }) },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.NAME] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.NAME }), value: 'title' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.DESCRIPTION] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.DESCRIPTION }), value: 'description' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_GROUP] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_GROUP }), value: 'variantGroupId' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.CREATED_DATE] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.CREATED_DATE }), value: 'createdAt' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.LAST_MODIFIED] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.LAST_MODIFIED }), value: 'updatedAt' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.CLASS] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.CLASS }), value: 'classId' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.URL] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.URL }), value: 'url' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS }), value: 'active' },
            // descoped for MVP
            // [State.PRODUCT_EDITOR_TABLE_COLUMNS.PRICE]: { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.PRICE }) },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.COMMENTS] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.COMMENTS }), value: 'totalComments' },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.CURATED_ATTRIBUTES] = {
                id: constants_1.ColumnId.CuratedAttributes,
                align: 'left',
                label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.CURATED_ATTRIBUTES }),
                filterable: true,
                CustomIcon: consolidated_list_1.AttributeLegendTooltip,
            },
            _a[State.PRODUCT_EDITOR_TABLE_COLUMNS.STANDARD_FIELDS] = { align: 'left', label: t({ key: State.PRODUCT_EDITOR_TABLE_COLUMNS.STANDARD_FIELDS }) },
            _a);
    }, [t]);
    var template = (0, react_1.useMemo)(function () { return (__assign(__assign({}, baseTemplate), additionalTemplate)); }, [additionalTemplate, baseTemplate]);
    return (0, react_1.useMemo)(function () { return (activeColumns.map(function (item) { return template[item]; })); }, [activeColumns, template]);
};
exports.useTableColumns = useTableColumns;
