"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLatest = void 0;
var react_1 = require("react");
var useLatest = function (value) {
    var ref = (0, react_1.useRef)(value);
    ref.current = value;
    return ref;
};
exports.useLatest = useLatest;
exports.default = exports.useLatest;
