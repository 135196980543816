"use strict";
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortingItemToPropName = exports.sortingItemToLocalSortMap = exports.sortingItemToStrategyAttributesSortMap = exports.sortingItemToProductAttributesSortMap = void 0;
var graphql_1 = require("../../../../__generated__/graphql");
var models_1 = require("../../../models");
var utils_1 = require("../../../utils");
exports.sortingItemToProductAttributesSortMap = (_a = {},
    _a[models_1.SortingItem.SortByTextAsc] = [{ label: graphql_1.OrderBy.Asc }],
    _a[models_1.SortingItem.SortByTextDesc] = [{ label: graphql_1.OrderBy.Desc }],
    _a[models_1.SortingItem.SortByProductCountsAsc] = [{ productCount: graphql_1.OrderBy.Asc }],
    _a[models_1.SortingItem.SortByProductCountsDesc] = [{ productCount: graphql_1.OrderBy.Desc }],
    _a);
exports.sortingItemToStrategyAttributesSortMap = (_b = {},
    _b[models_1.SortingItem.SortByTextAsc] = [{ label: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByTextDesc] = [{ label: graphql_1.OrderBy.Desc }],
    _b[models_1.SortingItem.SortByProductCountsAsc] = [{ label: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByProductCountsDesc] = [{ label: graphql_1.OrderBy.Asc }],
    _b);
exports.sortingItemToLocalSortMap = (_c = {},
    _c[models_1.SortingItem.SortByTextAsc] = (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Ascend),
    _c[models_1.SortingItem.SortByTextDesc] = (0, utils_1.sortByTextFieldCaseInsensitive)(utils_1.SortOrder.Descend),
    _c[models_1.SortingItem.SortByProductCountsAsc] = (0, utils_1.sortByNumberField)(utils_1.SortOrder.Ascend),
    _c[models_1.SortingItem.SortByProductCountsDesc] = (0, utils_1.sortByNumberField)(utils_1.SortOrder.Descend),
    _c);
exports.sortingItemToPropName = (_d = {},
    _d[models_1.SortingItem.SortByTextAsc] = 'label',
    _d[models_1.SortingItem.SortByTextDesc] = 'label',
    _d[models_1.SortingItem.SortByProductCountsAsc] = 'productCount',
    _d[models_1.SortingItem.SortByProductCountsDesc] = 'productCount',
    _d);
