"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortingItemToAggregatedProductMeasureUnitsSortMap = exports.sortingItemToAggregatedProductMeasuresSortMap = void 0;
var graphql_1 = require("../../../__generated__/graphql");
var models_1 = require("../../models");
exports.sortingItemToAggregatedProductMeasuresSortMap = (_a = {},
    _a[models_1.SortingItem.SortByTextAsc] = [{ dimension: graphql_1.OrderBy.Asc }],
    _a[models_1.SortingItem.SortByTextDesc] = [{ dimension: graphql_1.OrderBy.Desc }],
    _a[models_1.SortingItem.SortByProductCountsAsc] = [{ productCount: graphql_1.OrderBy.Asc }],
    _a[models_1.SortingItem.SortByProductCountsDesc] = [{ productCount: graphql_1.OrderBy.Desc }],
    _a);
exports.sortingItemToAggregatedProductMeasureUnitsSortMap = (_b = {},
    _b[models_1.SortingItem.SortByTextAsc] = [{ unit: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByTextDesc] = [{ unit: graphql_1.OrderBy.Desc }],
    _b[models_1.SortingItem.SortByProductCountsAsc] = [{ productCount: graphql_1.OrderBy.Asc }],
    _b[models_1.SortingItem.SortByProductCountsDesc] = [{ productCount: graphql_1.OrderBy.Desc }],
    _b);
