"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTableSort = void 0;
var react_1 = require("react");
var sort_1 = require("@groupby/enrich-sdk/sort");
var useTableSort = function () {
    var _a = __read((0, react_1.useState)([{ propertyName: 'id', direction: sort_1.SortDirection.Ascending }]), 2), sortParams = _a[0], setSortParams = _a[1];
    var handleSort = (0, react_1.useCallback)(function (propertyName) {
        var isDescending = sortParams[0].propertyName === propertyName
            && sortParams[0].direction === sort_1.SortDirection.Ascending;
        var direction = isDescending ? sort_1.SortDirection.Descending : sort_1.SortDirection.Ascending;
        setSortParams([{ propertyName: propertyName, direction: direction }]);
    }, [sortParams]);
    return { sortParams: sortParams, handleSort: handleSort };
};
exports.useTableSort = useTableSort;
exports.default = exports.useTableSort;
