"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetProducts = exports.createFilterFromAttributeNode = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var attributes_1 = require("../../attributes");
var client_constants_1 = require("../../client.constants");
var client_utils_1 = require("../../client.utils");
var pagination_1 = require("../../hooks/pagination");
var get_products_query_1 = require("../get-products.query");
var createFilterFromAttributeNode = function (_a) {
    var _b, _c, _d;
    var label = _a.label, value = _a.value, unit = _a.unit;
    var isUnknownValue = value === attributes_1.SpecialAttributeValue.Unknown;
    var filterKey = unit ? 'measures' : 'labels';
    var labelFilterKey = unit ? 'dimension' : 'label';
    var valueFilterKey = unit ? 'unit' : 'value';
    return [
        (_b = {},
            _b[filterKey] = __assign({}, isUnknownValue ? {
                none: (_c = {},
                    _c[labelFilterKey] = {
                        equals: label,
                    },
                    _c),
            } : {
                some: __assign((_d = {}, _d[labelFilterKey] = {
                    equals: label,
                }, _d[valueFilterKey] = {
                    equals: unit || value,
                }, _d), unit && value ? {
                    value: {
                        gte: value,
                        lte: value,
                    },
                } : null),
            }),
            _b),
    ];
};
exports.createFilterFromAttributeNode = createFilterFromAttributeNode;
var useGetProducts = function (_a) {
    var _b, _c, _d, _e;
    var catalogId = _a.catalogId, nodeId = _a.nodeId, attributeNode = _a.attributeNode, options = _a.options, search = _a.search, onCompleted = _a.onCompleted, productLabelFilter = _a.productLabelFilter, productMeasureFilter = _a.productMeasureFilter, _f = _a.skipMeasures, skipMeasures = _f === void 0 ? false : _f;
    var _g = (0, pagination_1.usePageSize)({ defaultPageSize: client_constants_1.PAGE_SIZE_DEFAULT }), pageSize = _g.pageSize, onPageSizeChange = _g.onPageSizeChange;
    var filter = (0, react_1.useMemo)(function () {
        var attributeFilter = attributeNode ? (0, exports.createFilterFromAttributeNode)(attributeNode) : undefined;
        if (!search && !attributeFilter) {
            return null;
        }
        return {
            product: __assign(__assign({}, attributeFilter && { attributes: attributeFilter }), search ? { name: { contains: search } } : undefined),
        };
    }, [attributeNode, search]);
    var _h = (0, client_1.useQuery)(get_products_query_1.GET_PRODUCT_TREE_NODE_PRODUCT_PRESENTATIONS, {
        skip: !catalogId || !nodeId || (options === null || options === void 0 ? void 0 : options.skip),
        variables: __assign(__assign({ catalogId: catalogId, id: nodeId }, filter && { productPresentationsFilter: filter }), { productLabelFilter: productLabelFilter, productMeasureFilter: productMeasureFilter, first: pageSize, before: null, after: null, last: null, skipMeasures: skipMeasures, sort: [
                {
                    product: {
                        lastAttributeUpdatedAt: graphql_1.OrderBy.Desc,
                    },
                },
            ] }),
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: onCompleted,
    }), data = _h.data, variables = _h.variables, previousData = _h.previousData, loading = _h.loading, error = _h.error, networkStatus = _h.networkStatus, fetchMore = _h.fetchMore;
    var productPresentations = (0, react_1.useMemo)(function () { var _a; return (_a = data === null || data === void 0 ? void 0 : data.productTreeNode) === null || _a === void 0 ? void 0 : _a.productPresentations; }, [data]);
    var _j = ((_c = (_b = previousData === null || previousData === void 0 ? void 0 : previousData.productTreeNode) === null || _b === void 0 ? void 0 : _b.productPresentations) !== null && _c !== void 0 ? _c : {}).totalCount, prevTotalCount = _j === void 0 ? 0 : _j;
    var _k = productPresentations !== null && productPresentations !== void 0 ? productPresentations : {}, _l = _k.totalCount, totalCount = _l === void 0 ? 0 : _l, pageInfo = _k.pageInfo, _m = _k.edges, edges = _m === void 0 ? [] : _m;
    var pagination = (0, pagination_1.usePagination)({
        pageInfo: pageInfo,
        networkStatus: networkStatus,
        pageSize: pageSize,
        fetchMore: fetchMore,
        lastVariables: variables,
    });
    var products = (0, react_1.useMemo)(function () { return edges.map(function (_a) {
        var node = _a.node;
        return node.product;
    }); }, [edges]);
    return {
        pagination: __assign(__assign({}, pagination), { totalCount: loading ? totalCount || prevTotalCount : totalCount, pageSize: pageSize, onPageSizeChange: onPageSizeChange }),
        isLoading: (0, client_utils_1.isQueryLoading)(networkStatus),
        error: error,
        nodeTitle: ((_e = (_d = (data !== null && data !== void 0 ? data : previousData)) === null || _d === void 0 ? void 0 : _d.productTreeNode) === null || _e === void 0 ? void 0 : _e.title) || '',
        products: products,
    };
};
exports.useGetProducts = useGetProducts;
