"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTablePersistedData = void 0;
var react_1 = require("react");
var PERSISTED_STORAGE = 'enrich:product_table_persisted_storage';
// For backward compatibility
var parsePersistedData = function (data) {
    if (data === void 0) { data = []; }
    if (!data.length) {
        return data;
    }
    return data.map(function (item) {
        if (typeof item === 'string') {
            return { label: item, isMeasure: false };
        }
        return item;
    });
};
var useTablePersistedData = function () {
    var persistedData = (0, react_1.useMemo)(function () {
        var data = JSON.parse(localStorage.getItem(PERSISTED_STORAGE) || '{}');
        var excludedColumns = data.excludedColumns, otherData = __rest(data, ["excludedColumns"]);
        return __assign(__assign({}, otherData), { excludedColumns: parsePersistedData(excludedColumns) });
    }, []);
    var setPersistedData = (0, react_1.useCallback)(function (newData) {
        localStorage.setItem(PERSISTED_STORAGE, JSON.stringify(__assign(__assign({}, persistedData), newData)));
    }, [persistedData]);
    return {
        persistedData: persistedData,
        setPersistedData: setPersistedData,
    };
};
exports.useTablePersistedData = useTablePersistedData;
