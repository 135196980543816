"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilter = void 0;
var react_1 = require("react");
var predicate_language_1 = require("@groupby/predicate-language");
var react_router_1 = require("react-router");
var use_filter_utils_1 = require("./use-filter.utils");
var hooks_1 = require("./");
var use_filter_utils_2 = require("./use-filter.utils");
var router_1 = require("../../router");
var selectors_1 = require("../../store/selectors");
var State = __importStar(require("../../store/state"));
var useFilter = function (_a) {
    var _b = _a.activeTab, activeTab = _b === void 0 ? 2 /* All */ : _b, _c = _a.searchFilter, searchFilter = _c === void 0 ? new predicate_language_1.PredicateBuilder() : _c;
    var enrichDrawerRoutes = (0, router_1.getEnrichDrawerRoutes)();
    var pathname = (0, react_router_1.useLocation)().pathname;
    var includedCategoriesIds = (0, selectors_1.useProductsIncludedCategoriesIds)();
    var excludedCategoriesIds = (0, selectors_1.useProductsExcludedCategoriesIds)();
    var includedAttributesIds = (0, selectors_1.useProductsIncludedAttributesIds)();
    var excludedAttributesIds = (0, selectors_1.useProductsExcludedAttributesIds)();
    var selectedOrganizations = (0, selectors_1.useSelectedOrganizationsSelector)();
    var selectedOrganizationsIds = (0, selectors_1.useSelectedOrganizationsIdsSelector)();
    var includedTaxonomyClassIds = (0, selectors_1.useProductsIncludedTaxonomyClassIds)();
    var excludedTaxonomyClassIds = (0, selectors_1.useProductsExcludedTaxonomyClassIds)();
    var productActiveFilter = (0, selectors_1.useProductsFilterActive)();
    var productActiveOperator = (0, selectors_1.useProductsFilterOperator)();
    var advancedFilter = (0, hooks_1.useViewerTableFilter)().advancedFilter;
    var filterRefinements = (0, react_1.useMemo)(function () {
        var logicalOperator = (0, use_filter_utils_1.getLogicalOperator)(productActiveOperator);
        var inclusionFilter = new predicate_language_1.PredicateBuilder();
        inclusionFilter[logicalOperator]((0, use_filter_utils_2.createCategoryInclusionsFilter)(includedCategoriesIds, logicalOperator));
        inclusionFilter[logicalOperator]((0, use_filter_utils_2.createClassesInclusionsFilter)(includedTaxonomyClassIds, logicalOperator));
        inclusionFilter[logicalOperator]((0, use_filter_utils_2.createAttributeInclusionsFilter)(includedAttributesIds, logicalOperator));
        var exclusionFilter = new predicate_language_1.PredicateBuilder();
        exclusionFilter.and((0, use_filter_utils_2.createCategoryExclusionsFilter)(excludedCategoriesIds));
        exclusionFilter.and((0, use_filter_utils_2.createClassesExclusionsFilter)(excludedTaxonomyClassIds));
        exclusionFilter.and((0, use_filter_utils_2.createAttributeExclusionsFilter)(excludedAttributesIds));
        return (0, predicate_language_1.where)(inclusionFilter).and((0, predicate_language_1.where)(exclusionFilter));
    }, [
        excludedAttributesIds,
        excludedCategoriesIds,
        excludedTaxonomyClassIds,
        includedAttributesIds,
        includedCategoriesIds,
        includedTaxonomyClassIds,
        productActiveOperator
    ]);
    var getFilters = (0, react_1.useCallback)(function () {
        var filter = new predicate_language_1.PredicateBuilder();
        if (productActiveFilter !== State.ProductFilterActiveTypes.All) {
            filter.and((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["active"], ["active"]))), '==', productActiveFilter === State.ProductFilterActiveTypes.Active));
        }
        if (searchFilter.expressionTree.children.length) {
            filter.and((0, predicate_language_1.where)(searchFilter));
        }
        if (advancedFilter.expressionTree.children.length && pathname === enrichDrawerRoutes.viewer.path) {
            filter.and((0, predicate_language_1.where)(advancedFilter));
        }
        var classifiedFilter = (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["classId"], ["classId"]))), '!=', null);
        var notClassifiedFilter = (0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["classId"], ["classId"]))), '==', null);
        filter.and(filterRefinements);
        filter.and((0, predicate_language_1.where)((0, predicate_language_1.f)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["organizationId"], ["organizationId"]))), '@<', selectedOrganizations.map(function (_a) {
            var id = _a.id;
            return (0, predicate_language_1.uuid)(id);
        })));
        return {
            filter: filter.expressionTree.children.length ? filter : undefined,
            classifiedFilter: (0, predicate_language_1.where)(classifiedFilter).and(filter),
            unclassifiedFilter: notClassifiedFilter.and(filter),
        };
    }, [productActiveFilter, searchFilter, selectedOrganizationsIds, filterRefinements, advancedFilter]);
    var activeFilter = (0, react_1.useMemo)(function () {
        var _a = getFilters(), filter = _a.filter, classifiedFilter = _a.classifiedFilter, unclassifiedFilter = _a.unclassifiedFilter;
        switch (activeTab) {
            case 0 /* Unclassified */:
                return unclassifiedFilter;
            case 1 /* Classified */:
                return classifiedFilter;
            case 2 /* All */:
                return filter;
            default:
                return undefined;
        }
    }, [activeTab, getFilters]);
    return { activeFilter: activeFilter, getFilters: getFilters };
};
exports.useFilter = useFilter;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
