"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToken = void 0;
var react_1 = require("react");
var context_1 = require("../context");
var useToken = function () {
    var useAuthToken = (0, react_1.useContext)(context_1.EnrichContext).useAuthToken;
    var token = useAuthToken();
    return token;
};
exports.useToken = useToken;
exports.default = exports.useToken;
