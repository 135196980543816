"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionsWithOtherNodesDialog = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var actions_with_other_nodes_styles_1 = require("./actions-with-other-nodes.styles");
var action_dialog_styles_1 = require("../../../../../generic/action-dialog/action-dialog.styles");
var product_tree_node_list_1 = require("../product-tree-node-list");
var components_1 = require("../../../../../components");
var generic_1 = require("../../../../../generic");
var localization_1 = require("../../../../../../localization");
var ActionsWithOtherNodesDialog = function (_a) {
    var title = _a.title, message = _a.message, productTreeNodeFilter = _a.productTreeNodeFilter, successSingleActionCompleteTitle = _a.successSingleActionCompleteTitle, error = _a.error, isLoading = _a.isLoading, labelsFilter = _a.labelsFilter, renderTreeNodeSecondaryText = _a.renderTreeNodeSecondaryText, onSubmit = _a.onSubmit, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)([]), 2), selectedTreeNodePaths = _b[0], setSelectedTreeNodePaths = _b[1];
    var handleSubmit = function () { return onSubmit(selectedTreeNodePaths); };
    return (react_1.default.createElement(action_dialog_styles_1.StyledActionDialog, { open: true, title: title, proceedButtonProps: {
            error: error,
            disabled: !selectedTreeNodePaths.length || isLoading,
            title: error ? t({ key: 'DELETE' }) : t({ key: 'ADD' }),
            onClick: handleSubmit,
            endIcon: isLoading && react_1.default.createElement(generic_1.LoadingIndicator, null),
        }, cancelButtonProps: { title: t({ key: 'SKIP' }), color: 'default', onClick: onClose } },
        react_1.default.createElement(actions_with_other_nodes_styles_1.StyledDeleteDialogContent, null,
            react_1.default.createElement(components_1.Alert, { severity: "success", variant: "outlined" }, successSingleActionCompleteTitle),
            message && react_1.default.createElement(ui_components_1.Typography, { variant: "caption", color: "textSecondary" }, message),
            react_1.default.createElement(product_tree_node_list_1.ProductTreeNodeList, { filter: productTreeNodeFilter, labelsFilter: labelsFilter, selectedItems: selectedTreeNodePaths, onSelect: setSelectedTreeNodePaths, renderSecondaryTitleText: renderTreeNodeSecondaryText }))));
};
exports.ActionsWithOtherNodesDialog = ActionsWithOtherNodesDialog;
exports.default = exports.ActionsWithOtherNodesDialog;
