"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT = void 0;
var Actions = __importStar(require("../actions/types"));
var const_1 = require("../const");
exports.DEFAULT = {
    categoriesMap: {},
    topLevelCategoryIds: [],
    categoryGroups: [],
    allCategories: [],
};
var categoriesSetCategories = function (state, categories) {
    categories.push({
        createdAt: null,
        id: const_1.UNCATEGORIZED_ID,
        organizationId: null,
        parentId: null,
        proprietaryId: null,
        title: 'Uncategorized',
        updatedAt: null,
    });
    var topLevelCategoryIds = categories.filter(function (category) { return !category.parentId; }).map(function (category) { return category.id; });
    var categoriesMap = categories.reduce(function (acc, category) {
        acc[category.id] = __assign(__assign({}, category), { subcategoryIds: [] });
        return acc;
    }, {});
    // fill subcategoryIds
    // better performance than put it in reduce
    categories.forEach(function (category) {
        var _a;
        (_a = categoriesMap[category.parentId]) === null || _a === void 0 ? void 0 : _a.subcategoryIds.push(category.id);
    });
    return __assign(__assign({}, state), { topLevelCategoryIds: topLevelCategoryIds, categoriesMap: categoriesMap, allCategories: categories });
};
var categoriesSetCategoryGroups = function (state, categoryGroups) { return (__assign(__assign({}, state), { categoryGroups: __spreadArray([], __read(categoryGroups), false) })); };
exports.default = (function (state, action) {
    if (state === void 0) { state = exports.DEFAULT; }
    switch (action.type) {
        case Actions.CATEGORIES_SET_CATEGORIES:
            return categoriesSetCategories(state, action.payload.categories);
        case Actions.CATEGORIES_RESET_DATA:
            return __assign({}, exports.DEFAULT);
        case Actions.CATEGORIES_SET_CATEGORY_GROUPS:
            return categoriesSetCategoryGroups(state, action.payload.categoryGroups);
        default:
            return state;
    }
});
