"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormalAttributesProvider = exports.useFormalAttributes = exports.FormalAttributesContext = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var react_redux_1 = require("react-redux");
var formal_attributes_client_1 = require("./formal-attributes.client");
var formal_attributes_utils_1 = require("./formal-attributes.utils");
var hooks_1 = require("../../hooks");
var store_1 = require("../../store");
exports.FormalAttributesContext = react_1.default.createContext({
    loadFormalAttributes: function () { },
    isLoading: false,
    getAttributeName: function () { return ''; },
    getAttributeValueName: function () { return ''; },
});
var useFormalAttributes = function () { return react_1.default.useContext(exports.FormalAttributesContext); };
exports.useFormalAttributes = useFormalAttributes;
var MAX_PAGE_SIZE = 100;
var FormalAttributesProvider = function (_a) {
    var children = _a.children;
    var client = (0, hooks_1.useEnrichClient)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var formalAttributesMap = (0, store_1.useFormalAttributesMapSelector)();
    var formalAttributeValuesMap = (0, store_1.useFormalAttributeValuesMapSelector)();
    var _b = __read((0, react_1.useState)(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    // to prevent a large number of requests when adding new parts of attribute names/values ids
    var lockRequest = (0, react_1.useRef)(false);
    var _c = __read((0, react_1.useState)(undefined), 2), attributeNameIds = _c[0], setAttributeNameIds = _c[1];
    var _d = __read((0, react_1.useState)(undefined), 2), attributeValueIds = _d[0], setAttributeValueIds = _d[1];
    var loadFormalAttributes = (0, react_1.useCallback)(function (attributes, _a) {
        var _b = _a === void 0 ? {} : _a, highPriority = _b.highPriority;
        var uniqueAttributeNameIds = new Set();
        var uniqueAttributeValueIds = new Set();
        attributes.forEach(function (_a) {
            var attributeId = _a.attributeId, valueId = _a.valueId, valueName = _a.valueName;
            if (attributeId) {
                uniqueAttributeNameIds.add(attributeId);
            }
            if (valueId && !valueName) {
                uniqueAttributeValueIds.add(valueId);
            }
        });
        setAttributeNameIds((0, formal_attributes_utils_1.addIdsToLoadQueue)(Array.from(uniqueAttributeNameIds), highPriority));
        setAttributeValueIds((0, formal_attributes_utils_1.addIdsToLoadQueue)(Array.from(uniqueAttributeValueIds), highPriority));
    }, []);
    var attributeNameIdsToFetch = (0, react_1.useMemo)(function () {
        var missingAttributeIds = (0, ramda_1.without)(__spreadArray([], __read(formalAttributesMap.keys()), false), attributeNameIds !== null && attributeNameIds !== void 0 ? attributeNameIds : []);
        return missingAttributeIds.slice(0, MAX_PAGE_SIZE);
    }, [attributeNameIds, formalAttributesMap]);
    var attributeValueIdsToFetch = (0, react_1.useMemo)(function () {
        var missingAttributeValueIds = (0, ramda_1.without)(__spreadArray([], __read(formalAttributeValuesMap.keys()), false), attributeValueIds !== null && attributeValueIds !== void 0 ? attributeValueIds : []);
        return missingAttributeValueIds.slice(0, MAX_PAGE_SIZE);
    }, [attributeValueIds, formalAttributeValuesMap]);
    (0, react_1.useEffect)(function () {
        var loadAttributes = function (attributeNameIdsToFetch, attributeValueIdsToFetch) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, formalAttributeNames, _c, formalAttributeValues;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        setIsLoading(true);
                        lockRequest.current = true;
                        return [4 /*yield*/, Promise.all([
                                attributeNameIdsToFetch.length ? (0, formal_attributes_client_1.getFormalAttributeNames)(client, attributeNameIdsToFetch) : undefined,
                                attributeValueIdsToFetch.length ? (0, formal_attributes_client_1.getFormalAttributeValues)(client, attributeValueIdsToFetch) : undefined,
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_d.sent(), 2]), _b = _a[0], formalAttributeNames = _b === void 0 ? [] : _b, _c = _a[1], formalAttributeValues = _c === void 0 ? [] : _c;
                        dispatch((0, store_1.attributesSetFormalAttributes)(formalAttributeNames));
                        dispatch((0, store_1.attributesSetFormalAttributeValues)(formalAttributeValues));
                        lockRequest.current = false;
                        return [2 /*return*/];
                }
            });
        }); };
        if (!lockRequest.current) {
            if (attributeNameIdsToFetch.length || attributeValueIdsToFetch.length) {
                void loadAttributes(attributeNameIdsToFetch, attributeValueIdsToFetch);
            }
            else {
                setIsLoading(false);
            }
        }
    }, [client, dispatch, formalAttributesMap, formalAttributeValuesMap, attributeNameIdsToFetch, attributeValueIdsToFetch]);
    var getAttributeName = (0, react_1.useCallback)(function (attributeId) { var _a, _b; return (_b = (_a = formalAttributesMap.get(attributeId)) === null || _a === void 0 ? void 0 : _a.displayName) !== null && _b !== void 0 ? _b : ''; }, [formalAttributesMap]);
    var getAttributeValueName = (0, react_1.useCallback)(function (valueId) { var _a, _b; return (_b = (_a = formalAttributeValuesMap.get(valueId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : ''; }, [formalAttributeValuesMap]);
    return (react_1.default.createElement(exports.FormalAttributesContext.Provider, { value: {
            isLoading: isLoading,
            loadFormalAttributes: loadFormalAttributes,
            getAttributeName: getAttributeName,
            getAttributeValueName: getAttributeValueName,
        } }, children));
};
exports.FormalAttributesProvider = FormalAttributesProvider;
exports.default = exports.FormalAttributesProvider;
