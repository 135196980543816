"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefetchQueries = exports.QueryType = void 0;
var client_1 = require("@apollo/client");
var utilities_1 = require("@apollo/client/utilities");
var get_product_aggregate_attributes_query_1 = require("../attributes/get-product-aggregate-attributes.query");
var get_strategy_attributes_query_1 = require("../attributes/get-strategy-attributes.query");
var measures_1 = require("../measures");
var get_product_presentation_query_1 = require("../product/get-product-presentation.query");
var get_products_query_1 = require("../product/get-products.query");
var get_product_aggregate_values_query_1 = require("../values/get-product-aggregate-values.query");
var get_strategy_values_query_1 = require("../values/get-strategy-values.query");
var QueryType;
(function (QueryType) {
    QueryType[QueryType["StrategyAttributes"] = 0] = "StrategyAttributes";
    QueryType[QueryType["ProductAttributes"] = 1] = "ProductAttributes";
    QueryType[QueryType["Products"] = 2] = "Products";
    QueryType[QueryType["ProductDetails"] = 3] = "ProductDetails";
})(QueryType = exports.QueryType || (exports.QueryType = {}));
var QueryTypeToDocumentNodeMap = (_a = {},
    _a[QueryType.StrategyAttributes] = [get_strategy_attributes_query_1.GET_STRATEGY_ATTRIBUTES],
    _a[QueryType.ProductAttributes] = [get_product_aggregate_attributes_query_1.GET_PRODUCT_AGGREGATE_ATTRIBUTES, measures_1.GET_AGGREGATED_PRODUCT_MEASURES],
    _a[QueryType.Products] = [get_products_query_1.GET_PRODUCT_TREE_NODE_PRODUCT_PRESENTATIONS],
    _a[QueryType.ProductDetails] = [get_product_presentation_query_1.GET_PRODUCT_TREE_NODE_PRESENTATION],
    _a);
var updateCache = function (selectedTreeNodeId) { return function (cache) {
    cache.evict({ id: "ProductTreeNode:".concat(selectedTreeNodeId), fieldName: 'productAggregateLabels' });
    cache.evict({ id: "ProductTreeNode:".concat(selectedTreeNodeId), fieldName: 'productAggregateMeasures' });
    cache.gc();
}; };
var getQueryList = function (queries) { return queries.flatMap(function (queryType) { return QueryTypeToDocumentNodeMap[queryType]; }); };
var useRefetchQueries = function () {
    var client = (0, client_1.useApolloClient)();
    // refetch strategy attributes and evict cache for strategy values without refetching
    var refetchStrategyAttributes = function (selectedTreeNodeId) { return client.refetchQueries({
        include: getQueryList([QueryType.StrategyAttributes, QueryType.Products]),
        updateCache: function (cache) {
            /*
              remove labelValidation field from normalized object ProductTreeNode:id in cache.
              All related queries will be reloaded
            */
            cache.evict({ id: "ProductTreeNode:".concat(selectedTreeNodeId), fieldName: 'labelValidation' });
            // remove all references for this field
            cache.gc();
        },
        onQueryUpdated: function (observableQuery) {
            // prevent values to be reloaded
            if (observableQuery.queryName === (0, utilities_1.getOperationName)(get_strategy_values_query_1.GET_STRATEGY_VALUES)) {
                return false;
            }
            return observableQuery.refetch();
        },
    }); };
    var onQueryUpdated = function (observableQuery) {
        if (observableQuery.queryName === (0, utilities_1.getOperationName)(get_product_aggregate_values_query_1.GET_PRODUCT_AGGREGATE_VALUES)) {
            return false;
        }
        return observableQuery.refetch();
    };
    // refetch products and product attributes and evict cache for product values without refetching
    var refetchProductAttributes = function (selectedTreeNodeId) { return client.refetchQueries({
        include: getQueryList([QueryType.ProductAttributes, QueryType.Products, QueryType.ProductDetails]),
        updateCache: updateCache(selectedTreeNodeId),
        onQueryUpdated: onQueryUpdated,
    }); };
    // refetch product details and product attributes and evict cache for product values without refetching
    var refetchProductDetailsAttributes = function (selectedTreeNodeId) { return client.refetchQueries({
        include: getQueryList([QueryType.ProductAttributes, QueryType.ProductDetails]),
        updateCache: updateCache(selectedTreeNodeId),
        onQueryUpdated: onQueryUpdated,
    }); };
    return {
        refetchStrategyAttributes: refetchStrategyAttributes,
        refetchProductAttributes: refetchProductAttributes,
        refetchProductDetailsAttributes: refetchProductDetailsAttributes,
    };
};
exports.useRefetchQueries = useRefetchQueries;
