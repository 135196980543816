"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCTS_SET_ADVANCED_VIEWER_FILTER = exports.PRODUCTS_SET_TOTAL = exports.PRODUCTS_CLEAR_SELECTED = exports.PRODUCTS_SET_SELECTED = exports.PRODUCTS_CLEAR_ALL_SELECTED = exports.PRODUCTS_ADD_SELECTED_IDS = exports.PRODUCTS_ADD_SELECTED_ID = exports.PRODUCTS_SET_IS_EXCLUSION_MODE_ON = exports.PRODUCTS_CLEAR_EXCLUDED_TAXONOMY_CLASS_IDS = exports.PRODUCTS_CLEAR_INCLUDED_TAXONOMY_CLASS_IDS = exports.CLEAR_NOT_EXIST_TAXONOMY_CLASSES = exports.PRODUCTS_SET_EXCLUDED_TAXONOMY_CLASS_IDS = exports.PRODUCTS_SET_INCLUDED_TAXONOMY_CLASS_IDS = exports.PRODUCTS_CLEAR_EXCLUDED_ATTRIBUTES_IDS = exports.PRODUCTS_CLEAR_INCLUDED_ATTRIBUTES_IDS = exports.PRODUCTS_SET_EXCLUDED_ATTRIBUTES_IDS = exports.PRODUCTS_SET_INCLUDED_ATTRIBUTES_IDS = exports.PRODUCTS_SET_EXCLUDED_CATEGORIES_IDS = exports.PRODUCTS_SET_INCLUDED_CATEGORIES_IDS = exports.PRODUCTS_SET_FILTER_OPERATOR = exports.PRODUCTS_SET_FILTER_ACTIVE = void 0;
var const_1 = require("../../const");
exports.PRODUCTS_SET_FILTER_ACTIVE = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_FILTER_ACTIVE");
exports.PRODUCTS_SET_FILTER_OPERATOR = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_FILTER_OPERATOR");
exports.PRODUCTS_SET_INCLUDED_CATEGORIES_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_INCLUDED_CATEGORIES_IDS");
exports.PRODUCTS_SET_EXCLUDED_CATEGORIES_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_EXCLUDED_CATEGORIES_IDS");
exports.PRODUCTS_SET_INCLUDED_ATTRIBUTES_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_INCLUDED_ATTRIBUTES_IDS");
exports.PRODUCTS_SET_EXCLUDED_ATTRIBUTES_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_EXCLUDED_ATTRIBUTES_IDS");
exports.PRODUCTS_CLEAR_INCLUDED_ATTRIBUTES_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_CLEAR_INCLUDED_ATTRIBUTES_IDS");
exports.PRODUCTS_CLEAR_EXCLUDED_ATTRIBUTES_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_CLEAR_EXCLUDED_ATTRIBUTES_IDS");
exports.PRODUCTS_SET_INCLUDED_TAXONOMY_CLASS_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_INCLUDED_TAXONOMY_CLASS_IDS");
exports.PRODUCTS_SET_EXCLUDED_TAXONOMY_CLASS_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_EXCLUDED_TAXONOMY_CLASS_IDS");
exports.CLEAR_NOT_EXIST_TAXONOMY_CLASSES = "".concat(const_1.PREFIX_ENRICH, "CLEAR_NOT_EXIST_TAXONOMY_CLASSES");
exports.PRODUCTS_CLEAR_INCLUDED_TAXONOMY_CLASS_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_CLEAR_INCLUDED_TAXONOMY_CLASS_IDS");
exports.PRODUCTS_CLEAR_EXCLUDED_TAXONOMY_CLASS_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_CLEAR_EXCLUDED_TAXONOMY_CLASS_IDS");
exports.PRODUCTS_SET_IS_EXCLUSION_MODE_ON = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_IS_EXCLUSION_MODE_ON");
exports.PRODUCTS_ADD_SELECTED_ID = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_ADD_SELECTED_ID");
exports.PRODUCTS_ADD_SELECTED_IDS = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_ADD_SELECTED_IDS");
exports.PRODUCTS_CLEAR_ALL_SELECTED = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_CLEAR_ALL_SELECTED");
exports.PRODUCTS_SET_SELECTED = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_SELECTED");
exports.PRODUCTS_CLEAR_SELECTED = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_CLEAR_SELECTED");
exports.PRODUCTS_SET_TOTAL = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_TOTAL");
exports.PRODUCTS_SET_ADVANCED_VIEWER_FILTER = "".concat(const_1.PREFIX_ENRICH, "PRODUCTS_SET_ADVANCED_VIEWER_FILTER");
