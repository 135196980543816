"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductsPreview = void 0;
var react_1 = __importStar(require("react"));
var products_preview_styles_1 = require("./products-preview.styles");
var product_grid_1 = require("../product-grid");
var product_1 = require("../../../client/product");
var components_1 = require("../../../components");
var feature_flags_1 = require("../../../../feature-flags");
var generic_1 = require("../../../../generic");
var hooks_1 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var store_1 = require("../../../../store");
var ProductsPreview = function (_a) {
    var activeProductId = _a.activeProductId, isSelectable = _a.isSelectable, search = _a.search, selectedAttributeNode = _a.selectedAttributeNode, onCompleted = _a.onCompleted, checkItemSelected = _a.checkItemSelected, onToggleSelection = _a.onToggleSelection, onActiveProductIdChange = _a.onActiveProductIdChange, setProducts = _a.setProducts;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var isMeasureEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.Measures);
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _b = (0, product_1.useGetProducts)({
        catalogId: selectedCatalogId,
        nodeId: selectedStrategyNodeId,
        attributeNode: selectedAttributeNode,
        search: search,
        skipMeasures: !isMeasureEnabled,
        onCompleted: onCompleted,
    }), products = _b.products, pagination = _b.pagination, isLoading = _b.isLoading, error = _b.error;
    var pageNumber = pagination.pageNumber, pageSize = pagination.pageSize, totalCount = pagination.totalCount, onPageChange = pagination.onPageChange, onPageSizeChange = pagination.onPageSizeChange;
    // TODO: remove this when we have a better way to handle this, probably lifting the state up
    (0, react_1.useEffect)(function () {
        if (products === null || products === void 0 ? void 0 : products.length)
            setProducts(products);
    }, [products, setProducts]);
    var showDataContent = !isLoading && !error && totalCount > 0;
    return (react_1.default.createElement(products_preview_styles_1.StyledProductsPreviewContainer, null,
        isLoading && react_1.default.createElement(components_1.PreviewSkeleton, { className: "products-preview-section-loader", contrast: true }),
        !isLoading && error && react_1.default.createElement(products_preview_styles_1.StyledErrorAlert, { severity: "error" }, error === null || error === void 0 ? void 0 : error.message),
        !totalCount && !isLoading && !error && react_1.default.createElement(generic_1.NoDataFallback, { className: "products-preview-section-no-data", title: t({ key: 'NO_PRODUCTS' }) }),
        showDataContent && (react_1.default.createElement(product_grid_1.ProductGrid, { products: products, selectedProductId: activeProductId, onSelectProduct: onActiveProductIdChange, isSelectable: isSelectable, checkItemSelected: checkItemSelected, onToggleSelection: onToggleSelection })),
        react_1.default.createElement(product_grid_1.PreviewPanelPagination, { itemsCount: totalCount, pageNumber: pageNumber, pageSize: pageSize, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange })));
};
exports.ProductsPreview = ProductsPreview;
exports.default = exports.ProductsPreview;
