"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StrategyAttributesContainer = void 0;
var react_1 = __importStar(require("react"));
var strategy_attributes_component_1 = require("./strategy-attributes.component");
var strategy_attributes_styles_1 = require("./strategy-attributes.styles");
var attributes_1 = require("../../client/attributes");
var components_1 = require("../../components");
var confirmation_alert_1 = require("../../components/confirmation-alert");
var context_1 = require("../../context");
var models_1 = require("../../models");
var generic_1 = require("../../../generic");
var localization_1 = require("../../../localization");
var store_1 = require("../../../store");
var StrategyAttributesContainer = function (_a) {
    var selectedAttributeNode = _a.selectedAttributeNode, onSelectAttributeNode = _a.onSelectAttributeNode, onReEnrich = _a.onReEnrich;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var _b = __read((0, react_1.useState)(models_1.SortingItem.SortByTextAsc), 2), sortingItem = _b[0], setSortingItem = _b[1];
    var _c = (0, attributes_1.useGetAggregateAttributes)({
        catalogId: selectedCatalogId,
        productTreeNodeId: selectedStrategyNodeId,
        sortingItem: sortingItem,
    }), attributes = _c.attributes, treeNodeTitle = _c.treeNodeTitle, loading = _c.loading, moreItemsLoading = _c.moreItemsLoading, error = _c.error, hasNextPage = _c.hasNextPage, fetchMore = _c.fetchMore;
    if (loading) {
        return react_1.default.createElement(components_1.PreviewSkeleton, { contrast: true });
    }
    if (error) {
        return react_1.default.createElement(strategy_attributes_styles_1.StyledErrorAlert, { severity: "error" }, error.message);
    }
    return (react_1.default.createElement(context_1.CheckedAttributesProvider, null,
        react_1.default.createElement(confirmation_alert_1.ConfirmationAlertProvider, null,
            react_1.default.createElement(strategy_attributes_component_1.StrategyAttributes, { sortingItem: sortingItem, moreItemsLoading: moreItemsLoading, hasNextPage: hasNextPage, onLoadMore: fetchMore, attributes: attributes, noDataFallback: !attributes.length && react_1.default.createElement(generic_1.NoDataFallback, { title: t({ key: 'NO_DATA_AVAILABLE' }) }), selectedStrategyNodeTitle: treeNodeTitle, selectedAttributeNode: selectedAttributeNode, onSelectAttributeNode: onSelectAttributeNode, onSort: setSortingItem, onReEnrich: onReEnrich }))));
};
exports.StrategyAttributesContainer = StrategyAttributesContainer;
exports.default = exports.StrategyAttributesContainer;
