"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTreeNodesData = exports.buildFilterToExcludeValues = exports.buildFilterToExcludeAttributes = exports.buildFilterToIncludeFilterItems = void 0;
var store_1 = require("../../../../store");
var buildFilterToIncludeFilterItems = function (filterItems) {
    var validators = Object.keys(filterItems).map(function (attributeId) { return ({
        some: __assign({ label: {
                equals: attributeId,
            } }, (filterItems[attributeId].length > 0 && {
            values: {
                hasSome: filterItems[attributeId],
            },
        })),
    }); });
    return {
        validators: validators,
    };
};
exports.buildFilterToIncludeFilterItems = buildFilterToIncludeFilterItems;
var buildFilterToExcludeAttributes = function (filterItems) {
    var validators = Object.keys(filterItems).map(function (attributeId) { return ({
        none: {
            label: {
                equals: attributeId,
            },
        },
    }); });
    return {
        validators: validators,
    };
};
exports.buildFilterToExcludeAttributes = buildFilterToExcludeAttributes;
// find tree nodes with the specified attribute that do not contain the selected values
var buildFilterToExcludeValues = function (filterItems) {
    var validators = Object.keys(filterItems).map(function (attributeId) { return ({
        some: {
            label: {
                equals: attributeId,
            },
        },
        none: {
            values: {
                hasSome: filterItems[attributeId],
            },
        },
    }); });
    return {
        validators: validators,
    };
};
exports.buildFilterToExcludeValues = buildFilterToExcludeValues;
var formatTreeNodesData = function (productTreeNodes) { return productTreeNodes.map(function (_a) {
    var ancestors = _a.ancestors, rest = __rest(_a, ["ancestors"]);
    var pathIds = ancestors.edges.map(function (_a) {
        var node = _a.node;
        return node.ancestor.id;
    });
    return __assign(__assign({}, rest), { ancestors: ancestors, path: pathIds.join(store_1.STRATEGY_NODE_PATH_SEPARATOR) });
}); };
exports.formatTreeNodesData = formatTreeNodesData;
