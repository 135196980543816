"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadBox = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var material_1 = require("@mui/material");
var upload_box_styles_1 = require("./upload-box.styles");
var icons_1 = require("../../../icons");
var localization_1 = require("../../../localization");
var UploadBox = function (_a) {
    var _b, _c;
    var id = _a.id, fileType = _a.fileType, disabled = _a.disabled, onChange = _a.onChange;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var ref = (0, react_1.useRef)();
    var handleChange = function (e) {
        var _a;
        if ((_a = e.target.files) === null || _a === void 0 ? void 0 : _a.length) {
            // TODO: allow the user to upload more than one file
            var file = e.target.files[0];
            // TODO: allow the user to specify a Catalog name
            var newItem = { id: "".concat(Date.now(), "-").concat(file.name), file: file };
            onChange(newItem);
        }
    };
    var fileName = (_c = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.files[0]) === null || _c === void 0 ? void 0 : _c.name;
    return (react_1.default.createElement(upload_box_styles_1.StyledUploadBoxContainer, null,
        react_1.default.createElement(icons_1.CSVDocumentIcon, { fill: ui_components_1.baseColors.primaryTeal100, stroke: ui_components_1.baseColors.primaryGreen300 }),
        react_1.default.createElement(material_1.FormControl, { className: "upload-button-container", component: "div" },
            react_1.default.createElement(ui_components_1.Typography, { variant: "body2", color: "textSecondary" }, fileName),
            react_1.default.createElement(material_1.FormLabel, { className: "upload-button-label", htmlFor: id },
                react_1.default.createElement(upload_box_styles_1.StyledButton, { variant: "outlined", rounded: true, title: t({ key: fileName ? 'REPLACE_FILE' : 'CHOOSE_FILE' }) })),
            react_1.default.createElement("input", { ref: ref, type: "file", className: "file-upload-input", accept: fileType, id: id, disabled: disabled, onChange: handleChange }))));
};
exports.UploadBox = UploadBox;
exports.default = exports.UploadBox;
