"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxonomyClassesSideBarSection = exports.default = void 0;
var react_1 = __importStar(require("react"));
var taxonomy_classes_facet_nav_component_1 = require("./taxonomy-classes-facet-nav.component");
var common_1 = require("../../common");
var tree_navigation_component_1 = require("../../tree-navigation/tree-navigation.component");
var generic_1 = require("../../../../generic");
var localization_1 = require("../../../../localization");
// this is temporary while work on EN-3049 is in progress
// when EN-3049 is complete, files in the v2 folder will replace the existing side-bar files
var v2_1 = require("../../side-bar/sidebar-section/v2");
var sidebar_section_styles_1 = require("../../side-bar/sidebar-section/v2/sidebar-section.styles");
var selectors_1 = require("../../../../store/selectors");
var TaxonomyClassesSideBarSection = function (_a) {
    var _b = _a.isLoading, isLoading = _b === void 0 ? true : _b, className = _a.className, debouncedSearchTerm = _a.debouncedSearchTerm;
    var selectedOrganizationsIds = (0, selectors_1.useSelectedOrganizationsIdsSelector)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _c = __read((0, react_1.useState)(true), 2), isSimpleView = _c[0], setIsSimpleView = _c[1];
    var _d = __read((0, react_1.useState)(true), 2), isTaxonomiesDisplayed = _d[0], setIsTaxonomiesDisplayed = _d[1];
    var _e = __read((0, react_1.useState)(false), 2), isTaxonomiesExpanded = _e[0], setIsTaxonomiesExpanded = _e[1];
    var _f = __read((0, react_1.useState)(null), 2), treeExpandOverride = _f[0], setTreeExpandOverride = _f[1];
    var _g = __read((0, react_1.useTransition)(), 2), pending = _g[0], startTransition = _g[1];
    var resetSelections = function () {
        setIsSimpleView(true);
        setIsTaxonomiesDisplayed(true);
        setIsTaxonomiesExpanded(false);
        setTreeExpandOverride(null);
    };
    (0, react_1.useEffect)(function () {
        resetSelections();
    }, [selectedOrganizationsIds]);
    var handleCollapseAll = function () {
        startTransition(function () {
            setIsTaxonomiesExpanded(false);
            setTreeExpandOverride(tree_navigation_component_1.CollapseState.Collapse);
        });
    };
    var handleExpandAll = function () {
        startTransition(function () {
            setIsTaxonomiesExpanded(true);
            setTreeExpandOverride(tree_navigation_component_1.CollapseState.Expand);
        });
    };
    return (react_1.default.createElement(v2_1.SidebarSection, { className: className, title: react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(sidebar_section_styles_1.StyledControlIcons, null,
                react_1.default.createElement(v2_1.DisplayHideButton, { toggleDisplay: function () { return setIsTaxonomiesDisplayed(function (prevState) { return !prevState; }); }, isDisplayed: isTaxonomiesDisplayed }),
                t({ key: 'TAXONOMY_CLASSES' })),
            react_1.default.createElement(sidebar_section_styles_1.StyledControlIcons, null,
                react_1.default.createElement(v2_1.SimpleHierarchyToggleButton, { isSimpleView: isSimpleView, toggleView: function () { return setIsSimpleView(function (prevState) { return !prevState; }); } }),
                !isSimpleView && (pending ? react_1.default.createElement(common_1.ProcessingSpinner, null) : (react_1.default.createElement(v2_1.ExpandCollapseButton, { isExpanded: isTaxonomiesExpanded, toggleExpandState: isTaxonomiesExpanded ? handleCollapseAll : handleExpandAll }))))) },
        react_1.default.createElement(generic_1.Loading, { iconOrientation: "right", isLoading: isLoading, progressSize: 25, showEllipsis: false }),
        (isTaxonomiesDisplayed && !isLoading)
            && (react_1.default.createElement(taxonomy_classes_facet_nav_component_1.TaxonomyClassesFacetNav, { expandOverride: treeExpandOverride, debouncedSearchTerm: debouncedSearchTerm, isSimple: isSimpleView }))));
};
var MemoizedTaxonomyClasses = react_1.default.memo(TaxonomyClassesSideBarSection);
exports.default = MemoizedTaxonomyClasses;
exports.TaxonomyClassesSideBarSection = MemoizedTaxonomyClasses;
