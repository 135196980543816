"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogSettings = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var catalog_1 = require("../client/catalog");
var projects_1 = require("../client/projects");
var hooks_1 = require("../../hooks");
var localization_1 = require("../../localization");
var store_1 = require("../../store");
var useCatalogSettings = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var addError = (0, hooks_1.useNotification)().addError;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var projects = (0, projects_1.useProjects)().projects;
    var projectId = (0, store_1.useSelectedProjectId)();
    var catalogs = (0, catalog_1.useCatalogs)(projectId).catalogs;
    var catalogId = (0, store_1.useSelectedCatalogId)();
    var handleSelectStrategyNodePath = (0, react_1.useCallback)(function (path) { return dispatch((0, store_1.enrichAISetSelectedTreeNodePath)(path)); }, [dispatch]);
    var handleSelectStrategyTree = (0, react_1.useCallback)(function (productTree) { return dispatch((0, store_1.enrichAISetProductTree)(productTree)); }, [dispatch]);
    var handleSelectCatalog = (0, react_1.useCallback)(function (catalog) { return dispatch((0, store_1.enrichAISetSelectedCatalog)(catalog)); }, [dispatch]);
    var handleSelectProjectId = (0, react_1.useCallback)(function (id) { return dispatch((0, store_1.enrichAISetSelectedProject)(id)); }, [dispatch]);
    var selectedProject = (0, react_1.useMemo)(function () {
        if (!(projects === null || projects === void 0 ? void 0 : projects.length)) {
            return null;
        }
        if (projects.length === 1) {
            return projects[0];
        }
        var selectedProjectIndex = projects.findIndex(function (_a) {
            var id = _a.id;
            return id === projectId;
        });
        var index = selectedProjectIndex !== -1 ? selectedProjectIndex : projects.findIndex(function (_a) {
            var slug = _a.slug;
            return slug === projects_1.PUBLIC_PROJECT_SLUG;
        });
        return projects[index];
    }, [projectId, projects]);
    var selectedCatalog = (0, react_1.useMemo)(function () {
        // catalog id can be set to null when creating a new catalog
        if (!(catalogs === null || catalogs === void 0 ? void 0 : catalogs.length)) {
            return null;
        }
        if (catalogs.length === 1) {
            return catalogs[0];
        }
        var selectedCatalogIndex = catalogs.findIndex(function (_a) {
            var id = _a.id;
            return id === catalogId;
        });
        var index = selectedCatalogIndex !== -1 ? selectedCatalogIndex : 0;
        return catalogs[index];
    }, [catalogId, catalogs]);
    (0, react_1.useEffect)(function () {
        if (selectedCatalog && selectedCatalog.id !== catalogId) {
            var id = selectedCatalog.id, title = selectedCatalog.title;
            // TODO: set tags
            handleSelectCatalog({ id: id, title: title, tags: [] });
        }
    }, [catalogId, selectedCatalog, handleSelectCatalog, handleSelectStrategyNodePath]);
    (0, react_1.useEffect)(function () {
        if (selectedProject && selectedProject.id !== projectId) {
            handleSelectProjectId(selectedProject.id);
            handleSelectCatalog(undefined);
        }
    }, [projectId, selectedProject, handleSelectProjectId, handleSelectCatalog]);
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (selectedCatalog) {
            var _c = (_b = (_a = selectedCatalog.productTrees.edges[0]) === null || _a === void 0 ? void 0 : _a.node) !== null && _b !== void 0 ? _b : {}, id = _c.id, root = _c.root, myPermissions = _c.myPermissions, tags = _c.tags;
            handleSelectStrategyTree({
                id: id,
                permissions: myPermissions,
                tags: tags.edges.map(function (item) { return item.node; }),
            });
            handleSelectStrategyNodePath(root === null || root === void 0 ? void 0 : root.id);
            if (!id) {
                dispatch(addError(t({ key: 'GENERAL_ERROR_MESSAGE' })));
            }
        }
    }, [addError, dispatch, handleSelectStrategyNodePath, handleSelectStrategyTree, selectedCatalog, t]);
    return {
        selectedCatalogId: selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id,
        selectedProjectId: selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.id,
        onSelectCatalog: handleSelectCatalog,
        onSelectProjectId: handleSelectProjectId,
    };
};
exports.useCatalogSettings = useCatalogSettings;
