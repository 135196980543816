"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductTree = void 0;
var client_1 = require("@apollo/client");
var get_product_tree_query_1 = require("./get-product-tree.query");
var useProductTree = function (catalogId, nodeId, options) {
    var data = (0, client_1.useQuery)(get_product_tree_query_1.GET_PRODUCT_TREE, {
        skip: !catalogId || !nodeId || (options === null || options === void 0 ? void 0 : options.skip),
        variables: { nodeId: nodeId, catalogId: catalogId },
    });
    return __assign({}, data);
};
exports.useProductTree = useProductTree;
exports.default = exports.useProductTree;
