"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaxonomyClassDropdown = void 0;
var react_1 = __importStar(require("react"));
var taxonomy_class_dropdown_styles_1 = require("./taxonomy-class-dropdown.styles");
var hooks_1 = require("../../../hooks");
var hooks_2 = require("../../hooks");
var selectors_1 = require("../../../store/selectors");
var TaxonomyClassDropdown = function (_a) {
    var className = _a.className, _b = _a.clearOnBlur, clearOnBlur = _b === void 0 ? false : _b, onInputChange = _a.onInputChange, product = _a.product;
    var client = (0, hooks_1.useEnrichClient)();
    var taxonomyClasses = (0, selectors_1.useClassesSelector)();
    var isLoading = (0, selectors_1.useClassesIsLoadingSelector)();
    var loadRefinements = (0, hooks_2.useRefinements)();
    var _c = __read((0, react_1.useState)(null), 2), taxonomyClass = _c[0], setTaxonomyClass = _c[1];
    var options = (0, react_1.useMemo)(function () { return taxonomyClasses.v2TerminalIds.map(function (id) { return ({ label: taxonomyClasses.byId[id].title, value: id }); }); }, [taxonomyClasses]);
    var handleChange = function (_, option) { return __awaiter(void 0, void 0, void 0, function () {
        var classId;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setTaxonomyClass(option);
                    if (!product) return [3 /*break*/, 2];
                    classId = (_a = option === null || option === void 0 ? void 0 : option.value) !== null && _a !== void 0 ? _a : null;
                    return [4 /*yield*/, client.products.updateOne({ id: product.id, classId: classId })];
                case 1:
                    _b.sent();
                    void loadRefinements();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        var _a;
        if (product === null || product === void 0 ? void 0 : product.classId) {
            setTaxonomyClass({ label: (_a = taxonomyClasses.byId[product.classId]) === null || _a === void 0 ? void 0 : _a.title, value: product.classId });
        }
        return function () { return setTaxonomyClass(null); };
    }, [product === null || product === void 0 ? void 0 : product.classId, setTaxonomyClass, taxonomyClasses]);
    return (react_1.default.createElement(taxonomy_class_dropdown_styles_1.StyledAutocomplete, { clearOnBlur: clearOnBlur, openOnFocus: true, className: className, loading: isLoading, onChange: handleChange, onInputChange: onInputChange, options: options, value: taxonomyClass, size: "small" }));
};
exports.TaxonomyClassDropdown = TaxonomyClassDropdown;
exports.default = exports.TaxonomyClassDropdown;
