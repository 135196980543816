"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLanguage = void 0;
var react_1 = require("react");
var context_1 = require("../context");
var useLanguage = function () {
    var _a = (0, react_1.useContext)(context_1.EnrichContext).localization, selectedLanguage = _a.selectedLanguage, defaultLanguage = _a.defaultLanguage;
    return { selectedLanguage: selectedLanguage, defaultLanguage: defaultLanguage };
};
exports.useLanguage = useLanguage;
exports.default = exports.useLanguage;
