"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeEntityType = exports.ChangeActionType = exports.AvailableRefinementType = exports.PRODUCT_EDITOR_TABLE_COLUMNS = exports.ORGANIZATIONS_DEFAULT_OPTIONS = exports.UserRole = exports.ProductFilterOperatorType = exports.ProductFilterActiveTypes = void 0;
var ProductFilterActiveTypes;
(function (ProductFilterActiveTypes) {
    ProductFilterActiveTypes["All"] = "ALL";
    ProductFilterActiveTypes["Active"] = "ACTIVE";
    ProductFilterActiveTypes["Inactive"] = "INACTIVE";
})(ProductFilterActiveTypes = exports.ProductFilterActiveTypes || (exports.ProductFilterActiveTypes = {}));
var ProductFilterOperatorType;
(function (ProductFilterOperatorType) {
    ProductFilterOperatorType["And"] = "AND";
    ProductFilterOperatorType["Or"] = "OR";
})(ProductFilterOperatorType = exports.ProductFilterOperatorType || (exports.ProductFilterOperatorType = {}));
var UserRole;
(function (UserRole) {
    UserRole["Admin"] = "admin";
    UserRole["Merchandiser"] = "MERCHANDISER";
    UserRole["Unlicensed"] = "UNLICENSED";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
exports.ORGANIZATIONS_DEFAULT_OPTIONS = {
    NONE: { id: 'None', name: 'None' },
};
var PRODUCT_EDITOR_TABLE_COLUMNS;
(function (PRODUCT_EDITOR_TABLE_COLUMNS) {
    PRODUCT_EDITOR_TABLE_COLUMNS["PRODUCT_ID"] = "PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_ID";
    PRODUCT_EDITOR_TABLE_COLUMNS["SKU_ID"] = "PRODUCT_EDITOR_TABLE_COLUMNS_SKU_ID";
    PRODUCT_EDITOR_TABLE_COLUMNS["IMAGE"] = "PRODUCT_EDITOR_TABLE_COLUMNS_IMAGE";
    PRODUCT_EDITOR_TABLE_COLUMNS["NAME"] = "PRODUCT_EDITOR_TABLE_COLUMNS_NAME";
    PRODUCT_EDITOR_TABLE_COLUMNS["DESCRIPTION"] = "PRODUCT_EDITOR_TABLE_COLUMNS_DESCRIPTION";
    PRODUCT_EDITOR_TABLE_COLUMNS["PRODUCT_GROUP"] = "PRODUCT_EDITOR_TABLE_COLUMNS_PRODUCT_GROUP";
    PRODUCT_EDITOR_TABLE_COLUMNS["CREATED_DATE"] = "PRODUCT_EDITOR_TABLE_COLUMNS_CREATED_DATE";
    PRODUCT_EDITOR_TABLE_COLUMNS["LAST_MODIFIED"] = "PRODUCT_EDITOR_TABLE_COLUMNS_LAST_MODIFIED";
    PRODUCT_EDITOR_TABLE_COLUMNS["CLASS"] = "PRODUCT_EDITOR_TABLE_COLUMNS_TAXONOMY_CLASS";
    PRODUCT_EDITOR_TABLE_COLUMNS["URL"] = "PRODUCT_EDITOR_TABLE_COLUMNS_URL";
    // descoped for MVP
    // PRICE = 'PRODUCT_EDITOR_TABLE_COLUMNS_PRICE',
    PRODUCT_EDITOR_TABLE_COLUMNS["ACTIVE_STATUS"] = "PRODUCT_EDITOR_TABLE_COLUMNS_ACTIVE_STATUS";
    PRODUCT_EDITOR_TABLE_COLUMNS["COMMENTS"] = "PRODUCT_EDITOR_TABLE_COLUMNS_COMMENTS";
    PRODUCT_EDITOR_TABLE_COLUMNS["CURATED_ATTRIBUTES"] = "PRODUCT_EDITOR_TABLE_COLUMNS_CURATED_ATTRIBUTES";
    PRODUCT_EDITOR_TABLE_COLUMNS["STANDARD_FIELDS"] = "PRODUCT_EDITOR_TABLE_COLUMNS_STANDARD_FIELDS";
    PRODUCT_EDITOR_TABLE_COLUMNS["CUSTOM_FIELDS"] = "PRODUCT_EDITOR_TABLE_COLUMNS_CUSTOM_FIELDS";
})(PRODUCT_EDITOR_TABLE_COLUMNS = exports.PRODUCT_EDITOR_TABLE_COLUMNS || (exports.PRODUCT_EDITOR_TABLE_COLUMNS = {}));
var AvailableRefinementType;
(function (AvailableRefinementType) {
    AvailableRefinementType["All"] = "ALL";
    AvailableRefinementType["Matches"] = "MATCHES";
})(AvailableRefinementType = exports.AvailableRefinementType || (exports.AvailableRefinementType = {}));
var ChangeActionType;
(function (ChangeActionType) {
    ChangeActionType["Add"] = "ADD";
    ChangeActionType["Delete"] = "DELETE";
    ChangeActionType["Edit"] = "EDIT";
})(ChangeActionType = exports.ChangeActionType || (exports.ChangeActionType = {}));
var ChangeEntityType;
(function (ChangeEntityType) {
    ChangeEntityType["Attribute"] = "ATTRIBUTE";
    ChangeEntityType["Value"] = "VALUE";
})(ChangeEntityType = exports.ChangeEntityType || (exports.ChangeEntityType = {}));
