"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddValueContainer = void 0;
var react_1 = __importStar(require("react"));
var graphql_1 = require("../../../../__generated__/graphql");
var react_redux_1 = require("react-redux");
var add_value_dialog_utils_1 = require("./add-value-dialog.utils");
var add_value_to_other_nodes_dialog_component_1 = require("./add-value-to-other-nodes-dialog.component");
var validation_schema_1 = require("./validation-schema");
var attributes_1 = require("../../../client/attributes");
var hooks_1 = require("../../../client/hooks");
var get_tree_nodes_1 = require("../../../client/product-tree/get-tree-nodes");
var values_1 = require("../../../client/values");
var generic_1 = require("../../../generic");
var hooks_2 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var localization_utils_1 = require("../../../../localization/localization.utils");
var store_1 = require("../../../../store");
var CREATE_VALUE_FORM_ID = 'create-value-form';
var AddValueContainer = function (_a) {
    var open = _a.open, selectedStrategyNodeTitle = _a.selectedStrategyNodeTitle, defaultSelectedAttributeName = _a.selectedAttribute, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, hooks_2.useNotification)(), addSuccess = _b.addSuccess, addError = _b.addError;
    var refetchStrategyAttributes = (0, hooks_1.useRefetchQueries)().refetchStrategyAttributes;
    var selectedStrategyNodeId = (0, store_1.useSelectedTreeNodeId)();
    var selectedStrategyNodePath = (0, store_1.useSelectedTreeNodePath)();
    var productTreeRootNodeId = (0, store_1.useProductTreeRootNodeId)();
    var productTreeId = (0, store_1.useProductTreeId)();
    var validationSchema = (0, validation_schema_1.useAttributeValidationSchema)();
    var addValuesToSeveralNodes = (0, values_1.useAddValuesToSeveralNodes)().addValuesToSeveralNodes;
    var editAttributes = (0, attributes_1.useEditAttributes)().editAttributes;
    var getTreeNodePaths = (0, get_tree_nodes_1.useTreeNodePaths)().getTreeNodePaths;
    var _c = __read((0, react_1.useState)(null), 2), addToOtherNodesDialogConfig = _c[0], setAddToOtherNodesDialogConfig = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), isLoading = _d[0], setIsLoading = _d[1];
    var _e = __read((0, react_1.useState)(defaultSelectedAttributeName), 2), selectedAttributeName = _e[0], setSelectedAttributeName = _e[1];
    (0, react_1.useEffect)(function () {
        if (defaultSelectedAttributeName) {
            setSelectedAttributeName(defaultSelectedAttributeName);
        }
    }, [defaultSelectedAttributeName]);
    var _f = (0, attributes_1.useExistingAttributes)({
        productTreeNodeId: selectedStrategyNodeId,
        options: { skip: !open },
    }), _g = _f.data, data = _g === void 0 ? [] : _g, loading = _f.loading, error = _f.error;
    var attributesOptionList = (0, react_1.useMemo)(function () {
        var labelsMap = new Map();
        data.forEach(function (_a) {
            var label = _a.label;
            labelsMap.set(label, { label: label, value: label });
        });
        return Array.from(labelsMap.values());
    }, [data]);
    var initialValues = (0, react_1.useMemo)(function () {
        var _a;
        var defaultInitialValues = {
            attributes: [__assign(__assign({}, generic_1.initialAttributeItem), { readonly: true })],
        };
        if (!selectedAttributeName) {
            return defaultInitialValues;
        }
        var selectedAttribute = data.find(function (_a) {
            var label = _a.label;
            return label === selectedAttributeName;
        });
        if (!selectedAttribute) {
            return defaultInitialValues;
        }
        var label = selectedAttribute.label, description = selectedAttribute.description, allowedValues = selectedAttribute.allowedValues;
        return {
            attributes: [{
                    name: label,
                    description: description || '',
                    readonly: true,
                    values: (_a = allowedValues.edges) === null || _a === void 0 ? void 0 : _a.map(function (_a) {
                        var _b;
                        var node = _a.node;
                        return ({
                            name: node.value,
                            description: (_b = node.description) !== null && _b !== void 0 ? _b : '',
                            readonly: true,
                        });
                    }),
                }],
        };
    }, [data, selectedAttributeName]);
    var dispatchError = function (attributes, error) {
        addError(t({
            key: 'ADD_ENTITY_ERROR',
            values: {
                count: attributes.length,
                entity: String(t({ key: 'VALUE' })).toLowerCase(),
                b: localization_utils_1.renderBoldText,
            },
        }), error);
    };
    var handleClose = function () {
        setSelectedAttributeName('');
        onClose();
    };
    var handleAddEditedItemsToHistory = function (attribute, treeNodePaths) {
        var e_1, _a, e_2, _b;
        var historyItems = [];
        var _c = attribute.editedValues, editedValues = _c === void 0 ? [] : _c, name = attribute.name, isDescriptionChanged = attribute.isDescriptionChanged;
        try {
            for (var treeNodePaths_1 = __values(treeNodePaths), treeNodePaths_1_1 = treeNodePaths_1.next(); !treeNodePaths_1_1.done; treeNodePaths_1_1 = treeNodePaths_1.next()) {
                var treeNodePath = treeNodePaths_1_1.value;
                try {
                    for (var editedValues_1 = (e_2 = void 0, __values(editedValues)), editedValues_1_1 = editedValues_1.next(); !editedValues_1_1.done; editedValues_1_1 = editedValues_1.next()) {
                        var valueItem = editedValues_1_1.value;
                        var historyItem = {
                            id: "".concat(name).concat(store_1.LABEL_VALUE_PAIR_SEPARATOR).concat(valueItem.name),
                            entityType: store_1.ChangeEntityType.Value,
                            actionType: store_1.ChangeActionType.Edit,
                            path: treeNodePath,
                        };
                        historyItems.push(historyItem);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (editedValues_1_1 && !editedValues_1_1.done && (_b = editedValues_1.return)) _b.call(editedValues_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                if (isDescriptionChanged) {
                    var historyItem = {
                        id: name,
                        entityType: store_1.ChangeEntityType.Attribute,
                        actionType: store_1.ChangeActionType.Edit,
                        path: treeNodePath,
                    };
                    historyItems.push(historyItem);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (treeNodePaths_1_1 && !treeNodePaths_1_1.done && (_a = treeNodePaths_1.return)) _a.call(treeNodePaths_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        dispatch((0, store_1.enrichAIAddChanges)(historyItems));
    };
    var handleAddNewItemsToHistory = function (attribute, treeNodePaths) {
        var e_3, _a, e_4, _b;
        var historyItems = [];
        var _c = attribute.newValues, newValues = _c === void 0 ? [] : _c, name = attribute.name;
        try {
            for (var newValues_1 = __values(newValues), newValues_1_1 = newValues_1.next(); !newValues_1_1.done; newValues_1_1 = newValues_1.next()) {
                var valueItem = newValues_1_1.value;
                try {
                    for (var treeNodePaths_2 = (e_4 = void 0, __values(treeNodePaths)), treeNodePaths_2_1 = treeNodePaths_2.next(); !treeNodePaths_2_1.done; treeNodePaths_2_1 = treeNodePaths_2.next()) {
                        var treeNodePath = treeNodePaths_2_1.value;
                        var historyItem = {
                            id: "".concat(name).concat(store_1.LABEL_VALUE_PAIR_SEPARATOR).concat(valueItem.name),
                            entityType: store_1.ChangeEntityType.Value,
                            actionType: store_1.ChangeActionType.Add,
                            path: treeNodePath,
                        };
                        historyItems.push(historyItem);
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (treeNodePaths_2_1 && !treeNodePaths_2_1.done && (_b = treeNodePaths_2.return)) _b.call(treeNodePaths_2);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (newValues_1_1 && !newValues_1_1.done && (_a = newValues_1.return)) _a.call(newValues_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        dispatch((0, store_1.enrichAIAddChanges)(historyItems));
    };
    var handleUpdateProductPresentation = function () { return refetchStrategyAttributes(selectedStrategyNodeId); };
    var handleAddValuesToSelectedNode = function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var parsedAttributeData, _a, editedValues, isDescriptionChanged, _b, newValues, attribute, newAttributeData, promises, responses, responses_1, responses_1_1, response, treeNodes, paths, error_1;
        var e_5, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    parsedAttributeData = (0, add_value_dialog_utils_1.parseFormValues)(formValues.attributes[0], initialValues.attributes[0]);
                    _a = parsedAttributeData.editedValues, editedValues = _a === void 0 ? [] : _a, isDescriptionChanged = parsedAttributeData.isDescriptionChanged, _b = parsedAttributeData.newValues, newValues = _b === void 0 ? [] : _b, attribute = __rest(parsedAttributeData, ["editedValues", "isDescriptionChanged", "newValues"]);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 5, 6, 7]);
                    newAttributeData = [__assign(__assign({}, attribute), { values: newValues })];
                    promises = [];
                    if (newValues === null || newValues === void 0 ? void 0 : newValues.length) {
                        promises.push(addValuesToSeveralNodes(newAttributeData, [selectedStrategyNodeId]));
                    }
                    if ((editedValues === null || editedValues === void 0 ? void 0 : editedValues.length) || isDescriptionChanged) {
                        promises.push(editAttributes([__assign(__assign({}, attribute), { values: editedValues })], [productTreeRootNodeId]));
                    }
                    return [4 /*yield*/, Promise.allSettled(promises)];
                case 2:
                    responses = _d.sent();
                    try {
                        for (responses_1 = __values(responses), responses_1_1 = responses_1.next(); !responses_1_1.done; responses_1_1 = responses_1.next()) {
                            response = responses_1_1.value;
                            if (response.status === 'rejected') {
                                throw new Error(response.reason);
                            }
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (responses_1_1 && !responses_1_1.done && (_c = responses_1.return)) _c.call(responses_1);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                    if (!((editedValues === null || editedValues === void 0 ? void 0 : editedValues.length) || isDescriptionChanged)) return [3 /*break*/, 4];
                    return [4 /*yield*/, getTreeNodePaths({
                            variables: {
                                productTreeId: productTreeId,
                                sort: [{ distance: graphql_1.SortOrder.Asc }],
                                skipLabels: true,
                                filter: {
                                    validators: [
                                        {
                                            some: {
                                                label: {
                                                    equals: attribute.name,
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        })];
                case 3:
                    treeNodes = (_d.sent()).data;
                    paths = treeNodes.map(function (_a) {
                        var path = _a.path;
                        return path;
                    });
                    if (paths) {
                        handleAddEditedItemsToHistory(parsedAttributeData, paths);
                    }
                    _d.label = 4;
                case 4:
                    handleAddNewItemsToHistory(parsedAttributeData, [selectedStrategyNodePath]);
                    if (newValues === null || newValues === void 0 ? void 0 : newValues.length) {
                        setAddToOtherNodesDialogConfig(newAttributeData);
                    }
                    else {
                        addSuccess(t({ key: 'EDIT_GUIDELINES_SUCCESS', values: { entityName: attribute.name } }));
                        void handleUpdateProductPresentation();
                    }
                    return [3 /*break*/, 7];
                case 5:
                    error_1 = _d.sent();
                    dispatchError(formValues.attributes, error_1.message);
                    return [3 /*break*/, 7];
                case 6:
                    handleClose();
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleAddAttributesToOtherNodes = function (selectedNodePaths) { return __awaiter(void 0, void 0, void 0, function () {
        var treeNodeIdList, _a, values, item, entities, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    treeNodeIdList = selectedNodePaths.map(function (path) { return path.split(store_1.STRATEGY_NODE_PATH_SEPARATOR).pop(); });
                    return [4 /*yield*/, addValuesToSeveralNodes(addToOtherNodesDialogConfig, treeNodeIdList)];
                case 2:
                    _b.sent();
                    _a = addToOtherNodesDialogConfig[0], values = _a.values, item = __rest(_a, ["values"]);
                    handleAddNewItemsToHistory(__assign(__assign({}, item), { newValues: values }), selectedNodePaths);
                    entities = values.map(function (_a) {
                        var name = _a.name;
                        return name;
                    }).join(', ');
                    addSuccess(t({
                        key: 'ADD_ENTITY_TO_OTHER_NODES_SUCCESS',
                        values: {
                            count: addToOtherNodesDialogConfig.length,
                            entity: String(t({ key: 'VALUE' })).toLowerCase(),
                            entityName: entities,
                        },
                    }));
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _b.sent();
                    dispatchError(addToOtherNodesDialogConfig, error_2.message);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    setAddToOtherNodesDialogConfig(null);
                    void handleUpdateProductPresentation();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleValuesChange = (0, react_1.useCallback)(function (values) {
        var _a;
        var selectedAttributeName = (_a = values[generic_1.AttributeFormFieldNames.Attributes][0]) === null || _a === void 0 ? void 0 : _a.name;
        setSelectedAttributeName(selectedAttributeName);
    }, []);
    var handleAddToOtherNodesDialogClose = function () {
        setAddToOtherNodesDialogConfig(null);
        void handleUpdateProductPresentation();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        open && (react_1.default.createElement(generic_1.CreateEntityDialog, { initialValues: initialValues, validationSchema: validationSchema, title: t({ key: 'ADD_ENTITY_TO_NODE', values: { entity: String(t({ key: 'VALUE' })).toLowerCase() } }), formId: CREATE_VALUE_FORM_ID, attributesOptionList: attributesOptionList, isLoading: loading, error: error === null || error === void 0 ? void 0 : error.message, valueOptionListStrategyNodeId: productTreeRootNodeId, onSubmit: handleAddValuesToSelectedNode, onClose: handleClose, onChange: handleValuesChange })),
        !!addToOtherNodesDialogConfig && (react_1.default.createElement(add_value_to_other_nodes_dialog_component_1.AddValueToOtherNodesDialog, { onClose: handleAddToOtherNodesDialogClose, onSubmit: handleAddAttributesToOtherNodes, selectedStrategyNodeTitle: selectedStrategyNodeTitle, attributes: addToOtherNodesDialogConfig, isLoading: isLoading }))));
};
exports.AddValueContainer = AddValueContainer;
exports.default = exports.AddValueContainer;
