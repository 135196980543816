"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowHighlighting = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var DELAY = 500;
var FADE_DELAY = 1000;
var useRowHighlighting = function (_a) {
    var targetRef = _a.targetRef, className = _a.className, scrollToHighlightedItem = _a.scrollToHighlightedItem, _b = _a.shouldRowBeHighlighted, shouldRowBeHighlighted = _b === void 0 ? (0, ramda_1.always)(false) : _b, value = _a.value;
    var previousValue = (0, react_1.useRef)(value);
    (0, react_1.useEffect)(function () {
        var shouldBeHighlighted = shouldRowBeHighlighted === null || shouldRowBeHighlighted === void 0 ? void 0 : shouldRowBeHighlighted(value, previousValue.current);
        if (!shouldBeHighlighted) {
            return;
        }
        var target = targetRef.current;
        if (scrollToHighlightedItem) {
            target.scrollIntoView();
        }
        target.classList.add(className);
        var shadowTimeoutId;
        var highlightingTimeoutId = setTimeout(function () {
            target.classList.remove(className);
            target.style.transition = "background-color ".concat(FADE_DELAY, "ms");
            shadowTimeoutId = setTimeout(function () {
                target.style.transition = '';
            }, FADE_DELAY);
        }, DELAY);
        previousValue.current = value;
        return function () {
            clearTimeout(highlightingTimeoutId);
            clearTimeout(shadowTimeoutId);
        };
    }, [className, scrollToHighlightedItem, shouldRowBeHighlighted, targetRef, value]);
};
exports.useRowHighlighting = useRowHighlighting;
exports.default = exports.useRowHighlighting;
