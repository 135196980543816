"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExistingAttributes = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var get_tree_node_validators_query_1 = require("../get-tree-node-validators.query");
var useExistingAttributes = function (_a) {
    var productTreeNodeId = _a.productTreeNodeId, options = _a.options;
    var data = (0, client_1.useQuery)(get_tree_node_validators_query_1.GET_TREE_NODE_VALIDATORS, { variables: { productTreeNodeId: productTreeNodeId }, skip: options === null || options === void 0 ? void 0 : options.skip, fetchPolicy: 'network-only' });
    var existingAttributes = (0, react_1.useMemo)(function () {
        var _a;
        if (!data) {
            return null;
        }
        return (_a = data.data) === null || _a === void 0 ? void 0 : _a.productTreeNode.validators.filter(function (_a) {
            var labelValidation = _a.labelValidation;
            return labelValidation;
        }).map(function (_a) {
            var labelValidation = _a.labelValidation;
            return (__assign({}, labelValidation));
        });
    }, [data]);
    return __assign(__assign({}, data), { data: existingAttributes });
};
exports.useExistingAttributes = useExistingAttributes;
