"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOT_INCLUDED_STANDARD_FIELDS = exports.DATE_FIELDS = exports.BOOLEAN_FIELDS = exports.ID_FIELDS = void 0;
var filter_model_1 = require("./filter.model");
var State = __importStar(require("../../../store/state"));
exports.ID_FIELDS = [
    filter_model_1.FilterField.Category,
    filter_model_1.FilterField.TaxonomyClass,
    filter_model_1.FilterField.Attribute,
    State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_ID,
    State.PRODUCT_EDITOR_TABLE_COLUMNS.CLASS,
    State.PRODUCT_EDITOR_TABLE_COLUMNS.PRODUCT_GROUP,
    filter_model_1.FilterField.Value,
];
exports.BOOLEAN_FIELDS = [State.PRODUCT_EDITOR_TABLE_COLUMNS.ACTIVE_STATUS];
exports.DATE_FIELDS = [filter_model_1.FilterField.DateCreated, filter_model_1.FilterField.DateUpdated, filter_model_1.FilterField.DateLastCommented];
exports.NOT_INCLUDED_STANDARD_FIELDS = [
    State.PRODUCT_EDITOR_TABLE_COLUMNS.IMAGE,
    State.PRODUCT_EDITOR_TABLE_COLUMNS.CREATED_DATE,
    State.PRODUCT_EDITOR_TABLE_COLUMNS.LAST_MODIFIED,
];
