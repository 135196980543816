"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttributeSelector = void 0;
var react_1 = __importStar(require("react"));
var common_1 = require("../../../../../../common");
var localization_1 = require("../../../../../../localization");
var hooks_1 = require("../../../../../hooks");
var AttributeSelector = function (_a) {
    var onOptionChange = _a.onOptionChange, selected = _a.selected;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var attributeNameOptions = (0, hooks_1.useAttributes)().attributeNameOptions;
    var options = (0, react_1.useMemo)(function () { return attributeNameOptions.map(function (_a) {
        var label = _a.label, id = _a.id;
        return ({
            label: label,
            value: id,
        });
    }); }, [attributeNameOptions]);
    return (react_1.default.createElement(common_1.AutocompleteSelector, { options: options, onChange: onOptionChange, selected: selected, placeholder: t({ key: 'ATTRIBUTE' }) }));
};
exports.AttributeSelector = AttributeSelector;
exports.default = exports.AttributeSelector;
