"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefineModalButton = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var refine_modal_button_style_1 = require("./refine-modal-button.style");
var refine_modal_component_1 = require("./refine-modal.component");
var RefineModalButton = function (_a) {
    var activeColumns = _a.activeColumns, columns = _a.columns, defaultColumns = _a.defaultColumns, setActiveColumns = _a.setActiveColumns, children = _a.children, disabled = _a.disabled;
    var _b = __read((0, react_1.useState)(false), 2), isOpen = _b[0], setOpen = _b[1];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(refine_modal_component_1.RefineModal, { activeColumns: activeColumns, columns: columns, defaultColumns: defaultColumns, isOpen: isOpen, setActiveColumns: setActiveColumns, setOpen: setOpen }),
        react_1.default.createElement(refine_modal_button_style_1.StyledButtonRefine, null,
            react_1.default.createElement(ui_components_1.Button, { disabled: disabled, variant: 'outlined', onClick: function () { return setOpen(true); } }, children))));
};
exports.RefineModalButton = RefineModalButton;
exports.default = react_1.default.memo(exports.RefineModalButton);
