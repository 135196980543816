"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExpandedNodes = exports.expandedNodesSelector = exports.useCatalogChanges = exports.catalogChangesSelector = exports.useProductTreeRootNodeId = exports.productTreeRootNodeIdSelector = exports.useSelectedTreeNodeId = exports.selectedTreeNodeIdSelector = exports.useProductTreeTagValue = exports.productTreeTagsSelector = exports.useIsProductTreeEditingAllowed = exports.productTreePermissionsSelector = exports.useProductTreeId = exports.productTreeIdSelector = exports.useSelectedTreeNodePath = exports.selectedTreeNodePathSelector = exports.useSelectedProjectId = exports.selectedProjectIdSelector = exports.useSelectedCatalogId = exports.selectedCatalogIdSelector = exports.enrichAISelector = void 0;
var graphql_1 = require("../../__generated__/graphql");
var react_redux_1 = require("react-redux");
var reselect_1 = require("reselect");
var main_1 = require("./main");
var const_1 = require("../const");
exports.enrichAISelector = (0, reselect_1.createSelector)(main_1.enrichSelector, function (_a) {
    var enrichAI = _a.enrichAI;
    return enrichAI;
});
exports.selectedCatalogIdSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var selectedCatalog = _a.selectedCatalog;
    return selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id;
});
var useSelectedCatalogId = function () { return (0, react_redux_1.useSelector)(exports.selectedCatalogIdSelector); };
exports.useSelectedCatalogId = useSelectedCatalogId;
exports.selectedProjectIdSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var selectedProjectId = _a.selectedProjectId;
    return selectedProjectId;
});
var useSelectedProjectId = function () { return (0, react_redux_1.useSelector)(exports.selectedProjectIdSelector); };
exports.useSelectedProjectId = useSelectedProjectId;
exports.selectedTreeNodePathSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var selectedTreeNodePath = _a.selectedTreeNodePath;
    return selectedTreeNodePath;
});
var useSelectedTreeNodePath = function () { return (0, react_redux_1.useSelector)(exports.selectedTreeNodePathSelector); };
exports.useSelectedTreeNodePath = useSelectedTreeNodePath;
exports.productTreeIdSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var productTree = _a.productTree;
    return productTree === null || productTree === void 0 ? void 0 : productTree.id;
});
var useProductTreeId = function () { return (0, react_redux_1.useSelector)(exports.productTreeIdSelector); };
exports.useProductTreeId = useProductTreeId;
exports.productTreePermissionsSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var productTree = _a.productTree;
    return productTree === null || productTree === void 0 ? void 0 : productTree.permissions;
});
var useIsProductTreeEditingAllowed = function () {
    var permissions = (0, react_redux_1.useSelector)(exports.productTreePermissionsSelector);
    return permissions === null || permissions === void 0 ? void 0 : permissions.some(function (permission) { return [graphql_1.PermissionAction.Grant, graphql_1.PermissionAction.Write].includes(permission); });
};
exports.useIsProductTreeEditingAllowed = useIsProductTreeEditingAllowed;
exports.productTreeTagsSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var productTree = _a.productTree;
    return productTree === null || productTree === void 0 ? void 0 : productTree.tags;
});
var useProductTreeTagValue = function (key) {
    var _a;
    var tags = (0, react_redux_1.useSelector)(exports.productTreeTagsSelector);
    return (_a = tags.find(function (tag) { return tag.key === key; })) === null || _a === void 0 ? void 0 : _a.value;
};
exports.useProductTreeTagValue = useProductTreeTagValue;
exports.selectedTreeNodeIdSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var selectedTreeNodePath = _a.selectedTreeNodePath;
    return selectedTreeNodePath === null || selectedTreeNodePath === void 0 ? void 0 : selectedTreeNodePath.split(const_1.STRATEGY_NODE_PATH_SEPARATOR).pop();
});
var useSelectedTreeNodeId = function () { return (0, react_redux_1.useSelector)(exports.selectedTreeNodeIdSelector); };
exports.useSelectedTreeNodeId = useSelectedTreeNodeId;
exports.productTreeRootNodeIdSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var selectedTreeNodePath = _a.selectedTreeNodePath;
    return selectedTreeNodePath === null || selectedTreeNodePath === void 0 ? void 0 : selectedTreeNodePath.split(const_1.STRATEGY_NODE_PATH_SEPARATOR).shift();
});
var useProductTreeRootNodeId = function () { return (0, react_redux_1.useSelector)(exports.productTreeRootNodeIdSelector); };
exports.useProductTreeRootNodeId = useProductTreeRootNodeId;
exports.catalogChangesSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var selectedCatalog = _a.selectedCatalog, changes = _a.changes;
    return changes === null || changes === void 0 ? void 0 : changes[selectedCatalog === null || selectedCatalog === void 0 ? void 0 : selectedCatalog.id];
});
var useCatalogChanges = function () { return (0, react_redux_1.useSelector)(exports.catalogChangesSelector); };
exports.useCatalogChanges = useCatalogChanges;
exports.expandedNodesSelector = (0, reselect_1.createSelector)(exports.enrichAISelector, function (_a) {
    var expandedNodes = _a.expandedNodes;
    return expandedNodes;
});
var useExpandedNodes = function () { return (0, react_redux_1.useSelector)(exports.expandedNodesSelector); };
exports.useExpandedNodes = useExpandedNodes;
