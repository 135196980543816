"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFormValues = void 0;
var ramda_1 = require("ramda");
var parseFormValues = function (modifiedAttribute, initialAttribute) {
    var values = modifiedAttribute.values, attribute = __rest(modifiedAttribute, ["values"]);
    var initialValuesMap = new Map(initialAttribute.values.map(function (_a) {
        var name = _a.name, description = _a.description;
        return [name, description];
    }));
    var modifiedValues = values.filter(function (_a) {
        var readonly = _a.readonly, description = _a.description, name = _a.name;
        return !readonly || description !== initialValuesMap.get(name);
    });
    var isDescriptionChanged = modifiedAttribute.description !== initialAttribute.description;
    return __assign(__assign({ isDescriptionChanged: isDescriptionChanged }, attribute), (0, ramda_1.groupBy)(function (_a) {
        var readonly = _a.readonly;
        return (readonly ? 'editedValues' : 'newValues');
    }, modifiedValues));
};
exports.parseFormValues = parseFormValues;
