"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResizeObserver = void 0;
var react_1 = require("react");
var useResizeObserver = function (callback) {
    var ref = (0, react_1.useRef)(null);
    (0, react_1.useLayoutEffect)(function () {
        var element = ref.current;
        if (!element) {
            return;
        }
        var observer = new ResizeObserver(function (entries) {
            callback(element, entries[0]);
        });
        observer.observe(element);
        return function () {
            observer.disconnect();
        };
    }, [callback, ref]);
    return ref;
};
exports.useResizeObserver = useResizeObserver;
exports.default = exports.useResizeObserver;
