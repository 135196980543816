"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NODE_CATEGORY_LABEL_TAG_KEY = exports.NODE_CATEGORY_LABEL_DEFAULT = exports.ONTOLOGY_NODE_TITLE = exports.UNCATEGORIZED_NODE_FILTER_LABEL_VALUE = exports.UNCATEGORIZED_NODE_FILTER_LABEL = exports.UNCATEGORIZED_NODE_TITLE = exports.LAST_ATTRIBUTE_UPDATED_AT_LABEL = exports.PRODUCT_ORIGINAL_CATEGORY_LABEL = exports.PRODUCT_CATEGORIES_LABEL = exports.PRODUCT_TYPE_LABEL_TAG_KEY = exports.PRODUCT_TYPE_LABEL_DEFAULT = exports.PRODUCT_PAGE_URL_LABEL = exports.PRODUCT_SKU_LABEL = exports.PRODUCT_DESCRIPTION_LABEL = exports.PRODUCT_BRAND_LABEL = exports.PRODUCT_IMAGE_LABEL = exports.SpecialAttributeValue = void 0;
var SpecialAttributeValue;
(function (SpecialAttributeValue) {
    SpecialAttributeValue["Unknown"] = "Unknown";
    SpecialAttributeValue["NotApplicable"] = "NOT APPLICABLE";
})(SpecialAttributeValue = exports.SpecialAttributeValue || (exports.SpecialAttributeValue = {}));
exports.PRODUCT_IMAGE_LABEL = 'Product Image URL';
exports.PRODUCT_BRAND_LABEL = 'Brand';
exports.PRODUCT_DESCRIPTION_LABEL = 'Description';
exports.PRODUCT_SKU_LABEL = 'Product SKU';
exports.PRODUCT_PAGE_URL_LABEL = 'Product Page URL';
exports.PRODUCT_TYPE_LABEL_DEFAULT = 'Product Type';
exports.PRODUCT_TYPE_LABEL_TAG_KEY = 'ENRICH.CATEGORY_DERIVED_LABEL';
exports.PRODUCT_CATEGORIES_LABEL = 'Categories';
exports.PRODUCT_ORIGINAL_CATEGORY_LABEL = 'category_names';
exports.LAST_ATTRIBUTE_UPDATED_AT_LABEL = 'lastAttributeUpdatedAt';
exports.UNCATEGORIZED_NODE_TITLE = 'Uncategorized Bucket';
exports.UNCATEGORIZED_NODE_FILTER_LABEL = 'Escalation Reason';
exports.UNCATEGORIZED_NODE_FILTER_LABEL_VALUE = 'Uncategorized';
exports.ONTOLOGY_NODE_TITLE = 'Ontology';
exports.NODE_CATEGORY_LABEL_DEFAULT = 'Product Type';
exports.NODE_CATEGORY_LABEL_TAG_KEY = 'ENRICH.CATEGORY_LABEL';
