"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalStorage = void 0;
var react_1 = require("react");
var context_1 = require("../context");
var useLocalStorage = function () {
    var localStorage = (0, react_1.useContext)(context_1.EnrichContext).localStorage;
    return localStorage;
};
exports.useLocalStorage = useLocalStorage;
exports.default = exports.useLocalStorage;
