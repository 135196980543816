"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addIdsToLoadQueue = void 0;
var addIdsToLoadQueue = function (newIds, highPriority) {
    if (highPriority === void 0) { highPriority = false; }
    return function (oldIds) {
        if (!newIds.length) {
            return oldIds;
        }
        var priorityList = [(oldIds !== null && oldIds !== void 0 ? oldIds : []), newIds];
        if (highPriority) {
            priorityList.reverse();
        }
        var _a = __read(priorityList, 2), firstList = _a[0], secondList = _a[1];
        var uniqueIds = Array.from(new Set(__spreadArray(__spreadArray([], __read(firstList), false), __read(secondList), false)));
        // oldIds is returned to keep the reference the same when the list has not changed
        if (uniqueIds.length === (oldIds !== null && oldIds !== void 0 ? oldIds : []).length) {
            return oldIds;
        }
        return uniqueIds;
    };
};
exports.addIdsToLoadQueue = addIdsToLoadQueue;
