"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CatalogProgressState = void 0;
var CatalogProgressState;
(function (CatalogProgressState) {
    CatalogProgressState[CatalogProgressState["NotStarted"] = 0] = "NotStarted";
    CatalogProgressState[CatalogProgressState["Waiting"] = 1] = "Waiting";
    CatalogProgressState[CatalogProgressState["Uploading"] = 2] = "Uploading";
    CatalogProgressState[CatalogProgressState["Importing"] = 3] = "Importing";
    CatalogProgressState[CatalogProgressState["CreatingProductTree"] = 4] = "CreatingProductTree";
    CatalogProgressState[CatalogProgressState["InitialProductsEnrichment"] = 5] = "InitialProductsEnrichment";
    CatalogProgressState[CatalogProgressState["RefiningStrategy"] = 6] = "RefiningStrategy";
    CatalogProgressState[CatalogProgressState["QualityAssurance"] = 7] = "QualityAssurance";
    CatalogProgressState[CatalogProgressState["Exporting"] = 8] = "Exporting";
    CatalogProgressState[CatalogProgressState["Completed"] = 9] = "Completed";
    CatalogProgressState[CatalogProgressState["Error"] = 10] = "Error";
})(CatalogProgressState = exports.CatalogProgressState || (exports.CatalogProgressState = {}));
