"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductTreeNodePresentation = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var graphql_1 = require("../../../../__generated__/graphql");
var get_product_presentation_query_1 = require("../get-product-presentation.query");
var useProductTreeNodePresentation = function (_a) {
    var productTreeId = _a.productTreeId, catalogId = _a.catalogId, productId = _a.productId, _b = _a.skipMeasures, skipMeasures = _b === void 0 ? false : _b;
    var _c = (0, client_1.useQuery)(get_product_presentation_query_1.GET_PRODUCT_TREE_NODE_PRESENTATION, {
        variables: {
            productTrees: [productTreeId],
            catalog: catalogId,
            productIds: [productId],
            sort: [{
                    productTreeNodeAncestorsCount: graphql_1.SortOrder.Desc,
                }],
            skipMeasures: skipMeasures,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    }), data = _c.data, response = __rest(_c, ["data"]);
    var productPresentation = (0, react_1.useMemo)(function () { var _a; return (_a = data === null || data === void 0 ? void 0 : data.products.edges[0].node.presentations.edges[0]) === null || _a === void 0 ? void 0 : _a.node; }, [data === null || data === void 0 ? void 0 : data.products.edges]);
    return __assign(__assign({}, response), { data: productPresentation });
};
exports.useProductTreeNodePresentation = useProductTreeNodePresentation;
