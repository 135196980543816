"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
function usePagination(_a) {
    var _this = this;
    var pageSize = _a.pageSize, pageInfo = _a.pageInfo, fetchMoreOptions = _a.fetchMoreOptions, networkStatus = _a.networkStatus, lastVariables = _a.lastVariables, fetchMore = _a.fetchMore;
    var _b = __read((0, react_1.useState)(1), 2), currentPage = _b[0], setCurrentPage = _b[1];
    (0, react_1.useEffect)(function () {
        if (!lastVariables) {
            return;
        }
        // reset page
        var before = lastVariables.before, after = lastVariables.after;
        if (!before && !after) {
            setCurrentPage(1);
        }
    }, [lastVariables]);
    var onPageChange = (0, react_1.useCallback)(function (nextPage) { return __awaiter(_this, void 0, void 0, function () {
        var options, startCursor, endCursor, _a, variables, otherFetchMoreOptions;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    options = {};
                    startCursor = pageInfo.startCursor, endCursor = pageInfo.endCursor;
                    if (nextPage > currentPage) {
                        options.after = endCursor;
                        options.first = pageSize;
                        options.last = null;
                    }
                    else if (nextPage !== 1) {
                        options.before = startCursor;
                        options.last = pageSize;
                        options.first = null;
                    }
                    _a = fetchMoreOptions !== null && fetchMoreOptions !== void 0 ? fetchMoreOptions : {}, variables = _a.variables, otherFetchMoreOptions = __rest(_a, ["variables"]);
                    return [4 /*yield*/, fetchMore(__assign({ 
                            // replace old data by default
                            updateQuery: function (_, _a) {
                                var fetchMoreResult = _a.fetchMoreResult;
                                return fetchMoreResult;
                            }, variables: __assign(__assign({}, variables), options) }, otherFetchMoreOptions))];
                case 1:
                    _b.sent();
                    setCurrentPage(nextPage);
                    return [2 /*return*/];
            }
        });
    }); }, [currentPage, fetchMore, fetchMoreOptions, pageInfo, pageSize]);
    var onNextPage = (0, react_1.useCallback)(function () {
        var _a = (pageInfo !== null && pageInfo !== void 0 ? pageInfo : {}).hasNextPage, hasNextPage = _a === void 0 ? true : _a;
        if (!hasNextPage) {
            return;
        }
        return onPageChange(currentPage + 1);
    }, [pageInfo, onPageChange, currentPage]);
    var onPreviousPage = (0, react_1.useCallback)(function () { return currentPage > 1 && onPageChange(currentPage - 1); }, [onPageChange, currentPage]);
    return {
        onPageChange: onPageChange,
        onNextPage: onNextPage,
        onPreviousPage: onPreviousPage,
        setCurrentPage: setCurrentPage,
        hasNextPage: pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.hasNextPage,
        pageNumber: currentPage,
        isLoadingMore: networkStatus === client_1.NetworkStatus.fetchMore,
    };
}
exports.usePagination = usePagination;
