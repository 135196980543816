"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkEditProductAttributesDialog = void 0;
var react_1 = __importStar(require("react"));
var ui_components_1 = require("@groupby/ui-components");
var bulk_edit_attributes_dialog_constants_1 = require("./bulk-edit-attributes-dialog.constants");
var bulk_edit_product_attributes_dialog_styles_1 = require("./bulk-edit-product-attributes-dialog.styles");
var product_attribute_control_container_1 = require("./partials/product-attribute-control.container");
var product_value_control_container_1 = require("./partials/product-value-control.container");
var hooks_1 = require("../../../client/hooks");
var proposal_1 = require("../../../client/proposal");
var components_1 = require("../../../components");
var generic_1 = require("../../../generic");
var hooks_2 = require("../../../../hooks");
var localization_1 = require("../../../../localization");
var store_1 = require("../../../../store");
var BulkEditProductAttributesDialog = function (_a) {
    var selectAll = _a.selectAll, excludedIds = _a.excludedIds, onSubmit = _a.onSubmit, onClose = _a.onClose;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var addSuccess = (0, hooks_2.useNotification)().addSuccess;
    var selectedCatalogId = (0, store_1.useSelectedCatalogId)();
    var selectedTreeNodeId = (0, store_1.useSelectedTreeNodeId)();
    var refetchProductAttributes = (0, hooks_1.useRefetchQueries)().refetchProductAttributes;
    var _b = __read((0, react_1.useState)(), 2), selectedAttributeId = _b[0], setSelectedAttributeId = _b[1];
    var _c = __read((0, react_1.useState)(bulk_edit_attributes_dialog_constants_1.BulkEditOperationTypes.Replace), 2), operationType = _c[0], setOperationType = _c[1];
    var _d = __read((0, react_1.useState)([]), 2), selectedValues = _d[0], setSelectedValues = _d[1];
    var handleSuccess = function () { return addSuccess(t({ key: 'BULK_EDIT_SUCCESS_MESSAGE' })); };
    var _f = (0, proposal_1.useBulkAssertions)({
        catalogId: selectedCatalogId,
        onCompleted: function () {
            void refetchProductAttributes(selectedTreeNodeId);
            handleSuccess();
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
        },
    }), addBulkAssertions = _f.addBulkAssertions, isLoading = _f.isLoading, error = _f.error;
    var getProductFilter = function () {
        if (selectAll && !(excludedIds === null || excludedIds === void 0 ? void 0 : excludedIds.length)) {
            return null;
        }
        return {
            id: __assign({}, selectAll ? { notIn: excludedIds } : { in: excludedIds }),
        };
    };
    var isWipeAllValuesMode = operationType === bulk_edit_attributes_dialog_constants_1.BulkEditOperationTypes.Delete && !selectedValues.length;
    var handleSubmit = function () {
        var args = [selectedAttributeId, selectedValues];
        var labels = proposal_1.getProductAssertionBatchLabelsInput.apply(void 0, __spreadArray([isWipeAllValuesMode ? proposal_1.BulkAssertionOperationTypes.DeleteAll : bulk_edit_attributes_dialog_constants_1.bulkEditOperationTypesToBulkAssertionTypes[operationType]], __read(args), false));
        void addBulkAssertions([
            {
                labels: labels,
                filter: getProductFilter(),
            },
        ]);
    };
    var toggleButtonOptions = (0, react_1.useMemo)(function () { return bulk_edit_attributes_dialog_constants_1.bulkEditOperationTypes.map(function (type) { return ({ title: t({ key: bulk_edit_attributes_dialog_constants_1.bulkEditOperationTypesToMessageKey[type] }), value: type }); }); }, [t]);
    var isSubmitDisabled = !selectedAttributeId || (!isWipeAllValuesMode && !selectedValues.length);
    return (react_1.default.createElement(bulk_edit_product_attributes_dialog_styles_1.StyledActionDialog, { maxWidth: "40rem", proceedButtonProps: {
            title: t({ key: 'APPLY' }),
            disabled: isSubmitDisabled || isLoading,
            endIcon: isLoading && react_1.default.createElement(generic_1.LoadingIndicator, null),
            onClick: handleSubmit,
        }, cancelButtonProps: {
            title: t({ key: 'CANCEL' }),
            onClick: function () {
                onClose();
            },
        }, title: t({ key: 'BULK_EDIT_ATTRIBUTES_DIALOG_TITLE' }) },
        react_1.default.createElement(bulk_edit_product_attributes_dialog_styles_1.StyledDialogContent, null,
            error && react_1.default.createElement(components_1.Alert, { severity: "error" }, error.message),
            react_1.default.createElement(product_attribute_control_container_1.ProductAttributeControl, { selectedAttributeId: selectedAttributeId, onChange: setSelectedAttributeId }),
            react_1.default.createElement(bulk_edit_product_attributes_dialog_styles_1.StyledGroup, null,
                react_1.default.createElement(ui_components_1.Typography, { className: 'title', strong: true, variant: "body2" }, t({ key: 'BULK_EDIT_EDIT_OPTIONS' })),
                react_1.default.createElement(ui_components_1.ToggleButtonGroup, { radioGroupMode: true, exclusive: true, options: toggleButtonOptions, value: operationType, minWidth: "8rem", disabled: !selectedAttributeId, onChange: function (_e, value) { return setOperationType(value); } })),
            react_1.default.createElement(bulk_edit_product_attributes_dialog_styles_1.StyledGroup, null,
                react_1.default.createElement(ui_components_1.Typography, { className: 'title', strong: true, variant: "body2" }, t({ key: 'BULK_EDIT_CHOOSE_VALUES' })),
                react_1.default.createElement(product_value_control_container_1.ProductValueControl, { selectedAttributeId: selectedAttributeId, isDisabled: !selectedAttributeId, selectedValues: selectedValues, onChange: setSelectedValues })))));
};
exports.BulkEditProductAttributesDialog = BulkEditProductAttributesDialog;
exports.default = exports.BulkEditProductAttributesDialog;
