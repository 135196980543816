"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductCounts = void 0;
var react_1 = require("react");
var types_1 = require("@groupby/enrich-sdk/resource/_jsonapi/types");
var feature_flags_1 = require("../../feature-flags");
var hooks_1 = require("../../hooks");
var localization_1 = require("../../localization");
var hooks_2 = require("./");
var selectors_1 = require("../../store/selectors");
var useProductCounts = function () {
    var client = (0, hooks_1.useEnrichClient)();
    var featureFlagsService = (0, hooks_1.useFeatureFlagsService)().featureFlagsService;
    var isProductCountsEnabled = featureFlagsService.isFeatureEnabled(feature_flags_1.FeatureFlag.ProductCounts);
    var addError = (0, hooks_1.useNotification)().addError;
    var classesRefresh = (0, selectors_1.useClassesRefreshSelector)();
    var selectedOrganizations = (0, selectors_1.useSelectedOrganizationsSelector)();
    var t = (0, localization_1.useLocalization)().formatMessage;
    var isOrganizationSelected = (0, selectors_1.useSelectedOrganizationsIdsSelector)().length > 0;
    var _a = __read((0, react_1.useState)(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var _b = __read((0, react_1.useState)({}), 2), classTotals = _b[0], setClassTotals = _b[1];
    var _c = __read((0, react_1.useState)({}), 2), categoryTotals = _c[0], setCategoryTotals = _c[1];
    var _d = __read((0, react_1.useState)({}), 2), attributeTotals = _d[0], setAttributeTotals = _d[1];
    var _e = __read((0, react_1.useState)(0), 2), productTotal = _e[0], setProductTotal = _e[1];
    var filter = (0, hooks_2.useFilter)({}).activeFilter;
    var getMetaProducts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload, allTotal, countedClasses, countedCategories, countedAttributes, _a;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    payload = { response: {} };
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 5, 6, 7]);
                    setIsLoading(true);
                    return [4 /*yield*/, client.products.getTotal(filter)];
                case 2:
                    allTotal = _d.sent();
                    if (!allTotal) return [3 /*break*/, 4];
                    return [4 /*yield*/, client.products.getPage({
                            filter: filter,
                            fields: {
                                meta: [types_1.CollectionMetaParam.ATTRIBUTES, types_1.CollectionMetaParam.RELATIONSHIPS],
                            },
                        }, payload)];
                case 3:
                    _d.sent();
                    countedClasses = (_b = payload.response.meta) === null || _b === void 0 ? void 0 : _b.relationships.class.values.reduce(function (acc, item) {
                        var _a;
                        return (Object.assign(acc, (_a = {}, _a[item.actual] = item.total, _a)));
                    }, {});
                    countedCategories = (_c = payload.response.meta) === null || _c === void 0 ? void 0 : _c.relationships.categories.values.reduce(function (acc, item) {
                        var _a;
                        return (Object.assign(acc, (_a = {}, _a[item.actual] = item.total, _a)));
                    }, {});
                    countedAttributes = payload.response.data
                        .reduce(function (acc, _a) {
                        var attributes = _a.attributes.attributes;
                        return acc.concat(attributes);
                    }, [])
                        .reduce(function (acc, attr) {
                        var _a;
                        var _b;
                        return (Object.assign(acc, (_a = {}, _a[attr.id] = ((_b = acc[attr.id]) !== null && _b !== void 0 ? _b : 0) + 1, _a)));
                    }, {});
                    setClassTotals(countedClasses);
                    setCategoryTotals(countedCategories);
                    setAttributeTotals(countedAttributes);
                    setProductTotal(payload.response.meta.total);
                    _d.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5:
                    _a = _d.sent();
                    addError(t({ key: 'META_PRODUCTS_LOADING_ERROR' }));
                    return [3 /*break*/, 7];
                case 6:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (isProductCountsEnabled && isOrganizationSelected && !isLoading) {
            void getMetaProducts();
        }
    }, [selectedOrganizations, classesRefresh, isProductCountsEnabled]);
    return {
        classTotals: classTotals,
        categoryTotals: categoryTotals,
        attributeTotals: attributeTotals,
        productTotal: productTotal,
        isLoading: isLoading,
    };
};
exports.useProductCounts = useProductCounts;
exports.default = exports.useProductCounts;
