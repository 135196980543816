"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterOptionToMessageKey = exports.defaultFilterOptions = exports.CustomizeViewFilterOptions = void 0;
var CustomizeViewFilterOptions;
(function (CustomizeViewFilterOptions) {
    CustomizeViewFilterOptions["Base"] = "Base data";
    CustomizeViewFilterOptions["Enriched"] = "Enriched data";
    CustomizeViewFilterOptions["Measures"] = "Measures";
})(CustomizeViewFilterOptions = exports.CustomizeViewFilterOptions || (exports.CustomizeViewFilterOptions = {}));
exports.defaultFilterOptions = Object.values(CustomizeViewFilterOptions);
exports.filterOptionToMessageKey = (_a = {},
    _a[CustomizeViewFilterOptions.Enriched] = 'ENRICHED_DATA',
    _a[CustomizeViewFilterOptions.Base] = 'BASE_DATA',
    _a[CustomizeViewFilterOptions.Measures] = 'MEASURES',
    _a);
