"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.bulkEditOperationTypesToBulkAssertionTypes = exports.bulkEditOperationTypesToMessageKey = exports.bulkEditOperationTypes = exports.BulkEditOperationTypes = void 0;
var proposal_1 = require("../../../client/proposal");
var BulkEditOperationTypes;
(function (BulkEditOperationTypes) {
    BulkEditOperationTypes["Replace"] = "replace";
    BulkEditOperationTypes["Merge"] = "merge";
    BulkEditOperationTypes["Delete"] = "delete";
})(BulkEditOperationTypes = exports.BulkEditOperationTypes || (exports.BulkEditOperationTypes = {}));
exports.bulkEditOperationTypes = Object.values(BulkEditOperationTypes);
exports.bulkEditOperationTypesToMessageKey = (_a = {},
    _a[BulkEditOperationTypes.Replace] = 'BULK_EDIT_ATTRIBUTES_DIALOG_OPERATION_REPLACE',
    _a[BulkEditOperationTypes.Merge] = 'BULK_EDIT_ATTRIBUTES_DIALOG_OPERATION_MERGE',
    _a[BulkEditOperationTypes.Delete] = 'BULK_EDIT_ATTRIBUTES_DIALOG_OPERATION_DELETE',
    _a);
exports.bulkEditOperationTypesToBulkAssertionTypes = (_b = {},
    _b[BulkEditOperationTypes.Replace] = proposal_1.BulkAssertionOperationTypes.Replace,
    _b[BulkEditOperationTypes.Merge] = proposal_1.BulkAssertionOperationTypes.Merge,
    _b[BulkEditOperationTypes.Delete] = proposal_1.BulkAssertionOperationTypes.Delete,
    _b);
