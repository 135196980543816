"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNodesTree = void 0;
var react_1 = require("react");
var ramda_1 = require("ramda");
var constants_1 = require("../../constants");
var nodes_tree_context_1 = require("../contexts/nodes-tree.context");
var utils_1 = require("../../utils");
var useNodesTree = function () {
    var setNodesTree = (0, react_1.useContext)(nodes_tree_context_1.NodesTreeContext).setNodesTree;
    var getTaxonomyNodeTitle = function (classes, node) { var _a, _b; return (_b = (_a = classes[node.classId]) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : ''; };
    var getV2Ids = function (taxonomyNodes, classes) {
        var descendantsOfV2 = (0, ramda_1.flatten)(taxonomyNodes)
            .filter(function (node) { return node.classId !== constants_1.TAXONOMY_CLASS_V2_ID; })
            .map(function (node) { return (node.parentId === constants_1.TAXONOMY_CLASS_V2_ID ? __assign(__assign({}, node), { parentId: null }) : node); });
        var descendantsOfV2Tree = (0, utils_1.getTreeData)(descendantsOfV2, function (nodeId) { return getTaxonomyNodeTitle(classes, nodeId); });
        var v2TerminalIds = (0, utils_1.getTerminalNodes)(descendantsOfV2Tree);
        var v2Ids = descendantsOfV2.map(function (_a) {
            var id = _a.id;
            return id;
        });
        setNodesTree(descendantsOfV2Tree);
        return [v2Ids, v2TerminalIds];
    };
    return { getV2Ids: getV2Ids };
};
exports.useNodesTree = useNodesTree;
exports.default = exports.useNodesTree;
