"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckedAttributes = exports.CheckedAttributesProvider = exports.CheckedAttributesContext = void 0;
var react_1 = __importStar(require("react"));
var ramda_1 = require("ramda");
var checked_attributes_utils_1 = require("./checked-attributes.utils");
var utils_1 = require("../../utils");
var localization_1 = require("../../../localization");
exports.CheckedAttributesContext = react_1.default.createContext({
    checkedIds: [],
    isAttributeCheckMode: false,
    isValueCheckMode: false,
    checkedItemTypeTitle: '',
    correspondingAttributeInValueCheckMode: '',
    getCheckedEntityLabels: function () { return null; },
    checkIfAttributeValuePairChecked: function () { return false; },
    getCheckedAttributeValuePair: function () { return null; },
    onCheck: ramda_1.identity,
    clearCheckedIds: function () { return null; },
    getCheckedAttributeValuesMap: function () { return ({}); },
});
var CheckedAttributesProvider = function (_a) {
    var children = _a.children;
    var t = (0, localization_1.useLocalization)().formatMessage;
    var _b = __read((0, react_1.useState)([]), 2), checkedAttributeValuePairs = _b[0], setCheckedAttributeValuePairs = _b[1];
    var onCheck = (0, react_1.useCallback)(function (item, isChecked) {
        var checkedItem = (0, checked_attributes_utils_1.createCheckedItem)(item);
        setCheckedAttributeValuePairs((0, utils_1.updateUniqueItemListBasedOnValue)(checkedItem, isChecked));
    }, []);
    var clearCheckedIds = (0, react_1.useCallback)(function () {
        setCheckedAttributeValuePairs([]);
    }, []);
    var isAttributeCheckMode = !!checkedAttributeValuePairs.length && (0, checked_attributes_utils_1.isAttributeChecked)(checkedAttributeValuePairs[0]);
    var isValueCheckMode = !!checkedAttributeValuePairs.length && !isAttributeCheckMode;
    var checkedItemTypeTitle = String(isAttributeCheckMode ? t({ key: 'ATTRIBUTE' }) : t({ key: 'VALUE' })).toLowerCase();
    var getCheckedAttributeValuesMap = (0, react_1.useCallback)(function () {
        if (!checkedAttributeValuePairs.length) {
            return null;
        }
        var attributeValuesMap = {};
        checkedAttributeValuePairs.forEach(function (item) {
            var _a = __read((0, checked_attributes_utils_1.getCheckedAttributeValuePair)(item), 2), attribute = _a[0], value = _a[1];
            if (!attributeValuesMap[attribute]) {
                attributeValuesMap[attribute] = [];
            }
            if (value) {
                attributeValuesMap[attribute].push(value);
            }
        });
        return attributeValuesMap;
    }, [checkedAttributeValuePairs]);
    var checkIfAttributeValuePairChecked = function (item) { return checkedAttributeValuePairs.includes((0, checked_attributes_utils_1.createCheckedItem)(item)); };
    var getCheckedEntityLabels = (0, react_1.useCallback)(function () {
        var entityIndex = isValueCheckMode ? 1 : 0;
        return checkedAttributeValuePairs.map(function (item) { return (0, checked_attributes_utils_1.getCheckedAttributeValuePair)(item)[entityIndex]; });
    }, [checkedAttributeValuePairs, isValueCheckMode]);
    var correspondingAttributeInValueCheckMode = (0, react_1.useMemo)(function () {
        if (!isValueCheckMode) {
            return '';
        }
        return (0, checked_attributes_utils_1.getCheckedAttributeValuePair)(checkedAttributeValuePairs[0])[0];
    }, [checkedAttributeValuePairs, isValueCheckMode]);
    return (react_1.default.createElement(exports.CheckedAttributesContext.Provider, { value: {
            checkedIds: checkedAttributeValuePairs,
            isAttributeCheckMode: isAttributeCheckMode,
            isValueCheckMode: isValueCheckMode,
            checkedItemTypeTitle: checkedItemTypeTitle,
            correspondingAttributeInValueCheckMode: correspondingAttributeInValueCheckMode,
            getCheckedEntityLabels: getCheckedEntityLabels,
            checkIfAttributeValuePairChecked: checkIfAttributeValuePairChecked,
            getCheckedAttributeValuePair: checked_attributes_utils_1.getCheckedAttributeValuePair,
            clearCheckedIds: clearCheckedIds,
            onCheck: onCheck,
            getCheckedAttributeValuesMap: getCheckedAttributeValuesMap,
        } }, children));
};
exports.CheckedAttributesProvider = CheckedAttributesProvider;
var useCheckedAttributes = function () { return (0, react_1.useContext)(exports.CheckedAttributesContext); };
exports.useCheckedAttributes = useCheckedAttributes;
exports.default = exports.CheckedAttributesProvider;
