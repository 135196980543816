"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBulkAssertions = void 0;
var react_1 = require("react");
var graphql_1 = require("../../../../__generated__/graphql");
var create_proposal_hook_1 = require("./create-proposal.hook");
var delete_proposal_hook_1 = require("./delete-proposal.hook");
var edit_proposal_hook_1 = require("./edit-proposal.hook");
var proposal_status_hook_1 = require("./proposal-status.hook");
var useBulkAssertions = function (_a) {
    var catalogId = _a.catalogId, onCompleted = _a.onCompleted;
    var _b = __read((0, react_1.useState)(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read((0, react_1.useState)(null), 2), error = _c[0], setError = _c[1];
    var createProposal = (0, create_proposal_hook_1.useCreateProposal)({
        catalog: catalogId,
        action: graphql_1.ProposalAction.Augment,
        description: '',
    }).createProposal;
    var proposalStatus = (0, proposal_status_hook_1.useProposalStatus)().proposalStatus;
    var editProposal = (0, edit_proposal_hook_1.useEditProposal)().editProposal;
    var deleteProposal = (0, delete_proposal_hook_1.useDeleteProposal)().deleteProposal;
    var handleError = (0, react_1.useCallback)(function (error) {
        setIsLoading(false);
        setError(error);
    }, []);
    var handleEditProposalError = (0, react_1.useCallback)(function (error, proposalId) {
        void deleteProposal({
            variables: {
                proposal: { id: proposalId, clientMutationId: '' },
            },
            onCompleted: function () { return handleError(error); },
            onError: handleError,
        });
    }, [deleteProposal, handleError]);
    var addBulkAssertions = (0, react_1.useCallback)(function (assertions, proposalAction) {
        setIsLoading(true);
        setError(null);
        void createProposal(__assign(__assign({}, (proposalAction && { variables: { action: proposalAction } })), { onError: handleError, onCompleted: function (data) {
                var id = data.proposal.id;
                void editProposal({
                    variables: {
                        proposal: {
                            id: id,
                            addAssertions: assertions,
                            clientMutationId: '',
                        },
                    },
                    onError: function (error) { return handleEditProposalError(error, id); },
                    onCompleted: function () {
                        void proposalStatus({
                            fetchPolicy: 'no-cache',
                            variables: {
                                proposal: {
                                    id: id,
                                    status: graphql_1.ProposalStatus.Committed,
                                    clientMutationId: '',
                                },
                            },
                            onError: handleError,
                            onCompleted: function () {
                                setIsLoading(false);
                                onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
                            },
                        });
                    },
                });
            } }));
    }, [createProposal, editProposal, handleEditProposalError, handleError, onCompleted, proposalStatus]);
    return {
        isLoading: isLoading,
        error: error,
        addBulkAssertions: addBulkAssertions,
    };
};
exports.useBulkAssertions = useBulkAssertions;
