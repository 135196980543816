"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearch = void 0;
var react_1 = require("react");
var client_1 = require("@apollo/client");
var useSearch = function (_a) {
    var networkStatus = _a.networkStatus, refetch = _a.refetch;
    var _b = __read((0, react_1.useState)(''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    var onSearch = (0, react_1.useCallback)(function (newSearchTerm) {
        setSearchTerm(newSearchTerm);
        // reset to the first page
        void refetch({
            search: searchTerm,
            before: null,
            after: null,
        });
    }, [refetch, searchTerm]);
    return {
        searchTerm: searchTerm,
        onSearch: onSearch,
        isSearching: networkStatus === client_1.NetworkStatus.refetch,
    };
};
exports.useSearch = useSearch;
