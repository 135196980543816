"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomizeTableColumns = void 0;
var react_1 = require("react");
var use_table_persisted_data_hook_1 = require("./use-table-persisted-data.hook");
var utils_1 = require("../../../enrich-ai/utils");
var useCustomizeTableColumns = function (_a) {
    var _b, _c, _d;
    var _e = _a.defaultSelectAllMode, defaultSelectAllMode = _e === void 0 ? false : _e, _f = _a.defaultColumnsOrder, defaultColumnsOrder = _f === void 0 ? [] : _f, _g = _a.defaultExcludedColumns, defaultExcludedColumns = _g === void 0 ? [] : _g, _h = _a.options, _j = _h === void 0 ? {} : _h, _k = _j.persisted, persisted = _k === void 0 ? true : _k;
    var _l = (0, use_table_persisted_data_hook_1.useTablePersistedData)(), persistedData = _l.persistedData, setPersistedData = _l.setPersistedData;
    var data = persisted ? persistedData : {};
    var _m = __read((0, react_1.useState)((_b = data === null || data === void 0 ? void 0 : data.columnsOrder) !== null && _b !== void 0 ? _b : defaultColumnsOrder), 2), columnsOrder = _m[0], setColumnsOrder = _m[1];
    var _o = __read((0, react_1.useState)((_c = data === null || data === void 0 ? void 0 : data.selectAllMode) !== null && _c !== void 0 ? _c : defaultSelectAllMode), 2), selectAllMode = _o[0], setSelectAllMode = _o[1];
    var _p = __read((0, react_1.useState)((_d = data === null || data === void 0 ? void 0 : data.excludedColumns) !== null && _d !== void 0 ? _d : defaultExcludedColumns), 2), excludedColumns = _p[0], setExcludedColumns = _p[1];
    var onChangeColumns = (0, react_1.useCallback)(function (newExcludedColumns, newOrder, newSelectMode) {
        if (newSelectMode === void 0) { newSelectMode = false; }
        setSelectAllMode(newSelectMode);
        setExcludedColumns(newExcludedColumns);
        setColumnsOrder(__spreadArray([], __read(newOrder), false));
        if (persisted) {
            setPersistedData({ columnsOrder: newOrder, excludedColumns: newExcludedColumns, selectAllMode: newSelectMode });
        }
    }, [persisted, setPersistedData]);
    var toggleSelectAll = (0, react_1.useCallback)(function () {
        setSelectAllMode(function (prevSelectMode) { return (!prevSelectMode); });
        setExcludedColumns([]);
    }, []);
    var onCheck = (0, react_1.useCallback)(function (column, isChecked) {
        var value = (selectAllMode && !isChecked) || (!selectAllMode && isChecked);
        setExcludedColumns((0, utils_1.updateUniqueItemListBasedOnValueAndProp)(column, value, 'label'));
    }, [selectAllMode]);
    var checkIsColumnSelected = (0, react_1.useCallback)(function (_a) {
        var columnLabel = _a.label, _b = _a.isMeasure, isColumnMeasure = _b === void 0 ? false : _b;
        var isExcluded = excludedColumns.findIndex(function (_a) {
            var label = _a.label, _b = _a.isMeasure, isMeasure = _b === void 0 ? false : _b;
            return label === columnLabel && isMeasure === isColumnMeasure;
        }) >= 0;
        return !selectAllMode ? isExcluded : !isExcluded;
    }, [selectAllMode, excludedColumns]);
    var isAllSelected = (0, react_1.useMemo)(function () { return selectAllMode && !excludedColumns.length; }, [excludedColumns.length, selectAllMode]);
    var _q = __read((0, react_1.useMemo)(function () {
        var _a = excludedColumns.reduce(function (acc, _a) {
            var isMeasure = _a.isMeasure, label = _a.label;
            acc[isMeasure ? 'measures' : 'labels'].push(label);
            return acc;
        }, { labels: [], measures: [] }), labels = _a.labels, measures = _a.measures;
        return [labels, measures];
    }, [excludedColumns]), 2), excludedLabelColumns = _q[0], excludedMeasureColumns = _q[1];
    return {
        columnsOrder: columnsOrder,
        selectAllMode: selectAllMode,
        excludedColumns: excludedColumns,
        excludedLabelColumns: excludedLabelColumns,
        excludedMeasureColumns: excludedMeasureColumns,
        isAllSelected: isAllSelected,
        checkIsColumnSelected: checkIsColumnSelected,
        actions: {
            onCheck: onCheck,
            onChangeColumns: onChangeColumns,
            toggleSelectAll: toggleSelectAll,
            setOrder: setColumnsOrder,
        },
    };
};
exports.useCustomizeTableColumns = useCustomizeTableColumns;
